.dg-main-div{
    height: 700px;
    
    h2{
        padding-top: 20px;
    }
}

.dg-title{
    text-align: center;
}