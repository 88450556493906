.activities-left-col {
    flex: 0 0 34%;
    max-width: 34%;
}

.activities-right-col {
    flex: 0 0 66%;
    max-width: 66%;
}

.activities-right-col .pbmit-title {
    font-size: 16px;
    margin-bottom: 20px;
    color: #777;
}

.activities-sidebar .post-list:first-child {
    padding-top: 40px;
}

.activities-sidebar .post-list {
    padding: 20px 40px;
    padding-top: 0;
    background-color: #f7f9fa;
    padding-bottom: 40px;
}

.activities-sidebar .widget .widget-title {
    color: #2c2c2c;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: 35px;
    position: relative;
}

.activities-sidebar .widget .widget-title::after {
    content: "";
    width: 100%;
    height: 1px;
    bottom: -10px;
    left: 0;
    position: absolute;
    z-index: 1;
    background: #e8e9ea;
}

.activities-flex {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.activities-image {
    width: auto;
    height: auto;
}

.activities-text {
    float: left;
    width: 340px;
    height: auto;
    background-color: #f2f2f2;
    padding: 30px 20px;
    color: black;
    font-weight: 500;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
}

.activities-grid-div {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 40px;
}

.sub-activities-title {
    font-size: 18px;
}