.text-salary {
    padding-top: 10px;
    margin-left: 0px;
    margin-bottom: 15px;
    color: currentColor;
}

.result-header {
    padding-top: 10px;
    margin-left: 0px;
    margin-bottom: 15px;
    color: currentColor;
    display: flex;
    justify-content: center;
    font-weight: bold;
    /* font-size: x-large; */
}

.result-title {
    padding-top: 10px;
    margin-left: 0px;
    /* margin-bottom: 10px; */
    color: currentColor;
    font-weight: bold;
    /* font-size: large; */
}

.result-block {
    display: contents;
}

.result-sub-title {
    margin-left: 0px;
    margin-bottom: 15px;
    color: currentColor;
    padding-left: 40px;
}

.result-sub-result {
    margin-left: 0px;
    margin-bottom: 15px;
    color: currentColor;
}

.form-container {
    padding: 20px;
}

.section-spacing {
    margin-bottom: 15px;
}

.emi-result-div {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: #f1f1f1;
}

.emi-label {
    width: 60%;
}

.emi-result {
    width: 100%;
    padding: 5px 5px 5px 10px;
    border: none;
    /* color: #ffffff; */
}

.emi-result-2div {
    width: 48%;
    padding: 5px 5px 5px 10px;
    border: 1px solid #ced4da;
}

.regular-input {
    width: 100%;
    padding: 5px 5px 5px 10px;
    border: 1px solid #ced4da;
    background-color: white;
}

.emi-result-div2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    background-color: #f1f1f1;
}

/* 
.small-text {
    font-size: 14px;
    width: 100%;
} */

.emi-result2 {
    width: 100%;
    padding: 5px 5px 5px 10px;
    border: none;
    /* color: #ffffff; */
}

.sc-bg-color{
    background-color: #f0f0f0;
}


.form-div {
    width: 85%;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .form-div {
        width: 100%;
    }
    .colon-span {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .emi-result-2div {
        width: 100%;
    }
    .rm-padding {
        padding: 0px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .form-div {
        width: 100%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 549px) {
    .result-sub-title {
        padding-left: 0px;
    }
}

@media only screen and (min-width: 550px) and (max-width: 1024px) {
    .result-sub-title {
        padding-left: 20px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
    .solar-result-div {
        display: flex;
        flex-direction: column;
    }
}