.anHero-container {
    display: block;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
}

.bgmain {
    background-image: linear-gradient(112.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%, rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%, rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%, rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%, rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%, rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%, rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%), linear-gradient(157.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%, rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%, rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%, rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%, rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%, rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%, rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%), linear-gradient(135deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%, rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%, rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%, rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%, rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%, rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%, rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%), linear-gradient(90deg, rgb(195, 195, 195), rgb(228, 228, 228));
    background-blend-mode: overlay, overlay, overlay, normal;
    /* background-image: radial-gradient(circle at center center, transparent,rgb(255,255,255)),linear-gradient(309deg, rgba(90, 90, 90,0.05) 0%, rgba(90, 90, 90,0.05) 50%,rgba(206, 206, 206,0.05) 50%, rgba(206, 206, 206,0.05) 100%),linear-gradient(39deg, rgba(13, 13, 13,0.05) 0%, rgba(13, 13, 13,0.05) 50%,rgba(189, 189, 189,0.05) 50%, rgba(189, 189, 189,0.05) 100%),linear-gradient(144deg, rgba(249, 249, 249,0.05) 0%, rgba(249, 249, 249,0.05) 50%,rgba(111, 111, 111,0.05) 50%, rgba(111, 111, 111,0.05) 100%),linear-gradient(166deg, rgba(231, 231, 231,0.05) 0%, rgba(231, 231, 231,0.05) 50%,rgba(220, 220, 220,0.05) 50%, rgba(220, 220, 220,0.05) 100%),linear-gradient(212deg, rgba(80, 80, 80,0.05) 0%, rgba(80, 80, 80,0.05) 50%,rgba(243, 243, 243,0.05) 50%, rgba(243, 243, 243,0.05) 100%),radial-gradient(circle at center center, hsl(107,19%,100%),hsl(107,19%,100%)); */
}

.glow-effect {
    z-index: -1;
}

.Content-title {
    text-align: center;
    /* font-size: 22px; */
}

.Subhero-text-container {
    display: flex;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    color: #18181b;
}

.Hero-text-container {
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #18181b;
}

.Hero-text {
    font-size: 64px;
    margin-bottom: 50px;
    padding: 1%;
}

.news-detail-image {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.news-detail-image img {
    width: 1020px;
    /* width: 1020p; */
    height: 500px;
    object-fit: cover;
    border-radius: 25px;
    border: 10px solid white;
    box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.07);
}

.Content-container {
    display: block;
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    margin-top: 2.5%;
    padding-left: 20%;
    padding-right: 20%;
}

.Content-title-container p {
    font-size: 32px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    text-align: start;
    color: #18181b;
    margin-bottom: 20px;
}

.adTimestamp-container {
    display: flex;
    gap: 1%;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    color: #18181b;
    margin-bottom: 10px;
    /* width: 50%; */
    justify-content: flex-start;
    /* text-align: left; */
    margin-left: 20.5%;
}

⁠.anContent-text-container p {
    font-family: 'Inter', sans-serif;
    width: 950px;
    margin-top: 2%;
    font-size: 18px;
    font-stretch: extra-expanded;
    color: #18181b;
}

.anContent-text h3 {
    font-size: 24px;
    padding: 20px 0px 8px 0px;
    margin: 0;
    text-align: justify;
}

.anContent-text p {
    margin-bottom: 15px !important;
    font-size: 18px;
    text-align: justify;
}

.anContent-text ul li {
    text-align: justify;
}

#Content-text-heading h3 {
    font-size: x-large;
    text-align: start;
}

.list-container li {
    font-size: larger;
    margin-bottom: 3%;
}

.notice-heading-individual {
    width: 60%;
}

.notice-heading-h2 {
    font-size: 18px;
    text-align: center;
}

.notice-heading-h4 {
    font-size: 18px;
}

/* .news-image{
    border-radius: 25px;
    border: 10px solid white;
    box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.07);
} */

.news-image-container img {
    border-radius: 25px;
    object-fit: cover;
    border: 10px solid white;
    box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.07);
    width: 500px;
    aspect-ratio: 3/2;
}

.news-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px 0;
}

.pb-news {
    padding-bottom: 30px;
}