/* .home3-service-section {
    height: 100%;
    background-color: rgb(246, 246, 246);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home3-service-div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30%;
}

.service-sub-div {
    width: 500px;
    height: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    transition: 0.4s ease-in-out;
}

.service-sub-div:hover {
    background-color: #000000;
    color: #ffffff;
}

.home3-service-heading {
    font-size: 23px;
    font-weight: 600;
}

.service-sub-div:hover .home3-service-heading {
    color: #ffffff;
    transition: 0.4s ease-in-out;
}

.home3-service-text {
    font-size: 18px;
}

.scroll-div {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.icon-div-ss-home3 {
    width: 70px;
    height: 70px;
    background-color: #7cba7c;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading-flex-ss-home3 {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
}

.icon-div2-ss-home3 {
    width: 70px;
}

.icon-class {
    color: #ffffff;
}

.sticky-div {
    position: sticky;
}

.sticky-class {
    font-size: 45px;
    color: #000;
    font-weight: 500;
}

.div1 {
    background-color: rgb(246, 246, 246);
    height: 100%;
    width: 100%;
    padding: 40px 0px;
}

.div2 {
    background-color: rgb(246, 246, 246);
    height: 100%;
    width: 100%;
}

.div2-2 {
    padding: 40px 0px;
    display: flex;
    flex-direction: row-reverse;
}

.sticky-div {
    position: sticky;
}

.pq-sticky-top {
    position: sticky;
    top: 130px;
    z-index: 1;
} */

/* New Service CSS */

/* .home3-services-section {} */

/* Services BG */

.consulting-bg {
    background-image: url(../../../Images_1/Home/ServicesImages/ConsultingBG1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    background-size: cover;
    justify-content: center;
    align-items: center;
}

.research-bg {
    background-image: url(../../../Images_1/Home/ServicesImages/ResearchBG1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    background-size: cover;
    justify-content: center;
    align-items: center;
}

.skillDev-bg {
    background-image: url(../../../Images_1/Home/ServicesImages/SkillDevBG.jpg);
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    background-size: cover;
    justify-content: center;
    align-items: center;
}


/* center-div-home3 */


/* Services BG End */

.bg-dark-div3 {
    width: 60%;
    height: 100%;
   
}
.bg-dark-div4 {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 50px;
    background-color: rgba(0, 0, 0, 0.4);
}

.home3-services-div-container {
    padding: 20px;
}

.home3-services-div {
    /* margin-left: 27%;
    margin-right: 25%; */
}

.home3-services-div-alt {
    margin-right: 50%;
}

.home3-services-div-content-alt-1 {
    width: 80%;
    margin: auto;
}

.cornered-heading3 {
    margin-top: -8px;
    margin-bottom: 40px;
    display: inline-block;
    max-width: fit-content;
}

.cornered-heading3 {
    position: relative;
}

.service-icon-div3 {
    width: 150px;
    height: 150px;
    /* margin-top: 30px;
    margin-bottom: 30px; */
}

.service-icon {
    width: 100%;
    height: 100%
}

.home3-services-depts-div:hover .service-icon-div3 {
    width: 130px;
    height: 130px;
}

/* .home3-services-depts-div:hover .service-icon {
    filter: grayscale(1);
} */

/* Title Border CSS */

.cornered-heading3::before,
.cornered-heading3::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    border: 2px solid #7cba27;
    z-index: 2;
}

.cornered-heading3::before {
    top: -15px;
    left: -22px;
    border-width: 2px 0 0 2px;
}

.cornered-heading3::after {
    bottom: -15px;
    right: -22px;
    border-width: 0 2px 2px 0;
}

/* Title Border CSS End */

.home3-services-div-heading {
    /* font-size: clamp(18px, 2.5vw, 30px); */
    color: #ffffff;
}

.home3-services-div-text {
    color: #ffffff;
    /* font-size: 18px; */
    /* font-size: clamp(14px, 2.5vw, 18px); */
}

.home3-services-div-text-container {
    margin-bottom: 40px;
    text-align: justify;
}

.home3-services-depts-div {
    background-color: rgba(255, 255, 255, 0.9);
    position: relative;
    overflow: visible;
    padding: 14px;
}

.home3-services-depts-div:hover {
    background-color: rgb(255, 255, 255);
}

.home3-services-depts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home3-services-depts a {
    font-size: 16px;
    line-height: 120%;
    /* transition: all 0.3s ease-in-out; */
    color: #18181B;
    text-align: center;
}

/* Top Grid */

.home3-services-depts-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 1.5rem;
}

.grid-3-3 {
    gap: 1rem;
}

.grid-3-33>div {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;

}

.grid-4-2 {
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.grid-4-2>div {
    width: 145px;
    height: 145px;
}

.service-text-center {
    text-align: center;
}

/* .home3-services-depts-grid>div {
    width: 170px;
    height: 170px;
} */

/* Top Grid End */

/* Bottom Grid */

/* .home3-services-depts-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 1.5rem;
    gap: 1.5rem;
    height: 160px;
}

.home3-services-depts-grid-2>div:nth-child(1) {
    width: 170px;
    justify-self: end;
}

.home3-services-depts-grid-2>div:nth-child(2) {
    width: 170px;
    justify-self: start;
} */

/* Bottom Grid End */

/* OnHover Department Block Border CSS */

.home3-services-depts::before,
.home3-services-depts::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    border: 3px solid #7cba27;
    z-index: 2;
    transition: 0.4s ease-in-out;
}

.home3-services-depts::before {
    top: 10px;
    left: 10px;
    border-width: 3px 0 0 3px;
    opacity: 0;
}

.home3-services-depts::after {
    bottom: 10px;
    right: 10px;
    border-width: 0 3px 3px 0;
    opacity: 0;
}

.home3-services-depts-div:hover .home3-services-depts::before {
    opacity: 1;
}

.home3-services-depts-div:hover .home3-services-depts::after {
    opacity: 1;
}

/* OnHover Department Block Border CSS End */

.home3-services-depts-font {
    margin-top: 5px;
}

.home3-services-depts-font {
    font-weight: 500;
    /* font-size: medium; */
}

.home3-services-depts-div:hover .home3-services-depts-font {
    color: #7cba27;
}

@media only screen and (min-width: 1280px) and (max-width: 1550px) {
    .home3-services-depts-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .home3-services-div-content-alt-1 {
        width: 100%;
    }
}

@media only screen and (min-width: 860px) and (max-width: 1279px) {
    .home3-services-depts-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .home3-services-div-text {
        color: #ffffff;
        /* font-size: 18px; */
    }

    .grid-3-33>div {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
    }

    .home3-services-div-content-alt-1 {
        width: 100%;
    }
}


@media only screen and (min-width: 515px) and (max-width: 859px) {
    .home3-services-depts-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .home3-services-div-text {
        color: #ffffff;
        /* font-size: 16px; */
    }

    .home3-services-div {
        margin-left: 0%;
    }

    .home3-services-div-alt {
        margin-right: 0%;
    }

    .home3-services-div-heading {
        /* font-size: 26px; */
        color: #ffffff;
    }

    .grid-3-33>div {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 514px) {

    .home3-services-depts-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .home3-services-div-content-alt-1 {
        width: 100%;
    }

    .home3-services-div-text {
        color: #ffffff;
        /* font-size: 14px; */
    }

    .home3-services-div-container {
        padding: 50px 20px;
    }

    .home3-services-div {
        margin-left: 0;
    }

    .home3-services-div-alt {
        margin-right: 0;
    }

    .home3-services-div-heading {
        /* font-size: 18px; */
        padding-left: 6px;
    }

    .cornered-heading3::after {
        right: -16px;
    }

    .cornered-heading3::before {
        left: -4px;
    }

    .home3-services-depts-grid>div {
        width: 150px;
        height: 158px;
    }

    .home3-services-depts-grid {
        grid-template-columns: repeat(1, 1fr);
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .grid-3-33>div {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
    }
}