.menu-item-visitor {
    text-align: left;
}
.linkfooter {
    color: white;
   background: none;
    border-width: 0;
}
.linkfooter:hover {
    color: rgb(154 198 93);;
   background: none;
    border-width: 0;
}