.employee-main{
    display:flex;
    justify-content:center;
    align-items: flex-start;
    padding-top: 25px;
    padding-bottom: 25px;
    
}
.employee-box{
    /* width: 50%; */
    margin-right: 40px;
    display:-webkit-inline-box
}

.employee-tagline {
    /* margin-bottom: 2rem; */
    font-weight: 500;
    /* text-transform: uppercase; */
    line-height: 1.3;
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity));
}
.margin-b-12{
    margin-bottom:3rem;
}
.employee-name{
    margin-top: 20px;
    font-size: clamp(18px, 2.5vw, 30px);
    margin-bottom: 10px;
    /* font-weight: 700; */
    color: black;
}
.employee-headingfont{
    font-size: clamp(16px, 2.5vw, 22px);
    /* font-weight: 700; */
   /* color: black; */
}

.singlePage p{
    --tw-text-opacity: 1;
    color: rgb(24 24 27 / var(--tw-text-opacity));
}


.eimg-size{
    width: 400px;
    /* max-width: 100%; */
    --tw-bg-opacity: 1;
    background-color: rgb(220 224 211 / var(--tw-bg-opacity));
    border-radius: 12px;
}
.eimg-liimage{
    position:absolute;
    max-width: 100%;
    --tw-bg-opacity: 1;
    background-color: rgb(220 224 211 / var(--tw-bg-opacity));
    border-radius: 12px;
}
.litext
{
    padding-left: 3rem;
    margin-bottom: 5px;
}
.emp-img-space{
    padding: 10px;
    border-radius: 12px;
    background-color: white;
}

.stack-upper{
    z-index: 1;
}

.stack-lower{
    z-index: -1;
}

.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.mb-6 {
    margin-bottom: 1.5rem;
}
.fill-paragraph {
    fill: #18181B;
}

.fill-transparent {
    fill: transparent;
}

.mt-12{
    margin-top:12px;
}

.fill-\[\#E6FFB1\] {
    fill: #E6FFB1;
}
.stroke-gray-100 {
    stroke: #DCE0D3;
}
.mr-3 {
    margin-right: 0.75rem;
}

.hover\:transition-colors:hover {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.tds-align{
    display: flex;
    gap: 15px;
}

.tds-main{
    background-image: linear-gradient(90deg, rgba(165, 165, 165, 0.03) 0%, rgba(165, 165, 165, 0.03) 8%,rgba(235, 235, 235, 0.03) 8%, rgba(235, 235, 235, 0.03) 9%,rgba(7, 7, 7, 0.03) 9%, rgba(7, 7, 7, 0.03) 14%,rgba(212, 212, 212, 0.03) 14%, rgba(212, 212, 212, 0.03) 17%,rgba(219, 219, 219, 0.03) 17%, rgba(219, 219, 219, 0.03) 95%,rgba(86, 86, 86, 0.03) 95%, rgba(86, 86, 86, 0.03) 100%),linear-gradient(67.5deg, rgba(80, 80, 80, 0.03) 0%, rgba(80, 80, 80, 0.03) 11%,rgba(138, 138, 138, 0.03) 11%, rgba(138, 138, 138, 0.03) 17%,rgba(122, 122, 122, 0.03) 17%, rgba(122, 122, 122, 0.03) 24%,rgba(166, 166, 166, 0.03) 24%, rgba(166, 166, 166, 0.03) 27%,rgba(245, 245, 245, 0.03) 27%, rgba(245, 245, 245, 0.03) 89%,rgba(88, 88, 88, 0.03) 89%, rgba(88, 88, 88, 0.03) 100%),linear-gradient(67.5deg, rgba(244, 244, 244, 0.03) 0%, rgba(244, 244, 244, 0.03) 4%,rgba(16, 16, 16, 0.03) 4%, rgba(16, 16, 16, 0.03) 10%,rgba(157, 157, 157, 0.03) 10%, rgba(157, 157, 157, 0.03) 20%,rgba(212, 212, 212, 0.03) 20%, rgba(212, 212, 212, 0.03) 83%,rgba(5, 5, 5, 0.03) 83%, rgba(5, 5, 5, 0.03) 84%,rgba(237, 237, 237, 0.03) 84%, rgba(237, 237, 237, 0.03) 100%),linear-gradient(90deg, #ffffff,#ffffff);
}
.team-detail-body p{
        text-align: justify;
        margin-bottom: 15px;
}
   
@media screen and (max-width: 1200px) {
    .eimg-size{
        max-width: 100%;
        --tw-bg-opacity: 1;
        background-color: rgb(220 224 211 / var(--tw-bg-opacity));
        border-radius: 12px;
    }
    .employee-main{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .employee-tagline{
        text-align: center;
        margin-bottom: unset;
    }
    .employee-name{
        text-align: center;
    }
    .team-detail-body {
        p{
            text-align: justify;
            width: 90%;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .team-ul {
        width: -webkit-fill-available;
        /* margin: 0; */
        padding: 0;
        list-style: none;
        font-family: kumbhfonts;
        display: flex;
        flex-direction: column;
        /* justify-content: start; */
        /* align-items: start; */
        margin-left: 5%;
    }
    .items-center{
        display: flex;
        justify-content: center;
    } 
}
.team-ul {
    margin-bottom: 10px;
}