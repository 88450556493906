.experience-achievement-section {
    height: 600px;
    margin: auto;
}

.experience-achievement-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.experience-achievement-div {
    width: 350px;
    height: 100%;
    margin: auto;
    padding: auto;
}

.experience-achievement-div::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 0;
}

.experience-achievement-image-div {
    width: 100%;
    height: 380px;
    border-radius: 10px;
    overflow: hidden;
}

.experience-achievement-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.experience-achievement-heading {
    font-size: 20px;
    line-height: 120%;
    padding-top: 4%;
    height: auto;
    padding-bottom: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.experience-achievement-desc {
    font-weight: 400;
    font-size: 15px;
    color: #18181B;
    margin-top: -75px;
    text-align: start;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.experience-achievement-div:hover .experience-achievement-image-div img {
    margin-top: -90px;
    margin-bottom: 2px;
}

.experience-achievement-div:hover .experience-achievement-heading {
    margin-top: -90px;
    margin-bottom: 10px;
}

.experience-achievement-div:hover .experience-achievement-desc {
    margin-top: -10px;
    margin-bottom: 23px;
    opacity: 1;
    visibility: visible;
    transition-delay: 0.3s;
}