* {
    box-sizing: border-box;
}

.service-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 650px;
}

.main-service-div {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 280px;
}

.left-service-div {
    display: flex;
    flex-direction: column;
    gap: 73px;
    /* margin-left:20px ; */
}

.center-service-div {
    background-color: #f4fae8;
    color: #bfef59;
    font-weight: bold;
    font-size: 24px;
    width: 110px;
    height: 110px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 10;

}

.right-service-div {
    display: flex;
    flex-direction: column;
    gap: 73px;
    z-index: 1;
}

.right-service-center{
    display: flex;
    flex-direction: column;
    gap: 73px;
    z-index: 1;
   /* margin-right: 130px; */
}
.center-left-icon {
    padding-left: 20px;
    z-index: 1;

}

.center-right-icon {
   padding-right:20px;
   /* right: 80%; */
   z-index: 1;
}

.image-box1 {
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 10px gray;
    border-radius: 999px;
    object-fit: contain;
    overflow: visible;
    /* padding-left: 30px; */
    z-index: 4;
}



 .image-container {
    position: relative;
    display: inline-flex; 
    align-items: center;
 /* margin-left:170px */
 z-index: 4;
}

.image-container-center-left {
    position: relative;
    display: inline-flex; 
    align-items: center;
 /* margin-left:170px */
 left:30%;
 z-index: 4;
}

.image-container1 {
    position: relative;
    display: inline-flex; 
    align-items: center;
    right:30%;
 /* padding-right: 70px; */
}
.image-container2 {
    position: relative;
    display: inline-flex; 
    align-items: center;
 /* padding-right:150px */
 z-index: 5;
}


.image-box {
    z-index: 5;
    width: 320px;

}


.svg{
width: 500px;
}



.ltline {
    height: auto; 
    position: absolute;
    top: 35%;
    width: auto;
    margin-left: 260px; 
    z-index: 1;

    width: auto;
    /* transform: translateY(-102%); */

}


.mtline {
    width: auto; 
    height: auto; 
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 230px; 
    z-index: 1;
}

.btline {
    width: auto;
    height: auto;
    position: absolute;
    top: 65%;
    transform: translateY(-102%);
    margin-left: 260px; 
    z-index: 1;
}






.trline {
    height: auto; 
    position: absolute;
    top: 35%;
    width: auto;
 right: 260px;
 
    z-index: 1;


}


.mrline {
    height: auto; 
    position: absolute;
    top: 40%;
    width: auto;
 right: 230px;
 z-index: 1;

}


.brline {
    height: auto; 
    position: absolute;

    width: auto;
 right: 260px;
    z-index: -10;
    bottom: 35%;

}


.hello {
    background-color: #ffdb65;
}


.service-section2{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 650px; */
}
.ourdepartment-bottom-spacing{
    padding-top: 25px;
    padding-bottom: 25px;
}