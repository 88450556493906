

 .section-title-2 {
  padding: 0 50px;
}

@media only screen and (max-width: 767px) {
  .section-title-2 {
    padding: 0;
  }
}

.rs-service-2__item {
  border-radius: 4px;
  overflow: hidden;
  margin: 0px 10px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rs-service-2__item {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rs-service-2__item {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rs-service-2__item {
    margin-left: 0;
    margin-right: 0;
  }
}

.rs-service-2__item .rs-thumb {
  position: relative;
  z-index: 10;
}

.rs-service-2__item .rs-thumb::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -1px;
  background: #ffffff;
  width: 100%;
  height: 50px;
  -webkit-clip-path: polygon(0% 100%, 24% 28%, 48% 77%, 74% 24%, 100% 100%);
  clip-path: polygon(0% 100%, 24% 28%, 48% 77%, 74% 24%, 100% 100%);
}

.rs-service-2__item .rs-thumb img {
  width: 100%;
  object-fit: cover;
}

.rs-service-2__item .rs-content {
  padding: 15px 20px 40px 20px;
  margin: 0px;
  border-style: solid;
  border-width: 0px 2px 2px 2px;
  border-color: #01264414;
  border-radius: 0px 0px 4px 4px;
  transition: all ease 0.3s;
  position: relative;
  z-index: 10;
  background: rgba(255, 255, 255);
  display: flex;
  align-items: center;
  gap: 20px;
}

.rs-service-2__item .rs-content:hover {
  background: rgba(255, 255, 255);
}

@media only screen and (min-width: 1220px) {
  .rs-content {
    height: 110px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rs-service-2__item .rs-content {
    padding: 15px 20px 20px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rs-service-2__item .rs-content {
    padding: 15px 20px 20px 20px;
  }

  .rs-service-2__item {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rs-service-2__item .rs-content {
    padding: 15px 20px 20px 20px;
  }

  .rs-service-2__item {
    margin-bottom: 20px;
  }
}

.rs-service-2__item .rs-content .rs-icon {
  transition: all linear 0.3s;
  display: inline-block;
}

.rs-service-2__item .rs-content .title {
  color: #0C2543;
  /* font-size: clamp(16px, 2.5vw, 18px); */
  line-height: 36px;
  font-weight: 400;
  margin: 16px 0 13px;
}

/* @media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rs-service-2__item .rs-content .title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rs-service-2__item .rs-content .title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .rs-service-2__item .rs-content .title {
    font-size: 20px;
  }
} */

.rs-service-2__item .rs-content .title a {
  transition: all linear 0.3s;
}

.rs-service-2__item .rs-content .title a:hover {
  color: #7cba27;
}

.rs-service-2__item .rs-content p {
  font-size: 16px;
}

.rs-service-2__item .rs-content>a {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  position: absolute;
  right: 50px;
  top: -30px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.3s;
  background: #7cba27;
  color: #ffffff;
}

.rs-service-2__item .rs-content>a:hover {
  background: #7cba27;
  color: #ffffff;
}

.rs-service-2__item:hover .rs-content {
  border-color: #7cba27;
}

.rs-service-2__item:hover .rs-content .rs-icon {
  transform: scale(-1) rotate(180deg);
}

.rs-service-2__item:hover .rs-content>a {
  top: -60px;
  opacity: 1;
  visibility: visible;
}

[class^="ri-"],
[class*=" ri-"] {
  font-family: 'remixicon' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ri-arrow-right-up-line:before {
  content: "\ea70";
}

.rs-service-grid-3 .rs-service-2__item .rs-content>a {
  background: #7cba27;
  color: #ffffff;
}

.rs-service-grid-3 {
  background-image: linear-gradient(112.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%, rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%, rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%, rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%, rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%, rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%, rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%), linear-gradient(157.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%, rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%, rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%, rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%, rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%, rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%, rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%), linear-gradient(135deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%, rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%, rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%, rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%, rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%, rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%, rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%), linear-gradient(90deg, rgb(195, 195, 195), rgb(228, 228, 228));
  background-blend-mode: overlay, overlay, overlay, normal;
  padding: 40px 0px;
}


.nod-container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}



@media (min-width:576px) {
  .nod-container {
    max-width: 720px
  }
}

@media (min-width:768px) {

  .nod-container {
    max-width: 900px
  }
}

@media (min-width:992px) {

  .nod-container {
    max-width: 1140px
  }
}

@media (min-width:1200px) {

  .nod-container {
    max-width: 1320px
  }
}

@media (min-width:1400px) {
  .nod-container {
    max-width: 1500px
  }
}