.nss-main-indi-box {
    width: fit-content;
    height: 450px;
    background-color: #ffffff;
    margin: auto;
    border-radius: 12px;
    position: relative;
    z-index: 1;
    top: 20%;
    --tw-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 0px 30px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    /* box-shadow: 0px 0px 10px #dbdbdb; */
}

.nss-connector-box {
    width: 1300px;
    height: 50px;
    background-color: #ffffff;
    margin: auto;
    border-radius: 0 0 12px 12px;
    position: relative;
    z-index: 1;
}

.nss-wrapper {
    padding: 60px 60px 0px 60px;
}

.nss-items {
    display: flex;
    align-items: flex-start;
    gap: 55px;
    padding-bottom: 30px;
}

.nss-image-div {
    border-radius: 12px;
}

.nss-image {
    width: 300px;
    flex-basis: 85%;
    height: 300px;
    position: relative;
    object-fit: cover;
    border-radius: 12px;
    box-shadow: 0px 0px 20px 2.75px rgba(0,0,0, .6);
}

.nss-title {
    position: relative;
    z-index: 99;
    margin-bottom: 15px;
    margin-top: -7px;


}

.nss-title span {
    color: #7cba27;
    margin-bottom: 10px;
    display: inline-block;
    font-weight: 500;
    text-transform: uppercase;
}

.nss-title h2 {
    /* font-size: 30px; */
    font-size: clamp(18px, 2.5vw, 30px);
    line-height: 125%;
    /* font-weight: 700; */
    color: #18181B;
}



.nss-content p {
    /* font-size: clamp(14px, 2.5vw, 18px); */
    font-weight: 500;
    border-bottom: 1px solid rgba(124, 186, 39, .3);
    padding-bottom: 10px;
    text-align: justify;
    display: block;
}


.nss-button {
    background-color: white;
    border-radius: 25px;
    padding: 10px 20px;
    color: black;
    border: 1px solid rgb(175, 172, 172);

}

.nss-button:hover {
    background-color: black;
    color: white;
    transition: 0.5s;
}

.nss-main-section {
    height: 750px;
    padding: 40px 0px;
    position: relative;
    background-color: rgb(255, 255, 255);
    background-image: url('../../../../Images_1/AboutUs/AboutCompanyImages/TimelineImages/tm-bacground.png');
}

/* .nss-bottom-div {
    width: 100%;
    height: 403px;
    background-size: cover;
    position: absolute;
    bottom: 47%;
} */

.nss-see-more-text-card {
    /* background-color: aqua; */
    padding: 0px;
    margin: 0px;
    height: 40px;
    display: flex;
    justify-content: start;
    margin-top: 10px;
}

.heading-container-white-fonts {
    color: #ffffff;
}

@media only screen and (min-width:1350px) {
    .nss-main-indi-box {
        width: 1300px;
        height: max-content;
    }
}

@media only screen and (min-width: 970px) and (max-width: 1350px) {
    .nss-main-indi-box {
        width: 90%;
        height: max-content;
    }
}


@media only screen and (min-width: 427px) and (max-width: 969px) {
    .nss-see-more-text-card {
        justify-content: center;
    }

    .nss-main-section {
        height: 950px;
    }

    .nss-main-indi-box {
        width: 90%;
        height: max-content;
        top: 140px;
    }

    .nss-wrapper {
        padding: 0px;
    }

    .nss-items {
        flex-direction: column;
        align-items: center;
        gap: 25px;
    }

    .nss-image-div {
        display: flex;
        justify-content: center;
        padding: 30px 0px 0px 0px;
    }

    .nss-title h2 {
        /* font-size: 26px; */
        text-align: center;
        padding: 0px 30px;
    }

    /* .nss-content p {
        font-size: 16px;
        text-align: justify;
        padding: 0px 30px 30px 30px;

    } */
    .nss-bottom-div {
        width: 100%;
        height: 498px;
        background-image: url('../../../../Images_1/AboutUs/AboutCompanyImages/TimelineImages/tm-bacground.png');
        background-size: cover;
        position: absolute;
        /* top: 250px; */
        bottom: 48%;
        /* top: 50%; */
        /* background-attachment: fixed; */
        /* filter: blur(4px); */
    }

    /* .nss-button-div {
        display: flex;
        justify-content: center;
        align-items: center;
    } */
}

@media screen and (min-width: 650px) and (max-width: 770px) {
    .nss-main-section {
        height: 1050px;
    }

    .nss-bottom-div {
        height: 550px;
    }
}


@media screen and (min-width: 427px) and (max-width: 649px) {
    .nss-main-section {
        height: 1200px;
    }

    .nss-bottom-div {
        height: 629px;
    }
}

@media only screen and (max-width: 426px) {

    .nss-main-section {
        height: 1050px;
    }

    .nss-see-more-text-card {
        justify-content: center;
    }


    .nss-main-indi-box {
        width: 95%;
        box-shadow: none;
        height: max-content;
        top: 120px;
    }

    .nss-bottom-div {
        width: 100%;
        height: 365px;
        background-image: url('../../../../Images_1/AboutUs/AboutCompanyImages/TimelineImages/tm-bacground.png');
        background-size: cover;
        position: absolute;
        /* top: 250px; */
        bottom: 66%;
        /* top: 50%; */
        /* background-attachment: fixed; */
        /* filter: blur(4px); */
    }

    .nss-wrapper {
        padding: 0px;
    }

    .nss-items {
        flex-direction: column;
        align-items: center;
        gap: 25px;
    }

    .nss-image-div {
        display: flex;
        justify-content: center;
        padding: 30px 0px 0px 0px;
    }

    .nss-title h2 {
        /* font-size: 18px; */
        text-align: center;
        padding: 0px 30px;
    }

    /* .nss-content p {
        font-size: 14px;
        text-align: justify;
        padding: 0px 30px 30px 30px;
    } */

    .nss-button-div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nss-image {
        width: 300px;
    }
}

.nss-image-div {
    width: 300px;
}


@media only screen and (max-width: 400px) {
    .nss-main-section {
        height: 1000px;
    }
}

@media only screen and (max-width: 350px) {
    .nss-image {
        width: 90%;
    }
    .nss-main-section {
        height: 1020px;
    }
}

@media only screen and (min-width: 969px) {

    .nss-content {
        width: 70%;
    }

}

@media only screen and (min-width: 969px) and (max-width: 1351px) {
    .nss-content {
        width: 60%;
    }
}