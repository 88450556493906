.Public-Hero-container {
    display: block;
    width: 100%;
    height: auto;
  
  }
  
  .tab-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;
  }
  
  .next-btn {
    padding: 10px 10px;
    border-radius: 57px;
    background: none;
    border: 1px solid #83b448;
    color: black;
    transition: all 100ms ease-in;
  }
  
  .prev-btn {
    padding: 10px 10px;
    border-radius: 57px;
    background: none;
    border: 1px solid #83b448;
    color: black;
  }
  
  .next-btn:hover {
    background-color: #82b440;
    border: #82b440;
  }
  
  .prev-btn:hover {
    background-color: #82b440;
    border: #82b440;
  }
  
  .tab-btn {
    background: none;
    border: 1px solid #83b448;
    border-radius: 7px;
    color: black;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, border 0.3s ease;
  }
  
  .tab-btn.active {
    background-color: #82b440 !important;
    color: black;
    border: #82b440 !important;
  }
  
  .tab-btn:hover {
    border: 1px solid #edf0e6;
    background-color: #82b440;
  }
  
  
  .Publication-Card-Container {
    display: flex;
    width: 100%;
    min-height: 200px;
    justify-content: center;
    align-items: center;
  }
  
  .Publication-card {
    display: block;
    width: 850px;
    height: auto;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: #18181b;
    padding: 3%;
    margin-top: 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.2);
    margin-bottom: 50px;
    margin-left: 17px;
    margin-right: 17px;
  }
  
  .Book-heading {
    text-align: start;
    font-size: clamp(18px, 2.5vw, 30px);
    font-family: kumbhfonts;
    margin-top: 10px;
    padding: 2% 2% 2% 3%;
    background-color: #82b440;
    color: white;
    border-radius: 20px;
  }
  
  .Book-content-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px dotted black;
    margin-top: 10px;
  }
  
  .Book-content {
    flex: 1;
    text-align: start;
    font-size: 16px;
    font-family: kumbhfonts;
    padding: 2%;
  }
  
  .Book-content-heading p {
    font-size: 22px;
    font-family: kumbhfonts;
  }
  
  .Book-content-text {
    padding: 2%;
    width: auto;
  
    text-align: start;
  }
  
  .Publication-card-buttons {
    display: flex;
    align-items: center;
  }
  
  .Publication-card-buttons a {
    padding: 3px 10px;
    border-radius: 50px;
    background: none;
    border: 1px solid #18181b;
    transition: all 500ms ease-in-out;
  }
  
  .Publication-card-buttons a:hover {
    background-color: black;
    color: white;
  }
  
  
  /* Animation */
  .tab-btn {
    position: relative;
    overflow: hidden;
  }
  
  .tab-btn::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #82b440;
    transition: height 0.3s ease;
    z-index: -1;
  }
  
  .tab-btn:hover::before,
  .tab-btn.active::before {
    height: 100%;
  }
  
  .publication-heading {
    display: flex;
    justify-content: center;
    text-align: justify;
    /* font-size: clamp(18px, 2.5vw, 30px); */
    line-height: 38px;
    letter-spacing: 0px;
    font-family: kumbhfonts;
    font-weight: 500;
    padding: 50px 17px 0px 17px;
  }
  
  /* .publication-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 40px;
  } */
  
  .pb-main {
    background-image: linear-gradient(23deg, rgba(202, 202, 202, 0.02) 0%, rgba(202, 202, 202, 0.02) 13%, transparent 13%, transparent 80%, rgba(11, 11, 11, 0.02) 80%, rgba(11, 11, 11, 0.02) 100%), linear-gradient(42deg, rgba(98, 98, 98, 0.02) 0%, rgba(98, 98, 98, 0.02) 36%, transparent 36%, transparent 77%, rgba(252, 252, 252, 0.02) 77%, rgba(252, 252, 252, 0.02) 100%), linear-gradient(286deg, rgba(173, 173, 173, 0.02) 0%, rgba(173, 173, 173, 0.02) 2%, transparent 2%, transparent 12%, rgba(59, 59, 59, 0.02) 12%, rgba(59, 59, 59, 0.02) 100%), linear-gradient(77deg, rgba(87, 87, 87, 0.02) 0%, rgba(87, 87, 87, 0.02) 18%, transparent 18%, transparent 55%, rgba(247, 247, 247, 0.02) 55%, rgba(247, 247, 247, 0.02) 100%), linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
  }
  
  .pb-search-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .search-input {
    width: 45%;
    border: 1px solid #83b448;
    border-radius: 7px;
    height: 40px;
    padding: 20px;
    color: black;
  }
  
  .search-input::placeholder {
    color: #6c757d;
    opacity: 1;
    /* Firefox */
  }
  
  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: red;
  }
  
  @media screen and (max-width: 740px) {
    .tab-btn {
      padding: 7px 7px;
      border-radius: 7px;
    }
  
    .search-input {
      width: 80%;
    }
  }
  
  @media screen and (min-width:741px) and (max-width: 1454px) {
    .search-input {
      width: 60%;
    }
  }
  
  @media screen and (max-width: 540px) {
    .tab-btn {
      padding: 5px 5px;
      border-radius: 7px;
      /* font-size: 14px; */
      /* height: 30px; */
    }
  
    .prev-btn {
      padding: 5px 5px;
      border-radius: 57px;
      /* height: 30px; */
      display: flex;
      align-items: center;
    }
  
    .next-btn {
      padding: 5px 5px;
      border-radius: 57px;
      /* height: 30px; */
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    /* 
    .publication-heading {
      font-size: 20px;
    } */
  
    .search-input {
      width: 90%;
    }
  }
  
  .year-color {
    color: #82b440;
  }