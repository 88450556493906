.homenotice-bg-div {
    /* background-image: linear-gradient(134deg, rgba(1, 1, 1,0.02) 0%, rgba(1, 1, 1,0.02) 16%,transparent 16%, transparent 76%,rgba(58, 58, 58,0.02) 76%, rgba(58, 58, 58,0.02) 100%),linear-gradient(215deg, rgba(166, 166, 166,0.02) 0%, rgba(166, 166, 166,0.02) 33%,transparent 33%, transparent 79%,rgba(111, 111, 111,0.02) 79%, rgba(111, 111, 111,0.02) 100%),linear-gradient(303deg, rgba(215, 215, 215,0.02) 0%, rgba(215, 215, 215,0.02) 7%,transparent 7%, transparent 90%,rgba(192, 192, 192,0.02) 90%, rgba(192, 192, 192,0.02) 100%),linear-gradient(302deg, rgba(113, 113, 113,0.02) 0%, rgba(113, 113, 113,0.02) 34%,transparent 34%, transparent 73%,rgba(65, 65, 65,0.02) 73%, rgba(65, 65, 65,0.02) 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255)); */
    /* background-image: linear-gradient(134deg, rgba(1,1,1, 0.06274509803921569) 0%, rgba(1,1,1, 0.06274509803921569) 16%,transparent 16%, transparent 76%,rgba(58,58,58, 0.06274509803921569) 76%, rgba(58,58,58, 0.06274509803921569) 100%),linear-gradient(215deg, rgba(166,166,166, 0.06274509803921569) 0%, rgba(166,166,166, 0.06274509803921569) 33%,transparent 33%, transparent 79%,rgba(111,111,111, 0.06274509803921569) 79%, rgba(111,111,111, 0.06274509803921569) 100%),linear-gradient(303deg, rgba(215,215,215, 0.06274509803921569) 0%, rgba(215,215,215, 0.06274509803921569) 7%,transparent 7%, transparent 90%,rgba(192,192,192, 0.06274509803921569) 90%, rgba(192,192,192, 0.06274509803921569) 100%),linear-gradient(302deg, rgba(113,113,113, 0.06274509803921569) 0%, rgba(113,113,113, 0.06274509803921569) 34%,transparent 34%, transparent 73%,rgba(65,65,65, 0.06274509803921569) 73%, rgba(65,65,65, 0.06274509803921569) 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255)); */
    background-image: linear-gradient(134deg, rgba(1, 1, 1, 0.027450980392156862) 0%, rgba(1, 1, 1, 0.027450980392156862) 16%, transparent 16%, transparent 76%, rgba(58, 58, 58, 0.027450980392156862) 76%, rgba(58, 58, 58, 0.027450980392156862) 100%), linear-gradient(215deg, rgba(166, 166, 166, 0.027450980392156862) 0%, rgba(166, 166, 166, 0.027450980392156862) 33%, transparent 33%, transparent 79%, rgba(111, 111, 111, 0.027450980392156862) 79%, rgba(111, 111, 111, 0.027450980392156862) 100%), linear-gradient(303deg, rgba(215, 215, 215, 0.027450980392156862) 0%, rgba(215, 215, 215, 0.027450980392156862) 7%, transparent 7%, transparent 90%, rgba(192, 192, 192, 0.027450980392156862) 90%, rgba(192, 192, 192, 0.027450980392156862) 100%), linear-gradient(302deg, rgba(113, 113, 113, 0.027450980392156862) 0%, rgba(113, 113, 113, 0.027450980392156862) 34%, transparent 34%, transparent 73%, rgba(65, 65, 65, 0.027450980392156862) 73%, rgba(65, 65, 65, 0.027450980392156862) 100%), linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
    /* background-image: linear-gradient(144deg, rgba(192, 192, 192, 0.03) 0%, rgba(192, 192, 192, 0.03) 23%,rgba(36, 36, 36, 0.03) 23%, rgba(36, 36, 36, 0.03) 29%,rgba(248, 248, 248, 0.03) 29%, rgba(248, 248, 248, 0.03) 44%,rgba(250, 250, 250, 0.03) 44%, rgba(250, 250, 250, 0.03) 61%,rgba(36, 36, 36, 0.03) 61%, rgba(36, 36, 36, 0.03) 100%),linear-gradient(200deg, rgba(81, 81, 81, 0.03) 0%, rgba(81, 81, 81, 0.03) 68%,rgba(36, 36, 36, 0.03) 68%, rgba(36, 36, 36, 0.03) 82%,rgba(187, 187, 187, 0.03) 82%, rgba(187, 187, 187, 0.03) 95%,rgba(13, 13, 13, 0.03) 95%, rgba(13, 13, 13, 0.03) 97%,rgba(107, 107, 107, 0.03) 97%, rgba(107, 107, 107, 0.03) 100%),linear-gradient(228deg, rgba(248, 248, 248, 0.03) 0%, rgba(248, 248, 248, 0.03) 25%,rgba(125, 125, 125, 0.03) 25%, rgba(125, 125, 125, 0.03) 28%,rgba(228, 228, 228, 0.03) 28%, rgba(228, 228, 228, 0.03) 32%,rgba(185, 185, 185, 0.03) 32%, rgba(185, 185, 185, 0.03) 84%,rgba(82, 82, 82, 0.03) 84%, rgba(82, 82, 82, 0.03) 100%),linear-gradient(244deg, rgba(15, 15, 15, 0.03) 0%, rgba(15, 15, 15, 0.03) 35%,rgba(224, 224, 224, 0.03) 35%, rgba(224, 224, 224, 0.03) 52%,rgba(251, 251, 251, 0.03) 52%, rgba(251, 251, 251, 0.03) 69%,rgba(83, 83, 83, 0.03) 69%, rgba(83, 83, 83, 0.03) 72%,rgba(219, 219, 219, 0.03) 72%, rgba(219, 219, 219, 0.03) 100%),linear-gradient(338deg, rgba(57, 57, 57, 0.03) 0%, rgba(57, 57, 57, 0.03) 32%,rgba(166, 166, 166, 0.03) 32%, rgba(166, 166, 166, 0.03) 54%,rgba(161, 161, 161, 0.03) 54%, rgba(161, 161, 161, 0.03) 56%,rgba(158, 158, 158, 0.03) 56%, rgba(158, 158, 158, 0.03) 61%,rgba(25, 25, 25, 0.03) 61%, rgba(25, 25, 25, 0.03) 100%),linear-gradient(90deg, rgb(225, 225, 225),rgb(231, 231, 231)); */
}

.footer-effect-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: auto;
}

.footer-circle-effect {
    width: 500px;
    height: 500px;
    background-color: rgb(196 242 65 / 0.2);
    border-radius: 100%;
    margin: 0px -50px;
}

.footer-circle-effect:nth-child(1) {
    filter: blur(145px);
}

.footer-circle-effect:nth-child(2) {
    filter: blur(145px);
}

.footer-circle-effect:nth-child(3) {
    filter: blur(145px);
}

.main-div {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.card-container {
    width: 1242px;
    /* margin: auto; */
    padding: 25px;
    border-style: dashed;
    border-radius: 10px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(220 224 211 / var(--tw-border-opacity));
    /* border-color: rgb(196, 195, 195); */
    background-color: #0e2f44;
    color: #ffffff;
    margin: 12px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 3rem;
}


.image-01 {
    width: 560px;
    height: 330px;
    border-radius: 10px;
}

.text-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.card-button {
    width: max-content;
    border-radius: 25px;
    background-color: #7cba2796;
    border: 1px solid #7cba27;
    color: #000;
    font-size: medium;
}

.badgebtn {
    margin-bottom: 0.5rem;
    display: inline-block;
    /* border-radius: 100px; */
    width: max-content;
    border-width: 1px;
    /* --tw-border-opacity: 1;
    /* background-color: rgb(177 227 70 / 0.1); */
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    /* font-size: var(--global-font-size-xxs); */
    line-height: 1.5rem;
    /* color: #9ac65e;
    border: 1px solid #9ac65e; */
    color: #ffffff;
    border: 1px solid #ffffff;
    /* background-color: rgb(177 227 70 / 0.1); */
}

.notice-flex {
    display: flex;
    gap: 10px;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.card-title {
    font-size: 22px;
    color: #18181B;
}

.card-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.btns {
    border-radius: 7px;
    border: none;
    width: 130px;
    height: 40px;
    background-color: #ffffff;
    color: #7cba27;
    border: 1.5px solid #7cba27;
}

.btns:hover {
    border: 1px solid #7cba27;
    background-color: #7cba27;
    color: #ffffff;
    transition: 0.5s;
}

.dashed-border {
    border-style: dashed;
    border-color: #D9E0C5;
    border-radius: 10px;
}

/* .notice-date {
   font-size: clamp(14px, 2.5vw, 18px);
} */
/* .notice-card-title {
    line-height: 1.33;
    font-size: clamp(14px, 2.5vw, 18px);
    color: #18181B;
}  */
.flex-gap {
    display: flex;
    gap: 20px;
}

.noticeSwiper {
    padding-top: 40px;
    padding-bottom: 30px;
}

.card-space-container {
    /* border: 2px solid black; */
    background-color: #0e2f44;
    display: -webkit-inline-box;
    /* display:flex; */
    justify-content: center;
    /* margin-left: ; */
    border-radius: 10px;
}

.card-centre-container {
    display: flex;
    justify-content: center;
    padding-bottom: 25px;
}

.view-all-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

/* .view-all-button {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.view-all-btn {
    border-radius: 18px;
    border: none;
    width: 130px;
    height: 40px;
    background-color: #0E2F44;
    color: #ffffff;
}

.view-all-btn:hover {
    background-color: white;
    border: 2px solid #0E2F44;
    color: #18181B;
    transition: 100ms;
}

/* 
.view-all-btn {
    border-radius: 7px;
    border: none;
    width: 130px;
    height: 40px;
    background-color: #7cba27;
    color: #ffffff;
    border: 1.5px solid #7cba27;
} */

.top-spacing {
    margin: 10px 0px;
}

.see-more-text-card {
    /* background-color: aqua; */
    padding: 0px;
    margin: 0px;
    height: 40px;
    display: flex;
    justify-content: start;
    margin-top: 10px;
}

/* /the button/ */
.see-more-button-card {
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 10px;
    border-radius: 10rem;
    color: #000000;
    /* text-transform: uppercase; */
    font-size: 1rem;
    letter-spacing: .15rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    border: none;
    z-index: 1;
    border: 1px solid rgb(175, 172, 172);
}

.see-more-button-card:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10rem;
    z-index: -2;
}

.see-more-button-card:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #000000;
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
}

.see-more-button-card:hover {
    color: #000000;
}

.see-more-button-card:hover:before {
    width: 100%;
}

/* /text inaside the button/ */
.text-color-card {
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    /* font-size: 15px; */
    align-content: start;
    font-weight: 500;
    display: flex;
    width: 100%;
    letter-spacing: 0.2px;
    justify-content: start;
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.see-more-button-card:hover .text-color-card {
    color: #ffffff;
}

.notice-image-container {
    position: relative;
    display: -webkit-inline-box;
    align-items: center;
    /* margin-left:170px */
    z-index: 4;
}

.notice-card-title {
    line-height: 1.33;
    /* font-size: var(--global-font-size-sub-title); */
    /* font-size: clamp(18px, 2.5vw, 22px); */
    color: #ffffff;
    text-align: justify;
}

.notice-card-desc {
    padding-top: 10px;
    text-align: justify;
    /* font-size: clamp(14px, 2.5vw, 18px); */
}

.notice-button-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    /* padding-left: 0 10px; */
}

.notice-button {
    width: 14%;
    text-align: center;
    border: transparent;
    border-bottom: 2px solid;
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
    padding-bottom: 10px;
    z-index: 1;
    /* font-size: 1.25rem; */
    line-height: 1.75rem;
    font-weight: 500;
    background-color: transparent;
    /* transition-duration: 5000ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: right; */
}

/* .left-button{
    width: 14%;
} */

.notice-circle-effect {
    background-color: rgb(196 242 65 / 0.4);
}

.notice-section-spacing {
    padding: 30px 0;
}

.shadow-nav {
    --tw-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 0px 30px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    /* box-shadow: 0px 0px 10px #d8d7d7; */
}

.image-shadow-nav {
    --tw-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 0px 30px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    object-fit: cover;
    /* box-shadow: 0px 0px 10px #d8d7d7; */
}


@media screen and (max-width: 1300px) {
    .notice-image-container {
        display: flex;
        /* margin: auto; */
    }

    .card-container {
        width: 90%;
        margin: 12px auto;
        grid-template-columns: auto;
    }

    .image-01 {
        width: 90%;
        height: 400px;
        margin: auto;
        object-fit: contain;
        /* aspect-ratio: 17/10; */
    }

    .card-space-container {
        width: 90%;
    }

}

@media screen and (max-width: 590px) {
    .flex-between {
        flex-direction: column;
        align-items: flex-start;
    }

    .image-01 {
        height: 250px;
        object-fit: contain;
    }
}

@media screen and (max-width: 400px) {


    .flex-between {
        align-items: flex-start;
    }


}


@media screen and (max-width: 220px) {
    /* .notice-button {
        font-size: 12px;
    } */

    .heading-container h2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 1024px) {
    .notice-button {
        width: fit-content;
        text-align: center;
    }

    /* .noticeSwiper {
        padding-bottom: 0px;
    } */
}


@media only screen and (min-width: 1280px) and (max-width: 1366px) {
    /* .notice-card-title {
        line-height: 1.33;
        font-size: 22px;
        color: #18181B;
    } */

}

@media only screen and (min-width: 860px) and (max-width: 1279px) {
    /* .notice-card-title {
        line-height: 1.33;
        font-size: 20px;
        color: #18181B;
    } */
    /* .notice-button {
        font-size: 16px;
    } */
}


@media only screen and (min-width: 490px) and (max-width: 859px) {
    /* .notice-card-title {
        line-height: 1.33;
        font-size: 18px;
        color: #18181B;
    } */
    /* .notice-button {
        font-size: 14px;
    } */
}

@media only screen and (min-width:200px) and (max-width: 489px) {

    /* .notice-card-title {
        line-height: 1.33;
        font-size: 18px;
        color: #18181B;
    } */
    /* .notice-button {
        font-size: 12px;
    } */
}