/* .glow-effect {
  z-index: -1;
}

.Subhero-text-container {
  display: flex;
  justify-content: center;
  font-family: kumbhfonts;
  font-size: 18px;
  color: #18181b;
}

.Hero-text-container {
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: kumbhfonts;
  color: #18181b;
}

.Hero-text {
  font-size: 64px;
  margin-bottom: 80px;
  padding: 1%;
}

.List-Grid-Container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  justify-items: center;
  align-items: start;
  margin-top: 5%;
}

.Card-Container {
  grid-column: 1 / 9;
}

.Card-holder {
  background-color: white;
  margin-bottom: 3%;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.121);
}

.List-Grid-Card-Container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: 5%;
  width: 850px;
  min-height: 415px;
  place-items: center;
  border-radius: 15px;
  border: 2px dotted #dce0d3;
  justify-content: center;
  align-items: center;
}

.List-Card-Image {
  width: 365px;
  height: 365px;
  border-radius: 15px;
  margin-left: 20px;
}

.List-Card-Image img {
  width: 365px;
  height: 365px;
  border-radius: 15px;
  object-fit: cover;
}

.tag-container button {
  background: #b1e3461a;
  border: 1px solid #b1e346;
  color: #b1e346;
  padding: 4px 12px;
  border-radius: 25px;
  font-size: 16px;
  font-family: kumbhfonts;
}

.List-Card-Content {
  width: 365px;
  height: auto;
}

.List-Card-Heading-text {
  font-size: 24px;
  font-family: kumbhfonts;
  color: #18181b;
  text-align: start;
}

.TimeStamp {
  display: flex;
  gap: 2%;
  font-family: kumbhfonts;
  font-size: 18px;
  color: #18181b;
}

.List-Card-text {
  font-family: kumbhfonts;
  font-size: 18px;
  color: #18181b;
}

.List-Card-button button {
  border-radius: 50px;
  border: 1px solid black;
  background: none;
  padding: 5px 10px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.List-Card-button button:hover {
  background: black;
  color: white;
}

.List-Grid-Categories-Container1 {
  background-color: white;
  height: 100%;
  border-radius: 20px;
  padding: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.121);
}

.flexing-container {
  display: flex;
  justify-content: center;
}

.ssContent-container {
  display: block;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 2.5%;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 40px;
}

.Categories-Container {
  border: 2px dotted #dce0d3;
  border-radius: 15px;
  padding: 30px;
  width: 350px;
}

.mainsssbg {
  padding-top: 40px;
  background-image: linear-gradient(22.5deg, rgba(242, 242, 242, 0.03) 0%, rgba(242, 242, 242, 0.03) 16%, rgba(81, 81, 81, 0.03) 16%, rgba(81, 81, 81, 0.03) 26%, rgba(99, 99, 99, 0.03) 26%, rgba(99, 99, 99, 0.03) 73%, rgba(43, 43, 43, 0.03) 73%, rgba(43, 43, 43, 0.03) 84%, rgba(213, 213, 213, 0.03) 84%, rgba(213, 213, 213, 0.03) 85%, rgba(125, 125, 125, 0.03) 85%, rgba(125, 125, 125, 0.03) 100%), linear-gradient(22.5deg, rgba(25, 25, 25, 0.03) 0%, rgba(25, 25, 25, 0.03) 54%, rgba(144, 144, 144, 0.03) 54%, rgba(144, 144, 144, 0.03) 60%, rgba(204, 204, 204, 0.03) 60%, rgba(204, 204, 204, 0.03) 76%, rgba(37, 37, 37, 0.03) 76%, rgba(37, 37, 37, 0.03) 78%, rgba(115, 115, 115, 0.03) 78%, rgba(115, 115, 115, 0.03) 91%, rgba(63, 63, 63, 0.03) 91%, rgba(63, 63, 63, 0.03) 100%), linear-gradient(157.5deg, rgba(71, 71, 71, 0.03) 0%, rgba(71, 71, 71, 0.03) 6%, rgba(75, 75, 75, 0.03) 6%, rgba(75, 75, 75, 0.03) 15%, rgba(131, 131, 131, 0.03) 15%, rgba(131, 131, 131, 0.03) 18%, rgba(110, 110, 110, 0.03) 18%, rgba(110, 110, 110, 0.03) 37%, rgba(215, 215, 215, 0.03) 37%, rgba(215, 215, 215, 0.03) 62%, rgba(5, 5, 5, 0.03) 62%, rgba(5, 5, 5, 0.03) 100%), linear-gradient(90deg, #ffffff, #ffffff);
}

.Search-Bar h1 {
  text-align: start;
  margin-left: 2%;
}

.Search-Bar input {
  border-radius: 50px;
}

.List-Grid-Card-Categories h1 {
  text-align: start;
  margin-bottom: 5%;
}

.List-Grid-Card-Categories ul {
  list-style-type: none;
  padding: 0;
}

.List-Grid-Card-Categories ul li {
  padding: 20px 0;
  border-bottom: 1px dotted rgb(211, 210, 210);
  font-size: 18px;
  font-family: kumbhfonts;
  color: #18181b;
  cursor: pointer;
}

.List-Grid-Card-Categories ul li:hover {
  transition: all ease-in-out 500ms;
  border-bottom: 1px solid black;
}

.button-container button {
  border-radius: 50px;
  border: 1px solid black;
  background: none;
  color: black;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button-container button {
  display: inline-block;
  padding: 5px 1px;
  border-radius: 25px;
  margin-left: 0;
  margin-bottom: 20px;
  color: #000000;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  border: none;
  z-index: 1;
  border: 1px solid rgb(175, 172, 172);
}

.button-container button:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10rem;
  z-index: -2;
}

.button-container button:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: black;
  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}

.button-container button:hover {
  color: white;
}

.button-container button:hover:before {
  width: 100%;
}

.button-container button:hover .text-contact-us-dept {
  color: white;
}

.button-container {
  display: flex;
  gap: 2%;
  flex-direction: column;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background: none;
  border: 1px solid #d9e0c5;
  color: black;
  padding: 5px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 50px;
}

.pagination button.active {
  transition: all 100ms ease-in;
  background-color: #b1e346;
  border: none;
  color: black;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.List-Grid-Categories-Container2 {

  background-color: white;
  width: 900px;
  height: 800px;
  border-radius: 20px;
  padding: 5%;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.121);

  padding-bottom: 25px;
}

.imagedetail2 {
  margin-left: 150px;
  height: 300px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parafrom {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 36px;
  font-weight: 600;
}

.paraDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
} */

.success-stories-single-section {
  width: 100%;
  padding: 40px 0;
  background-image: linear-gradient(22.5deg, #f2f2f208, #f2f2f208 16%, #51515108 0, #51515108 26%, #63636308 0, #63636308 73%, #2b2b2b08 0, #2b2b2b08 84%, #d5d5d508 0, #d5d5d508 85%, #7d7d7d08 0, #7d7d7d08), linear-gradient(22.5deg, #19191908, #19191908 54%, #90909008 0, #90909008 60%, #cccccc08 0, #cccccc08 76%, #25252508 0, #25252508 78%, #73737308 0, #73737308 91%, #3f3f3f08 0, #3f3f3f08), linear-gradient(157.5deg, #47474708, #47474708 6%, #4b4b4b08 0, #4b4b4b08 15%, #83838308 0, #83838308 18%, #6e6e6e08 0, #6e6e6e08 37%, #d7d7d708 0, #d7d7d708 62%, #05050508 0, #05050508), linear-gradient(90deg, #fff, #fff);
}

.success-stories-single-main-div {
  display: flex;
  justify-content: center;
  width: 1400px;
  gap: 50px;
  margin: auto;
  padding: 40px 0;
}

.ssd-list-div {
  width: 30%;
}

.ssd-content-div {
  width: 70%;
}

.ssd-content-heading {
  text-align: center;
  margin-bottom: 20px;
}

.ssd-content-heading h2 {
  color: #18181b;
}

.ssd-content-image-div {
  width: 900px;
  height: 450px;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.121);
}

.ssd-content-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.ssd-content-text {
  width: 100%;
  padding: 20px 0;
}

.ssd-content-text>* {
  color: #18181b;
  text-align: justify;
}

.ssd-list-bg {
  background-color: #ffffff;
  padding: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.121);
  border-radius: 12px;
}

.ssd-border-div {
  border: 2px dotted #dce0d3;
  border-radius: 12px;
  padding: 30px;
}

.ssd-all-post-list ul li {
  padding: 20px 0;
  font-size: 18px;
  border-bottom: 1px dotted #dce0d3;
}

.ssd-all-post-list ul li:hover {
  border-bottom: 2px dotted #b7baaf;
}