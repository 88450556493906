.gujaratHeadingheader {
    /* background-color: #7cba27; */
    background-color: #0e2f44;
    text-align: center;
    color: white;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gujaratHeadingheaderright {
    /* background-color: #7cba27; */
    background-color: #e0dddd;
    text-align: center;
    color: #0e2f44;
    height: 40px;
    display: flex;
    justify-content: right;
    align-items: right;
}

.linkheader {
    /* background-color: #7cba27; */
    /* display: flex; */
    font-size: 14px;
    text-align: right;
    color: white;
    padding-left: 20px;
    justify-content: right;
    align-items: right;
    text-align: center;
}
.linkheaderright {
    /* background-color: #7cba27; */
    display: flex;
    font-size: 14px;
    /* text-align: center; */
    color: #0e2f44;
    padding-right: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.headingtitle {
    padding: 10px 0;
    font-family: kumbhfonts;
    font-size: clamp(8px, 2.5vw, 18px);
    /* font-weight: bold; */
}

.headingtitlemq {
    padding: 10px 0;
    font-family: kumbhfonts;
    /* font-size: clamp(8px, 2.5vw, 18px); */
}

/* .fixed-header-class {
    width: 100%;
    position: sticky;
    z-index: 9999999;
    top: 0%;
    background-color: #f6f6f6;
} */

/* Header menu css */
.navbar-brand .navbar-brand-item {
    height: 60px;
    display: block;
    width: auto;
}

.site-header {
    position: relative;
}

.site-header-dark {
    background-color: black;
}

.site-header .site-branding img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    max-height: 60px;
    z-index: 1;
    position: relative;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.site-navigation ul.navigation>li>a {
    display: block;
    padding: 0px 17px;
    text-decoration: none;
    position: relative;
    z-index: 1;
    font-size: 16px;
    font-weight: 600;
    height: 95px;
    line-height: 95px;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: var(--pbmit-blackish-color);
}

.site-navigation-dark ul.navigation>li>a {
    display: block;
    padding: 0px 17px;
    text-decoration: none;
    position: relative;
    z-index: 1;
    font-size: 16px;
    font-weight: 600;
    height: 95px;
    line-height: 95px;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: white;
}

.textcolor {
    color: chocolate;
}

.site-navigations ul li.active>a {
    color: var(--pbmit-global-color);
}

.site-navigation ul.navigation>li>a:hover {
    color: var(--pbmit-global-color);
}

.site-navigation-dark ul.navigation>li>a:hover {
    color: var(--pbmit-global-color);
}

.site-navigation ul.navigation>li>a:hover:after {
    color: var(--pbmit-global-color);
}

.site-navigation-dark ul.navigation>li>a:hover:after {
    color: var(--pbmit-global-color);
}

.site-navigation ul.navigation a {
    font-family: kumbhfonts;
    font-weight: 700;
    font-size: 15px;
}

.site-navigation-dark ul.navigation a {
    font-family: kumbhfonts;
    font-weight: 700;
    font-size: 15px;
}

.site-header .site-navigation ul.navigation>li.active>a {
    color: var(--pbmit-global-color);
}

.main-menu .navigation>li {
    position: relative;
    float: left;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.main-menu .navigation>li>ul,
.main-menu .navigation>li>ul>li>ul {
    min-width: 240px;
    box-shadow: 0 0 60px 0 rgb(53 57 69 / 15%) !important;
    border-bottom: inherit;
    border-top: 4px solid var(--pbmit-global-color);
    position: absolute;
    left: 0;
    z-index: 100;
    visibility: hidden;
    background-color: #f6f6f6;
    opacity: 0;
    -webkit-transform: translateY(15px);
    -moz-transform: translateY(15px);
    -ms-transform: translateY(15px);
    -o-transform: translateY(15px);
    transform: translateY(15px);
    -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.main-menu .navigation>li>ul>li>ul {
    left: 100%;
    top: 0;
}

.main-menu .navigation>li.dropdown:hover>ul,
.main-menu .navigation>li>ul>li.dropdown:hover>ul {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    z-index: 999;
}

.main-menu .navigation>li>ul li a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
}

.main-menu .navigation>li>ul a {
    padding: 15px 30px;
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin: 0;
    text-transform: capitalize;
}

.main-menu .navigation>li>ul>li a:before {
    position: absolute;
    content: '';
    left: 18px;
    top: 24px;
    width: 0;
    height: 2px;
    background-color: transparent;
    -webkit-transition: all .500s ease-in-out;
    transition: all .500s ease-in-out;
}

.main-menu .navigation>li>ul>li:hover a:before {
    background-color: rgba(255, 255, 255, .50);
    width: 10px;
}

.main-menu .navigation>li>ul li {
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.main-menu .navigation>li>ul li.active a {
    color: var(--pbmit-global-color);
}

.main-menu .navigation>li>ul a:hover {
    background-color: var(--pbmit-global-color);
    color: var(--pbmit-white-color);
}

.main-menu .navigation>li>ul li.active a:hover,
.main-menu .navigation>li>ul a:hover {
    padding-left: 40px;
    color: var(--pbmit-white-color);
}



.header-button .pbmit-btn {
    padding: 15px 24px;
}

.header-button .pbmit-btn i {
    font-size: 20px;
}

.site-header .search-btn {
    margin-right: 15px;
    font-size: 17px;
}

.site-header .social-icons li>a {
    height: 50px;
    line-height: 50px;
}

.ipad-view-search {
    display: none;
}

.pbmit-link li {
    margin: 0 10px;
}

/** sticky-header **/
.sticky-header {
    position: fixed !important;
    top: 0px;
    width: 100%;
    background-color: #fff;
    z-index: 999;
    margin: 0;
    -webkit-box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
    box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
    padding: 0;
}

.site-header-menu .logo-img.stickylogo,
.site-header-menu.sticky-header .logo-img {
    max-height: 55px;
}
.social-logo{
    max-height: 25px;
}
.site-header-menu.sticky-header .logo-img.stickylogo {
    display: inline-block;
}

.sticky-header .site-navigation ul.navigation>li>a {
    height: 90px;
    line-height: 90px;
}

/* Pre Header */
.pre-header {
    height: 55px;
    line-height: 55px;
}

.list-unstyled i {
    font-size: 16px;
    color: #666;
}

.closepanel,
.pbmit-mobile-menu-bg,
.site-header .righticon {
    display: none;
}


/* Header from shortcode header6 */

.header-style-6 .site-header-menu {
    padding: 0 30px;
}

.header-style-6.site-header .site-branding img {
    max-height: 95px;
}

.header-style-6 .site-navigation ul.navigation>li>a {
    padding: 0 10px;
    height: 120px;
    line-height: 120px;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    font-family: kumbhfonts;
}

@media only screen and (min-width: 1601px) and (max-width: 1900px) {
    .header-style-6 .site-navigation ul.navigation>li>a {
        padding: 0 18px;
        height: 100px;
        line-height: 100px;
        font-size: 16px;
        font-weight: 700;
        text-transform: capitalize;
        font-family: kumbhfonts;
    }

    .header-style-6.site-header .site-branding img {
        max-height: 95px;
    }

    .gujaratHeadingheader {
        /* background-color: #7cba27; */
        background-color: #0e2f44;
        text-align: center;
        color: white;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .gujaratHeadingheaderright {
        /* background-color: #7cba27; */
        background-color: #e0dddd;
        text-align: center;
        color: #0e2f44;
        height: 40px;
        display: flex;
        justify-content: right;
        align-items: right;
    }

    .headingtitle {
        padding: 10px 0;
        font-family: kumbhfonts;
        font-size: clamp(8px, 2.5vw, 18px);
        font-weight: bold;
    }
}

@media only screen and (min-width: 1343px) and (max-width: 1600px) {
    .header-style-6 .site-navigation ul.navigation>li>a {
        height: 100px;
        line-height: 100px;
        font-size: 16px;
        font-weight: 700;
        text-transform: capitalize;
        font-family: kumbhfonts;
    }

    .header-style-6.site-header .site-branding img {
        max-height: 75px;
    }

    .gujaratHeadingheader {
        /* background-color: #7cba27; */
        background-color: #0e2f44;
        text-align: center;
        color: white;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .gujaratHeadingheaderright {
        /* background-color: #7cba27; */
        background-color: #e0dddd;
        text-align: center;
        color: #0e2f44;
        height: 40px;
        display: flex;
        justify-content: right;
        align-items: right;
    }

    .headingtitle {
        padding: 10px 0;
        font-family: kumbhfonts;
        font-size: clamp(8px, 2.5vw, 18px);
        font-weight: bold;
    }
}

@media screen and (max-width: 500px){
    .linkheaderright {
        /* background-color: #7cba27; */
        display: flex;
        font-size: 10px;
        /* text-align: center; */
        color: #0e2f44;
        padding-right: 20px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    
}

@media only screen and (min-width: 1286px) and (max-width: 1343px) {
    .header-style-6 .site-navigation ul.navigation>li>a {
        height: 90px;
        line-height: 90px;
        font-size: 14px;
        font-weight: 700;
        text-transform: capitalize;
        font-family: kumbhfonts;
    }

    .header-style-6.site-header .site-branding img {
        max-height: 70px;
    }

    .gujaratHeadingheader {
        /* background-color: #7cba27; */
        background-color: #0e2f44;
        text-align: center;
        color: white;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .gujaratHeadingheaderright {
        /* background-color: #7cba27; */
        background-color: #e0dddd;
        text-align: center;
        color: #0e2f44;
        height: 40px;
        display: flex;
        justify-content: right;
        align-items: right;
    }

    .headingtitle {
        padding: 10px 0;
        font-family: kumbhfonts;
        font-size: clamp(8px, 2.5vw, 16px);
        font-weight: bold;
    }
}

@media only screen and (min-width: 1241px) and (max-width: 1286px) {
    .header-style-6 .site-navigation ul.navigation>li>a {
        height: 90px;
        line-height: 90px;
        font-size: 14px;
        font-weight: 700;
        text-transform: capitalize;
        font-family: kumbhfonts;
    }

    .header-style-6.site-header .site-branding img {
        max-height: 70px;
    }

    .gujaratHeadingheader {
        /* background-color: #7cba27; */
        background-color: #0e2f44;
        text-align: center;
        color: white;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .gujaratHeadingheaderright {
        /* background-color: #7cba27; */
        background-color: #e0dddd;
        text-align: center;
        color: #0e2f44;
        height: 40px;
        display: flex;
        justify-content: right;
        align-items: right;
    }

    .headingtitle {
        padding: 10px 0;
        font-family: kumbhfonts;
        font-size: clamp(8px, 2.5vw, 14px);
        font-weight: bold;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1240px) {
    .header-style-6 .site-navigation ul.navigation>li>a {
        height: 80%;
        line-height: 80px;
        font-size: 13px;
        font-weight: 700;
        text-transform: capitalize;
        font-family: kumbhfonts;
    }

    .header-style-6.site-header .site-branding img {
        max-height: 70px;
    }

    .gujaratHeadingheader {
        /* background-color: #7cba27; */
        background-color: #0e2f44;
        text-align: center;
        color: white;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .gujaratHeadingheaderright {
        /* background-color: #7cba27; */
        background-color: #e0dddd;
        text-align: center;
        color: #0e2f44;
        height: 40px;
        display: flex;
        justify-content: right;
        align-items: right;
    }

    .headingtitle {
        padding: 10px 0;
        font-family: kumbhfonts;
        font-size: clamp(8px, 2.5vw, 12px);
        font-weight: bold;
    }
}



.header-style-6 .pbmit-right-main-box {
    display: flex;
    align-items: center;
}

.header-style-6 .pbmit-header-contact {
    display: inline-block;
    position: relative;
    padding-left: 40px;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: -.4px;
    font-weight: 700;
    margin-right: 20px;
    font-family: kumbhfonts;
}

.header-style-6 .pbmit-header-contact:before {
    position: absolute;
    content: '\e833';
    left: 0;
    top: 0;
    font-size: 28px;
    line-height: 28px;
    font-weight: 400;
    color: var(--pbmit-global-color);
    font-family: 'pbminfotech-base-icons';
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.header-style-6 .pbmit-header-button2 a {
    padding: 13px 38px;
    text-transform: capitalize;
}

.header-nav-g20 {
    display: flex;
    justify-content: center;
    align-items: center;
}