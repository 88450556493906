.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  
  .scroll-to-top button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px 15px;
    cursor: pointer;
  }
  .floating-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #7cba27;
    color: white;
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 1000;
  }

  .float-arrow-align{
    display: flex;
    justify-content: center;
    align-items: center;
  }