.glow-effect {
    z-index: -1;
  }
  
  .Subhero-text-container {
    display: flex;
    justify-content: center;
    font-family: kumbhfonts;
    font-size: 18px;
    color: #18181b;
  }
  
  .Hero-text-container {
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: kumbhfonts;
    color: #18181b;
  }
  
  .Hero-text {
    font-size: 64px;
    margin-bottom: 80px;
    padding: 1%;
  }
  
  .List-Grid-Container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(12, 1fr);
    justify-items: center;
    align-items: start;
    margin-top: 5%;
  }
  
  .Card-Container {
    grid-column: 1 / 9;
  }
  
  .Card-holder {
    background-color: white;
    margin-bottom: 3%;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.121);
  }
  
  .List-Grid-Card-Container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    row-gap: 5%;
    width: 850px;
    min-height: 415px;
    place-items: center;
    border-radius: 15px;
    border: 2px dotted #dce0d3;
    justify-content: center;
    align-items: center;
  }
  
  .List-Card-Image {
    width: 365px;
    height: 365px;
    border-radius: 15px;
    margin-left: 20px;
  }
  
  .List-Card-Image img {
    width: 365px;
    height: 365px;
    border-radius: 15px;
    object-fit: contain;
  }
  
  .tag-container button {
    background: #b1e3461a;
    border: 1px solid #b1e346;
    color: #b1e346;
    padding: 4px 12px;
    border-radius: 25px;
    /* font-size: 16px; */
    font-family: kumbhfonts;
  }
  
  .List-Card-Content {
    width: 365px;
    height: auto;
  }
  
  .List-Card-Heading-text {
    /* font-size: clamp(22px, 2.5vw, 16px); */
    font-family: kumbhfonts;
    color: #18181b;
    text-align: start;
    line-height: normal;
  }
  
  .TimeStamp {
    display: flex;
    gap: 2%;
    font-family: kumbhfonts;
    font-size: 18px;
    color: #18181b;
  }
  
  .List-Card-text {
    font-family: kumbhfonts;
    /* font-size: clamp(18px,2.5vw, 14px); */
    color: #18181b;
  }
  
  .List-Card-button button {
    border-radius: 50px;
    border: 1px solid black;
    background: none;
    padding: 5px 10px;
    color: black;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .List-Card-button button:hover {
    background: black;
    color: white;
  }
  
  .List-Grid-Categories-Container {
    grid-column: 9/12;
    background-color: white;
    width: 400px;
    height: auto;
    border-radius: 20px;
    padding: 5%;
    height: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.121);
    align-items: end;
  }
  
  .Categories-Container {
    border: 2px dotted #dce0d3;
    border-radius: 15px;
    padding: 30px;
  }
  
  .mainambg {
    padding: 50px 0;
    background-image: linear-gradient(134deg, rgba(1, 1, 1, 0.02) 0%, rgba(1, 1, 1, 0.02) 16%, transparent 16%, transparent 76%, rgba(58, 58, 58, 0.02) 76%, rgba(58, 58, 58, 0.02) 100%), linear-gradient(215deg, rgba(166, 166, 166, 0.02) 0%, rgba(166, 166, 166, 0.02) 33%, transparent 33%, transparent 79%, rgba(111, 111, 111, 0.02) 79%, rgba(111, 111, 111, 0.02) 100%), linear-gradient(303deg, rgba(215, 215, 215, 0.02) 0%, rgba(215, 215, 215, 0.02) 7%, transparent 7%, transparent 90%, rgba(192, 192, 192, 0.02) 90%, rgba(192, 192, 192, 0.02) 100%), linear-gradient(302deg, rgba(113, 113, 113, 0.02) 0%, rgba(113, 113, 113, 0.02) 34%, transparent 34%, transparent 73%, rgba(65, 65, 65, 0.02) 73%, rgba(65, 65, 65, 0.02) 100%), linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
  }
  
  .Search-Bar h1 {
    text-align: start;
    margin-left: 2%;
  }
  
  .Search-Bar input {
    border-radius: 50px;
  }
  
  .List-Grid-Card-Categories h1 {
    text-align: start;
    margin: 5%;
  }
  .List-Grid-Card-Categories{
    margin: 30px;
  }
  
  .List-Grid-Card-Categories ul {
    list-style-type: none;
    padding: 0;
  }
  
  .List-Grid-Card-Categories ul li {
    padding: 20px 0;
    border-bottom: 1px dotted rgb(211, 210, 210);
    font-size: 18px;
    font-family: kumbhfonts;
    color: #18181b;
    cursor: pointer;
  }
  
  .List-Grid-Card-Categories ul li:hover {
    transition: all ease-in-out 500ms;
    border-bottom: 1px solid black;
  }
  
  .button-container button {
    border-radius: 50px;
    border: 1px solid black;
    background: none;
    color: black;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .button-container button {
    display: inline-block;
    padding: 5px 1px;
    /* Adjust padding to change size */
    border-radius: 25px;
    /* Adjust border-radius to change shape */
    margin-left: 0;
    /* Remove margin-left */
    margin-bottom: 20px;
    /* Add margin-bottom to create vertical gap */
    color: #000000;
    font-size: 1rem;
    /* Adjust font-size to change text size */
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    border: none;
    z-index: 1;
    border: 1px solid rgb(175, 172, 172);
  }
  
  .button-container button:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 10rem;
    z-index: -2;
  }
  
  .button-container button:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: black;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
  }
  
  .button-container button:hover {
    color: white;
  }
  
  .button-container button:hover:before {
    width: 100%;
  }
  
  .button-container button:hover .text-contact-us-dept {
    color: white;
  }
  
  .button-container {
    display: flex;
    gap: 2%;
    flex-direction: column;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background: none;
    border: 1px solid #d9e0c5;
    color: black;
    padding: 5px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 50px;
  }
  
  .pagination button.active {
    transition: all 100ms ease-in;
    background-color: #b1e346;
    border: none;
    color: black;
  }
  
  .pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .pb-announcement {
    padding-bottom: 45px;
  }
  .tag-container{
    margin-bottom: 5px;
  }
  
  @media screen  and (min-width: 1201px) and (max-width: 1600px) {
    .List-Card-Heading-text {
      /* font-size: 22px; */
      font-family: kumbhfonts;
      color: #18181b;
      text-align: start;
      line-height: normal;
    }
    .List-Card-text {
      font-family: kumbhfonts;
      /* font-size: 18px; */
      color: #18181b;
      text-align: justify;
  }
  }
  @media screen  and (min-width: 901px) and (max-width: 1200px) {
    .List-Card-Heading-text {
      /* font-size: 20px; */
      font-family: kumbhfonts;
      color: #18181b;
      text-align: start;
      line-height: normal;
    }
    .List-Card-text {
      font-family: kumbhfonts;
      /* font-size: 18px; */
      color: #18181b;
      text-align: justify;
  }
  }
  @media screen  and (min-width: 786px) and (max-width: 900px) {
    .List-Card-Heading-text {
      /* font-size: 18px; */
      font-family: kumbhfonts;
      color: #18181b;
      text-align: start;
      line-height: normal;
    }
    .List-Card-text {
      font-family: kumbhfonts;
      /* font-size: 16px; */
      color: #18181b;
      text-align: justify;
  }
  }
  @media screen  and (min-width: 200px) and (max-width: 785px) {
    .List-Card-Heading-text {
      /* font-size: 16px; */
      font-family: kumbhfonts;
      color: #18181b;
      text-align: start;
      line-height: normal;
    }
    .List-Card-text {
      font-family: kumbhfonts;
      /* font-size: 14px; */
      color: #18181b;
      text-align: justify;
  }
  }
  
  .notices-container{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }@media (min-width: 475px){.notices-container{
      max-width: 475px;
    }
  }@media (min-width: 640px){.notices-container{
      max-width: 640px;
    }
  }@media (min-width: 768px){.notices-container{
      max-width: 768px;
    }
  }@media (min-width: 1024px){.notices-container{
      max-width: 1024px;
    }
  }@media (min-width: 1280px){.notices-container{
      max-width: 1280px;
    }
  }@media (min-width: 1400px){.notices-container{
      max-width: 1400px;
    }
  }@media (min-width: 1536px){.notices-container{
      max-width: 1536px;
    }
  }
  .relative {
    position: relative;
  }
  .grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .grid {
    display: grid;
  }
  .gap-12 {
    gap: 3rem;
  }
  
  .items-center {
    align-items: center;
  }
  .grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  .\[\&\>\*\:not\(\:last-child\)\]\:mb-8>*:not(:last-child) {
    margin-bottom: 2rem;
  }
  
  .shadow-nav {
    --tw-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 0px 30px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .p-2\.5 {
    padding: 0.625rem;
  }
  .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
  .rounded-medium {
    border-radius: 20px;
  }
  .p-6 {
    padding: 1.5rem;
  }
  .border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgb(220 224 211 / var(--tw-border-opacity));
  }
  .border-dashed {
    border-style: dashed;
  }
  .border {
    border-width: 1px;
  }
  .rounded {
    border-radius: 12px;
  }
  .self-start {
    align-self: flex-start;
  }
  
  .shadow-nav {
    --tw-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 0px 30px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  
  .p-2\.5 {
    padding: 0.625rem;
  }
  .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
  .rounded-medium {
    border-radius: 20px;
  }
  
  .rounded-lg {
    border-radius: 0.5rem;
  }
  .w-full {
    width: 100%;
  }
  img, video {
    max-width: 100%;
    height: auto;
  }
  img, svg, video, canvas, audio, iframe, embed, object {
    display: block;
    vertical-align: middle;
  }
  
  @media not all and (min-width: 1400px){.max-1xl\:h-\[335px\]{
    height: 335px;
  }.max-1xl\:w-\[335px\]{
    width: 335px;
  }.max-1xl\:px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }.max-1xl\:blur-\[80px\]{
    --tw-blur: blur(80px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  }
  @media not all and (min-width: 1280px){.max-xl\:-bottom-\[54px\]{
    bottom: -54px;
  }.max-xl\:top-\[42px\]{
    top: 42px;
  }.max-xl\:py-150{
    padding-top: 150px;
    padding-bottom: 150px;
  }.max-xl\:leading-\[1\.33\]{
    line-height: 1.33;
  }
  }
  @media not all and (min-width: 1024px){.max-lg\:\!left-0{
    left: 0px !important;
  }.max-lg\:-bottom-\[76px\]{
    bottom: -76px;
  }.max-lg\:-right-12{
    right: -3rem;
  }.max-lg\:-top-20{
    top: -5rem;
  }.max-lg\:bottom-2{
    bottom: 0.5rem;
  }.max-lg\:bottom-2\.5{
    bottom: 0.625rem;
  }.max-lg\:top-5{
    top: 1.25rem;
  }.max-lg\:top-\[220px\]{
    top: 220px;
  }.max-lg\:top-\[80px\]{
    top: 80px;
  }.max-lg\:top-auto{
    top: auto;
  }.max-lg\:order-1{
    order: 1;
  }.max-lg\:order-2{
    order: 2;
  }.max-lg\:col-span-5{
    grid-column: span 5 / span 5;
  }.max-lg\:col-span-7{
    grid-column: 1 / -1;
  }.max-lg\:col-span-full{
    grid-column: 1 / -1;
  }.max-lg\:mx-auto{
    margin-left: auto;
    margin-right: auto;
  }.max-lg\:mb-10{
    margin-bottom: 2.5rem;
  }.max-lg\:mb-4{
    margin-bottom: 1rem;
  }.max-lg\:mb-5{
    margin-bottom: 1.25rem;
  }.max-lg\:mb-6{
    margin-bottom: 1.5rem;
  }.max-lg\:ml-auto{
    margin-left: auto;
  }.max-lg\:mt-5{
    margin-top: 1.25rem;
  }.max-lg\:mt-6{
    margin-top: 1.5rem;
  }.max-lg\:inline-block{
    display: inline-block;
  }.max-lg\:hidden{
    display: none;
  }.max-lg\:aspect-square{
    aspect-ratio: 1 / 1;
  }.max-lg\:aspect-video{
    aspect-ratio: 16 / 9;
  }.max-lg\:w-28{
    width: 7rem;
  }.max-lg\:w-4\/5{
    width: 80%;
  }.max-lg\:w-8\/12{
    width: 66.666667%;
  }.max-lg\:w-\[196px\]{
    width: 196px;
  }.max-lg\:w-\[220px\]{
    width: 220px;
  }.max-lg\:w-\[335px\]{
    width: 335px;
  }.max-lg\:w-full{
    width: 100%;
  }.max-lg\:max-w-\[275px\]{
    max-width: 275px;
  }.max-lg\:max-w-full{
    max-width: 100%;
  }.max-lg\:grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }.max-lg\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }.max-lg\:flex-col{
    flex-direction: column;
  }.max-lg\:justify-center{
    justify-content: center;
  }.max-lg\:justify-between{
    justify-content: space-between;
  }.max-lg\:gap-5{
    gap: 1.25rem;
  }.max-lg\:gap-y-10{
    row-gap: 2.5rem;
  }.max-lg\:gap-y-2{
    row-gap: 0.5rem;
  }.max-lg\:gap-y-2\.5{
    row-gap: 0.625rem;
  }.max-lg\:gap-y-25{
    row-gap: 100px;
  }.max-lg\:gap-y-5{
    row-gap: 1.25rem;
  }.max-lg\:overflow-y-auto{
    overflow-y: auto;
  }.max-lg\:p-5{
    padding: 1.25rem;
  }.max-lg\:\!px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }.max-lg\:\!px-3{
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }.max-lg\:px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }.max-lg\:px-2\.5{
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }.max-lg\:py-12{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }.max-lg\:py-20{
    padding-top: 5rem;
    padding-bottom: 5rem;
  }.max-lg\:py-25{
    padding-top: 100px;
    padding-bottom: 100px;
  }.max-lg\:py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }.max-lg\:py-3\.5{
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }.max-lg\:pb-150{
    padding-bottom: 150px;
  }.max-lg\:pb-25{
    padding-bottom: 100px;
  }.max-lg\:pl-0{
    padding-left: 0px;
  }.max-lg\:pt-150{
    padding-top: 150px;
  }.max-lg\:pt-20{
    padding-top: 5rem;
  }.max-lg\:pt-\[160px\]{
    padding-top: 160px;
  }.max-lg\:text-center{
    text-align: center;
  }.max-lg\:\!text-sm{
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }.max-lg\:text-\[32px\]{
    font-size: 32px;
  }.max-lg\:text-sm{
    font-size: 0.875rem;
    line-height: 1.25rem;
  }.max-lg\:after\:bottom-0::after{
    content: var(--tw-content);
    bottom: 0px;
  }.max-lg\:after\:h-\[1px\]::after{
    content: var(--tw-content);
    height: 1px;
  }.max-lg\:after\:w-full::after{
    content: var(--tw-content);
    width: 100%;
  }
  }
  @media not all and (min-width: 768px){.max-md\:static{
    position: static;
  }.max-md\:\!-bottom-5{
    bottom: -1.25rem !important;
  }.max-md\:\!-right-5{
    right: -1.25rem !important;
  }.max-md\:\!bottom-\[70px\]{
    bottom: 70px !important;
  }.max-md\:\!left-\[50px\]{
    left: 50px !important;
  }.max-md\:\!top-5{
    top: 1.25rem !important;
  }.max-md\:top-0{
    top: 0px;
  }.max-md\:z-10{
    z-index: 10;
  }.max-md\:order-1{
    order: 1;
  }.max-md\:order-2{
    order: 2;
  }.max-md\:col-span-full{
    grid-column: 1 / -1;
  }.max-md\:-mt-60{
    margin-top: -15rem;
  }.max-md\:mb-0{
    margin-bottom: 0px;
  }.max-md\:mb-20{
    margin-bottom: 5rem;
  }.max-md\:mb-25{
    margin-bottom: 100px;
  }.max-md\:mb-4{
    margin-bottom: 1rem;
  }.max-md\:mb-6{
    margin-bottom: 1.5rem;
  }.max-md\:mb-8{
    margin-bottom: 2rem;
  }.max-md\:mb-\[300px\]{
    margin-bottom: 300px;
  }.max-md\:ml-0{
    margin-left: 0px;
  }.max-md\:mt-15{
    margin-top: 60px;
  }.max-md\:mt-150{
    margin-top: 150px;
  }.max-md\:mt-20{
    margin-top: 5rem;
  }.max-md\:mt-4{
    margin-top: 1rem;
  }.max-md\:mt-5{
    margin-top: 1.25rem;
  }.max-md\:hidden{
    display: none;
  }.max-md\:h-\[220px\]{
    height: 220px;
  }.max-md\:h-\[350px\]{
    height: 350px;
  }.max-md\:h-\[400px\]{
    height: 400px;
  }.max-md\:h-\[750px\]{
    height: 750px;
  }.max-md\:h-full{
    height: 100%;
  }.max-md\:min-h-\[400px\]{
    min-height: 400px;
  }.max-md\:w-15{
    width: 60px;
  }.max-md\:w-4\/6{
    width: 66.666667%;
  }.max-md\:w-\[285px\]{
    width: 285px;
  }.max-md\:w-auto{
    width: auto;
  }.max-md\:w-full{
    width: 100%;
  }.max-md\:-translate-y-0{
    --tw-translate-y: -0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }.max-md\:grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }.max-md\:flex-col{
    flex-direction: column;
  }.max-md\:justify-center{
    justify-content: center;
  }.max-md\:gap-10{
    gap: 2.5rem;
  }.max-md\:gap-12{
    gap: 3rem;
  }.max-md\:gap-5{
    gap: 1.25rem;
  }.max-md\:gap-x-2{
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
  }.max-md\:gap-x-2\.5{
    -moz-column-gap: 0.625rem;
         column-gap: 0.625rem;
  }.max-md\:gap-y-10{
    row-gap: 2.5rem;
  }.max-md\:gap-y-15{
    row-gap: 60px;
  }.max-md\:gap-y-25{
    row-gap: 100px;
  }.max-md\:gap-y-5{
    row-gap: 1.25rem;
  }.max-md\:overflow-hidden{
    overflow: hidden;
  }.max-md\:border-none{
    border-style: none;
  }.max-md\:object-cover{
    -o-object-fit: cover;
       object-fit: cover;
  }.max-md\:object-center{
    -o-object-position: center;
       object-position: center;
  }.max-md\:p-4{
    padding: 1rem;
  }.max-md\:p-5{
    padding: 1.25rem;
  }.max-md\:px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }.max-md\:py-20{
    padding-top: 5rem;
    padding-bottom: 5rem;
  }.max-md\:py-25{
    padding-top: 100px;
    padding-bottom: 100px;
  }.max-md\:py-7{
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }.max-md\:pb-0{
    padding-bottom: 0px;
  }.max-md\:pb-10{
    padding-bottom: 2.5rem;
  }.max-md\:pb-20{
    padding-bottom: 5rem;
  }.max-md\:pb-25{
    padding-bottom: 100px;
  }.max-md\:pt-150{
    padding-top: 150px;
  }.max-md\:pt-20{
    padding-top: 5rem;
  }.max-md\:pt-25{
    padding-top: 100px;
  }.max-md\:pt-\[320px\]{
    padding-top: 320px;
  }.max-md\:text-center{
    text-align: center;
  }.max-md\:before\:hidden::before{
    content: var(--tw-content);
    display: none;
  }.max-md\:after\:hidden::after{
    content: var(--tw-content);
    display: none;
  }
  }
  @media not all and (min-width: 640px){.max-sm\:hidden{
    display: none;
  }.max-sm\:grid-cols-1{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }.max-sm\:overflow-hidden{
    overflow: hidden;
  }
  }
  @media not all and (min-width: 475px){.max-xs\:top-\[170px\]{
    top: 170px;
  }.max-xs\:top-\[75px\]{
    top: 75px;
  }
  }
  @media (min-width: 475px){.xs\:col-span-4{
    grid-column: span 4 / span 4;
  }.xs\:col-span-8{
    grid-column: span 8 / span 8;
  }
  }
  @media (min-width: 640px){.sm\:-left-15{
    left: -60px;
  }.sm\:-right-15{
    right: -60px;
  }.sm\:bottom-6{
    bottom: 1.5rem;
  }.sm\:col-span-3{
    grid-column: span 3 / span 3;
  }.sm\:col-span-9{
    grid-column: span 9 / span 9;
  }.sm\:col-span-full{
    grid-column: 1 / -1;
  }.sm\:hidden{
    display: none;
  }.sm\:w-\[250px\]{
    width: 250px;
  }.sm\:overflow-hidden{
    overflow: hidden;
  }.sm\:pl-5{
    padding-left: 1.25rem;
  }
  }
  @media (min-width: 768px){.md\:sticky{
    position: sticky;
  }.md\:-top-12{
    top: -3rem;
  }.md\:bottom-0{
    bottom: 0px;
  }.md\:left-\[100px\]{
    left: 100px;
  }.md\:top-1\/2{
    top: 50%;
  }.md\:top-20{
    top: 5rem;
  }.md\:z-10{
    z-index: 10;
  }.md\:order-1{
    order: 1;
  }.md\:order-2{
    order: 2;
  }.md\:col-span-4{
    grid-column: span 4 / span 4;
  }.md\:col-span-5{
    grid-column: span 5 / span 5;
  }.md\:col-span-6{
    grid-column: span 6 / span 6;
  }.md\:col-span-7{
    grid-column: span 7 / span 7;
  }.md\:mb-32{
    margin-bottom: 8rem;
  }.md\:mt-25{
    margin-top: 100px;
  }.md\:flex{
    display: flex;
  }.md\:hidden{
    display: none;
  }.md\:w-\[200px\]{
    width: 200px;
  }.md\:w-\[250px\]{
    width: 250px;
  }.md\:w-\[calc\(50\%_-_20px\)\]{
    width: calc(50% - 20px);
  }.md\:max-w-\[250px\]{
    max-width: 250px;
  }.md\:max-w-\[650px\]{
    max-width: 650px;
  }.md\:shrink-0{
    flex-shrink: 0;
  }.md\:-translate-y-1\/2{
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }.md\:gap-10{
    gap: 2.5rem;
  }.md\:gap-5{
    gap: 1.25rem;
  }.md\:gap-8{
    gap: 2rem;
  }.md\:gap-x-12{
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }.md\:\!px-6{
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  }
  @media (min-width: 1024px){.lg\:\!-top-\[20px\]{
    top: -20px !important;
  }.lg\:\!bottom-0{
    bottom: 0px !important;
  }.lg\:\!left-\[45px\]{
    left: 45px !important;
  }.lg\:-bottom-\[86px\]{
    bottom: -86px;
  }.lg\:-left-150{
    left: -150px;
  }.lg\:-right-150{
    right: -150px;
  }.lg\:-top-\[170px\]{
    top: -170px;
  }.lg\:-top-\[185px\]{
    top: -185px;
  }.lg\:bottom-7{
    bottom: 1.75rem;
  }.lg\:left-15{
    left: 60px;
  }.lg\:left-\[200px\]{
    left: 200px;
  }.lg\:right-0{
    right: 0px;
  }.lg\:right-20{
    right: 5rem;
  }.lg\:top-1\/3{
    top: 33.333333%;
  }.lg\:top-\[280px\]{
    top: 280px;
  }.lg\:top-\[75px\]{
    top: 75px;
  }.lg\:col-span-2{
    grid-column: span 2 / span 2;
  }.lg\:col-span-3{
    grid-column: span 3 / span 3;
  }.lg\:col-span-4{
    grid-column: span 4 / span 4;
  }.lg\:col-span-5{
    grid-column: span 5 / span 5;
  }.lg\:col-span-6{
    grid-column: span 6 / span 6;
  }.lg\:col-span-7{
    grid-column: span 7 / span 7;
  }.lg\:col-span-8{
    grid-column: span 12 / span 8;
  }.lg\:-ml-\[170px\]{
    margin-left: -170px;
  }.lg\:-mt-15{
    margin-top: -60px;
  }.lg\:ml-15{
    margin-left: 60px;
  }.lg\:ml-7{
    margin-left: 1.75rem;
  }.lg\:ml-auto{
    margin-left: auto;
  }.lg\:flex{
    display: flex;
  }.lg\:hidden{
    display: none;
  }.lg\:aspect-video{
    aspect-ratio: 16 / 9;
  }.lg\:h-\[180px\]{
    height: 180px;
  }.lg\:h-\[230px\]{
    height: 230px;
  }.lg\:h-\[330px\]{
    height: 330px;
  }.lg\:h-\[442px\]{
    height: 442px;
  }.lg\:h-\[500px\]{
    height: 500px;
  }.lg\:w-\[170px\]{
    width: 170px;
  }.lg\:w-\[250px\]{
    width: 250px;
  }.lg\:w-\[280px\]{
    width: 280px;
  }.lg\:w-\[285px\]{
    width: 285px;
  }.lg\:w-\[300px\]{
    width: 300px;
  }.lg\:w-\[320px\]{
    width: 320px;
  }.lg\:w-\[330px\]{
    width: 330px;
  }.lg\:w-\[420px\]{
    width: 420px;
  }.lg\:w-\[442px\]{
    width: 442px;
  }.lg\:w-\[calc\(33\.33\%_-_20px\)\]{
    width: calc(33.33% - 20px);
  }.lg\:max-w-\[320px\]{
    max-width: 320px;
  }.lg\:max-w-\[425px\]{
    max-width: 425px;
  }.lg\:max-w-\[750px\]{
    max-width: 750px;
  }.lg\:items-center{
    align-items: center;
  }.lg\:gap-16{
    gap: 4rem;
  }.lg\:gap-5{
    gap: 1.25rem;
  }.lg\:gap-8{
    gap: 2rem;
  }.lg\:gap-x-6{
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }.lg\:px-12{
    padding-left: 3rem;
    padding-right: 3rem;
  }.lg\:px-150{
    padding-left: 150px;
    padding-right: 150px;
  }.lg\:px-4{
    padding-left: 1rem;
    padding-right: 1rem;
  }.lg\:px-8{
    padding-left: 2rem;
    padding-right: 2rem;
  }.lg\:py-150{
    padding-top: 150px;
    padding-bottom: 150px;
  }.lg\:pb-15{
    padding-bottom: 60px;
  }.lg\:pb-\[140px\]{
    padding-bottom: 140px;
  }.lg\:pt-3{
    padding-top: 0.75rem;
  }.lg\:pt-\[260px\]{
    padding-top: 260px;
  }.lg\:blur-\[145px\]{
    --tw-blur: blur(145px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }.lg\:after\:right-0::after{
    content: var(--tw-content);
    right: 0px;
  }.lg\:after\:top-1\/2::after{
    content: var(--tw-content);
    top: 50%;
  }.lg\:after\:h-full::after{
    content: var(--tw-content);
    height: 100%;
  }.lg\:after\:w-\[1px\]::after{
    content: var(--tw-content);
    width: 1px;
  }.lg\:after\:-translate-y-1\/2::after{
    content: var(--tw-content);
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  }
  @media (min-width: 1280px){.xl\:\!left-\[85px\]{
    left: 85px !important;
  }.xl\:-top-\[150px\]{
    top: -150px;
  }.xl\:bottom-8{
    bottom: 2rem;
  }.xl\:bottom-\[38px\]{
    bottom: 38px;
  }.xl\:left-150{
    left: 150px;
  }.xl\:left-\[290px\]{
    left: 290px;
  }.xl\:right-\[30px\]{
    right: 30px;
  }.xl\:ml-15{
    margin-left: 60px;
  }.xl\:h-\[230px\]{
    height: 230px;
  }.xl\:h-\[280px\]{
    height: 280px;
  }.xl\:h-\[442px\]{
    height: 442px;
  }.xl\:w-\[120px\]{
    width: 120px;
  }.xl\:w-\[308px\]{
    width: 308px;
  }.xl\:w-\[310px\]{
    width: 310px;
  }.xl\:w-\[320px\]{
    width: 320px;
  }.xl\:w-\[350px\]{
    width: 350px;
  }.xl\:w-\[368px\]{
    width: 368px;
  }.xl\:w-\[380px\]{
    width: 380px;
  }.xl\:w-\[442px\]{
    width: 442px;
  }.xl\:min-w-\[266px\]{
    min-width: 266px;
  }.xl\:max-w-\[1020px\]{
    max-width: 1020px;
  }.xl\:max-w-\[344px\]{
    max-width: 344px;
  }.xl\:max-w-\[420px\]{
    max-width: 420px;
  }.xl\:px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }.xl\:px-8{
    padding-left: 2rem;
    padding-right: 2rem;
  }.xl\:text-\[24px\]{
    font-size: 24px;
  }.xl\:text-\[36px\]{
    font-size: 36px;
  }.xl\:text-\[64px\]{
    font-size: 64px;
  }
  }
  @media (min-width: 1400px){.\31xl\:h-\[442px\]{
    height: 442px;
  }.\31xl\:w-\[442px\]{
    width: 442px;
  }.\31xl\:max-w-\[1290px\]{
    max-width: 1290px;
  }.\31xl\:gap-x-24{
    -moz-column-gap: 6rem;
         column-gap: 6rem;
  }.\31xl\:p-2{
    padding: 0.5rem;
  }.\31xl\:p-2\.5{
    padding: 0.625rem;
  }
  }