/* .footer-style-2  {
    text-align: center;
} */
.our-team-social-area {
    text-align: center;
    display: flex;
    flex-direction: column;
}

/* .footer-style-2 .pbmit-social-links li {
    margin-right: 8px;
    display: inline-block;
} */
.our-team-social-links {
    display: flex;
}

.member-tag {
    font-size: clamp(14px, 2.5vw, 18px);
    color: #18181B;
}

.member-tag-title {
    /* font-size: clamp(16px, 2.5vw, 22px); */
    color: #18181B;
    text-align: center;
    padding-bottom: 5px;
}

.sectionbg {
    padding: 0px;
    padding: 30px 0px;
    background-image: linear-gradient(339deg, rgba(47, 47, 47, 0.02) 0%, rgba(47, 47, 47, 0.02) 42%, transparent 42%, transparent 99%, rgba(17, 17, 17, 0.02) 99%, rgba(17, 17, 17, 0.02) 100%), linear-gradient(257deg, rgba(65, 65, 65, 0.02) 0%, rgba(65, 65, 65, 0.02) 11%, transparent 11%, transparent 92%, rgba(53, 53, 53, 0.02) 92%, rgba(53, 53, 53, 0.02) 100%), linear-gradient(191deg, rgba(5, 5, 5, 0.02) 0%, rgba(5, 5, 5, 0.02) 1%, transparent 1%, transparent 45%, rgba(19, 19, 19, 0.02) 45%, rgba(19, 19, 19, 0.02) 100%), linear-gradient(29deg, rgba(28, 28, 28, 0.02) 0%, rgba(28, 28, 28, 0.02) 33%, transparent 33%, transparent 40%, rgba(220, 220, 220, 0.02) 40%, rgba(220, 220, 220, 0.02) 100%), linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

.swiper-wrapper-Team {
    padding: 30px 0px;
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
}

.pbmit-social-link {
    display: flex;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #D9E0C5;


}

.pbmit-social-facebook {
    display: flex;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #D9E0C5;
}

/* 
.img-team-dept-style {
    filter: grayscale(1);
    transition: all 0.3s ease;
} */
.img-team-dept-style {
    /* filter: grayscale(1); */
    transition: all 0.3s ease;
    width: 200px;
    object-fit: cover;

}

.img-team-dept-style:hover {
    filter: grayscale(0);
    transition: all 0.3s ease;
}

.team-header-spacing {
    margin-bottom: 100px;
}

.team-member-box {
    width: 30%;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
    /* padding-bottom: 30px; */
}

.img-alignment {
    display: flex;
    justify-content: center;
    height: 250px;
    width: max-content;
}

.team-block-alignment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:1024px) {
    .team-member-box {
        width: 48%;
    }
}

@media screen and (max-width:560px) {
    .team-member-box {
        width: 95%;
    }
}

.dep-team {
    /* font-size: clamp(14px, 2.5vw, 16px); */
    color: #18181B;
    width: 100%;
    line-height: 26px;
    font-weight: 500;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    text-align: center;

}