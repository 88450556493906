.saf-main-div {
  height: max-content;
  width: 80%;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 20px;
  display: flex;
  justify-content: center;
  margin-left: 50px;
  padding-bottom: 30px;
}



.saf-row-div {
  display: flex;
  gap: 20px;
  background-color: white;
  border-radius: 2%;
}

.form-inner {
  margin: 20;
}

.saf-address textarea {
  border-radius: 0;
}

.text-salary {
  margin-left: 10px;
  margin-bottom: 10px;
  color: currentColor;
}

.saf-pt-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.saf-pr {
  padding-right: 30px;
  /* text-align: center; */
  padding-top: 10px;
  color: #18181B;
}

.saf-pl {
  padding-left: 10px;
}

.saf-check-l {
  padding-left: 10px;
}

.saf-declare-styling {
  font-weight: 600;
  text-align: left;
}

.saf-mandatory {
  font-weight: 600;
  color: red;
}

.form-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}

.saf-pr {
  padding-right: 20px;
  padding-left: 16px;
}

.saf-date-width {
  width: 30%;
}

.saf-heading {
  padding-top: 40px;
  text-align: center;
}

.saf-heading h4 {
  color: #0e2f44;

}

.saf-heading h5 {
  padding-top: 10px;
}

.saf-heading p {
  text-align: center;
  font-size: 16px;
}