.card-body {
    background-color: rgba(250, 251, 251, 255);
    height: max-content;
    width: auto;
    padding: 22px 48px 48px 48px;
    border-radius: 20px;
}

.responsibilty ul {
    list-style: disc;
}

.responsibilty ul li {
    margin-bottom: 15px;
}

.btnapp {
    display: flex;
    justify-content: center;

}

.requirement ul {
    list-style: disc;
    margin-bottom: 15px;
}

.requirement ul li {
    margin-bottom: 15px;
}

.skills ul {
    list-style: disc;
    margin-bottom: 15px;
}

.skill ul li {
    margin-bottom: 15px;
}

.jd-main-div {
    /* gap: 20px; */
    max-width: 1320px;
    margin: auto;
    align-items: flex-start;
}

.jd-left-div {
    width: 66.67%;
    padding-right: 3rem;
}

.jd-right-div {
    width: 33.3%;
    height: 1000px
}

.job-main-section {
    /* background: aliceblue; */
    /* margin-left: 20px; */
    padding: 60px 0;
}

.jd-sticky-sidebar {
    position: sticky;
    top: 40px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.jb-card-title {
    font-weight: 600;
    /* font-size: 20px; */
}
.normal-text-red {
    color: red;
    /* font-size: 20px; */
}

.jb-card-title-h6 {
    font-weight: 600;
    /* font-size: 17px; */
    display: block;
}

.jb-card-title-p {
    font-weight: 400;
    /* font-size: 14px; */
}

.jb-overview-detail {
    display: flex;
    margin-bottom: 20px;
    gap: 15px;
    align-items: baseline;
}

@media screen and (min-width: 951px) and (max-width: 1320px) {
    .jd-main-section {
        max-width: 95%;
    }
}

@media screen and (max-width: 950px) {
    .jd-main-div {
        flex-direction: column;
        max-width: 720px;
        gap: 40px;
    }

    .jd-left-div {
        width: 100%;
        margin: auto;
    }

    .jd-right-div {
        width: 100%;
        margin: auto;
        height: max-content;
    }
}

@media screen and (max-width: 750px) {
    .jd-main-div {
        max-width: 95%;
    }
}