.home-counterNew {
    position: relative;
    height: auto;
}

.home-counterNew-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 50px 0px 100px;
}

.counter-div-containerNew {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: auto;
}

.home-counterNew-headingNew {
    /* color: #18181B; */
    color: #ffffff;
}

.counter-divNew {
    width: 250px;
    text-align: center;
}

.counter-numbersNew {
    color: #030303;
    /* font-size: 36px; */
    /* font-weight: 800; */
    font-family: kumbhfonts;
    /* text-shadow: 2px 2px #000000; */
}

.counter-symbolNew {
    margin-left: -10px;
}

/* .counter-textNew {
    color: #ffffff;
    font-size: 18px;
} */
.counter-textNew {
    color: #000000;
    /* font-size: 18px; */
    font-family: kumbhfonts;
    font-weight: 200;
    display: block;
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    .counter-div-containerNew {
        width: 100%;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .counter-div-containerNew {
        width: 95%;
    }
}

@media only screen and (min-width: 531px) and (max-width: 767px) {
    .counter-div-containerNew {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .home-counterNew-container {
        padding: 50px 0px;
    }

    .home-counterNew-container>div {
        gap: 30px;
    }
}

@media only screen and (max-width: 530px) {
    .counter-div-containerNew {
        flex-direction: column;
    }

    .home-counterNew-container {
        padding: 50px 0px;
    }

    .home-counterNew-container>div {
        gap: 30px;
    }
}

.top-padding-zero {
    padding-top: 0px;
}