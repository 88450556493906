/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

::selection {
    color: #f1f1f1;
    background: #d2611c;
}

.loader-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.headericonflex {
    display: flex;
  
}