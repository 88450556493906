


.contact-us-text-dept {
  /* background-color: aqua; */
  padding: 0px;
  margin: 0px;
  height: 40px;
  display: flex;
  justify-content: start;
  margin-bottom: 30px;
}

/* /the button/ */
.contact-us-button {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10rem;
  margin-left: 55px;
  color: #000000;
  justify-content: center;
  /* text-transform: uppercase; */
  font-size: 1rem;
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  border: none;
  z-index: 1;
  border: 1px solid rgb(175, 172, 172);
  margin-top: 20px;
}

.contact-us-text-dept {
  display: flex;
  justify-content: center;
  margin-right: 40px;
}

.contact-us-button:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10rem;
  z-index: -2;

}

.contact-us-button:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: black;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;

}

.contact-us-button:hover {
  color: white;

}

.contact-us-button:hover:before {
  width: 100%;
}


/* /text inaside the button/ */
.text-contact-us-dept {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  /* font-size: 15px; */
  align-content: start;
  font-weight: 500;
  display: flex;
  width: 100%;
  letter-spacing: 0.2px;
  justify-content: start;
  padding-top: 5px;
  margin-left: 45px;

}

.contact-us-button:hover .text-contact-us-dept {
  color: white;


}




.justify-both-parts {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  margin-left: 3px;
  /* background-color: aqua; */
}

.contact-row {
  gap: 97px;
}

.contact-spacing{
  padding: 0px !important;
}

.contact-us-section{
  padding-top: 100px;
}