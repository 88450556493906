visitor-counter {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .counter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .count {
    font-size: 48px;
    font-weight: bold;
    color: #b3f45e; /* Adjust color as needed */
    animation: countUp 1s ease-in-out;
  }
  
  .label {
    font-size: 24px;
    color: #fcf6f6;
  }
  
  @keyframes countUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }