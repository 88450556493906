.clients-bg-div {
	background-image: radial-gradient(circle at 69% 86%, rgba(165, 165, 165, 0.07) 0%, rgba(165, 165, 165, 0.07) 25%, rgba(193, 193, 193, 0.07) 25%, rgba(193, 193, 193, 0.07) 50%, rgba(221, 221, 221, 0.07) 50%, rgba(221, 221, 221, 0.07) 75%, rgba(249, 249, 249, 0.07) 75%, rgba(249, 249, 249, 0.07) 100%), radial-gradient(circle at 49% 76%, rgba(129, 129, 129, 0.07) 0%, rgba(129, 129, 129, 0.07) 25%, rgba(164, 164, 164, 0.07) 25%, rgba(164, 164, 164, 0.07) 50%, rgba(200, 200, 200, 0.07) 50%, rgba(200, 200, 200, 0.07) 75%, rgba(235, 235, 235, 0.07) 75%, rgba(235, 235, 235, 0.07) 100%), radial-gradient(circle at 22% 64%, rgba(173, 173, 173, 0.07) 0%, rgba(173, 173, 173, 0.07) 25%, rgba(119, 119, 119, 0.07) 25%, rgba(119, 119, 119, 0.07) 50%, rgba(64, 64, 64, 0.07) 50%, rgba(64, 64, 64, 0.07) 75%, rgba(10, 10, 10, 0.07) 75%, rgba(10, 10, 10, 0.07) 100%), linear-gradient(307deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

.logos__marquee {
	display: flex;
	overflow-x: hidden;
	user-select: none;
	mask-image: linear-gradient(to right,
			hsl(0 0% 0% / 0),
			hsl(0 0% 0% / 1) 20%,
			hsl(0 0% 0% / 1) 80%,
			hsl(0 0% 0% / 0));
	margin-bottom: 10px;
	padding-top: 20px;
}

.clients-header-spacing {
	padding-top: 100px;
}

.marquee__logos {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 1rem;
	min-width: 100%;
	animation: loop 30s linear infinite;
}

.marquee__logos img {
	display: block;
	margin-inline: 2rem;
}

@keyframes loop {
	from {
		transform: translateX(-100%);
	}

	to {
		transform: translateX(0%);
	}
}

.logos__marquees {
	display: flex;
	overflow-x: hidden;
	user-select: none;
	mask-image: linear-gradient(to right,
			hsl(0 0% 0% / 0),
			hsl(0 0% 0% / 1) 20%,
			hsl(0 0% 0% / 1) 80%,
			hsl(0 0% 0% / 0));
}

.marquee__logoss {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 1rem;
	min-width: 100%;
	animation: loops 40s linear infinite;
}

.marquee__logoss img {
	display: block;
	margin-inline: 2rem;
}

@keyframes loops {
	from {
		transform: translateX(0%);
	}

	to {
		transform: translateX(-100%);
	}
}

.aLogo {
	width: 150px;
	aspect-ratio: 3/2;
	object-fit: contain;
	mix-blend-mode: color-burn;
}

.logotitle {
	text-align: center;
	padding-top: 25px;
	padding-bottom: 25px;
}

.bottom-spacing {
	padding-bottom: 100px;
}

.clients-subtitle {
	/* font-size: clamp(16px, 2.5vw, 25px); */
	/* font-weight: 600; */
	font-family: "Plus Jakarta Sans", sans-serif;

}

@media screen and (max-width: 500px) {
	/* .clients-subtitle {
		font-size: 18px;
	} */
	.aLogo {
		width: 100px;
		aspect-ratio: 3/2;
		object-fit: contain;
		mix-blend-mode: color-burn;
	}
	.bottom-spacing {
		padding-bottom: 0px;
	}
}

@media only screen and (max-width:1200px) {
	.clients-header-spacing {
		padding-top: 40px;
	}
}

/* @media only screen and (max-width:720px) {
	.clients-header-spacing {
		padding-top: 470px;
	}
}

@media only screen and (min-width:350px) and (max-width:500px) {
	.clients-header-spacing {
		padding-top: 500px;
	}
}

@media only screen and (max-width: 350px) {
	.clients-header-spacing {
		padding-top: 510px;
	}
}

@media only screen and (max-width: 460px) {
	.clients-header-spacing {
		padding-top: 520px;
	}
} */