.buttons-container {
    width: 100%;
    display: contents;
    justify-content: center;
    padding: 10px 0;
    gap: 10;
}
.top-button-active1 {
    padding: 10px 20px;
    background-color:rgb(115 188 80);
    color: white;
    border: none;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
}
.top-button {
    padding: 10px 20px;
    background-color: rgb(176, 176, 176);
    color: white;
    border: none;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.top-button:hover {
    background-color: #0056b3;
}



.success-stories-detail-section {
    padding: 100px 0px;
}

.div-2 {
    display: flex;
    flex-direction: row-reverse;
}

.success-stories-detail-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 1200px;
    max-width: 100%;
}

.success-stories-detail-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}

.success-stories-detail-text-div {
    margin-top: 72px;
    margin-left: -74px;
    margin-right: -32px;
    padding-top: 53px;
    padding-left: 80px;
    background-color: #ffffff;
    border-radius: 10px;
    z-index: 5;
}

.ss-detail-heading {
    color: #18181B;
    font-size: 36px;
    font-weight: 700;
    line-height: 58px;
    margin-top: 9px;
}

.ss-detail-desc {
    color: #18181B;
    font-size: 18px;
    line-height: 200%;
}

.success-stories-detail-image-div {
    position: relative;
}

.ss-image-1 {
    width: 500px;
    height: 500px;
    border-radius: 10px;
    overflow: hidden;
}

.ss-image-2 {
    width: 300px;
    height: 360px;
    border-radius: 10px;
    position: absolute;
    bottom: -50px;
    left: -150px;
    overflow: hidden;
}

.ss-image-2-2 {
    width: 300px;
    height: 360px;
    border-radius: 10px;
    position: absolute;
    top: 30px;
    right: -150px;
    overflow: hidden;
}

.success-stories-detail-text-div {
    padding: 50px 20px 0px 80px;
    --tw-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 0px 30px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media screen and (max-width: 1000px) {
    .flex-center-div {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 1300px;
    }

    .dep-div-2 {
        flex-wrap: wrap;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        position: relative;
        width: auto;
    }

    .dep-div-2-bg::after {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        background-image: repeating-linear-gradient(0deg, rgba(25, 208, 33, 0.1) 0px, rgba(25, 208, 33, 0.1) 1px, transparent 1px, transparent 13px), repeating-linear-gradient(90deg, rgba(25, 208, 33, 0.1) 0px, rgba(25, 208, 33, 0.1) 1px, transparent 1px, transparent 13px), linear-gradient(90deg, rgb(80, 177, 80), rgb(141, 197, 80));
    }

    .department-details-div {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 60px 20px;
    }
}