/* RESET STYLES & HELPER CLASSES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
:root {
  --level-1: #8dccad;
  --level-2: #f5cc7f;
  --level-3: #7b9fe0;
  --level-4: #f27c8d;
  --black: black;
}


ol {
  list-style: none;
}

acolorw {
  color: white;
}

.oc-container {
  margin: 50px 0 100px;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.container {
  max-width: 1000px;
  padding: 0 10px;
  margin: 0 auto;
}

.rectangle {
  position: relative;
  padding: 16px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.org-heading-fonts {
  color: #ffffff;
  font-weight: 700;
}


/* LEVEL-1 STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-1 {
  width: 35%;
  margin: 0 auto 40px;
  /* background: linear-gradient(to right, #7cca38 0%, #7cca38 50%, #7cba27 50%, #7cba27 100%); */
  /* background: #0a3e76; */
  /* background-image: linear-gradient(175deg, rgb(12,70,137),rgb(45,123,181)); */
  /* background-image: linear-gradient(175deg, rgb(14 47 68), rgb(37 90 123)); */
  background-color: #0e2f44;
  /* background-color: #0FA9A9; */
  border-radius: 12px;
}

.level-1::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 20px;
  background: #b6b6b6
}


/* LEVEL-2 STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-2-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.level-21-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}


/* .level-2-wrapper::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 27%;
  width: 48.75%;
  height: 2px;
  background: #b6b6b6;
} */

.level-2-wrapper::before {
  content: "";
  position: absolute;
  top: -20px;

  height: 2px;
  background: #b6b6b6;

    left: 33.7%;
    width: 49.2%;
}

.level-21-wrapper::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 28.5%;
  width: 49%;
  height: 2px;
  background: #b6b6b6
}

/* .level-2-wrapper::after {
  display: none;
  content: "";
  position: absolute;
  left: -20px;
  bottom: -20px;
  width: calc(100% + 20px);
  height: 2px;
  background: #b6b6b6;
} */
.level-21-wrapper::after {
  display: none;
  content: "";
  position: absolute;
  left: -20px;
  bottom: -20px;
  width: calc(100% + 20px);
  height: 2px;
  background: #b6b6b6;
}

.level-2-wrapper li {
  position: relative;
}

.level-21-wrapper li {
  position: relative;
}

.level-2-wrapper>li::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 20px;
  background: #b6b6b6;
}

.level-21-wrapper>li::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 20px;
  background: #b6b6b6;
}

.level-2 {
  width: 70%;
  margin: 0 auto 40px;
  /* background-image: linear-gradient(175deg, rgb(14 47 68), rgb(94 150 194 / 95%)); */
  background-color: #7cba27;
  color: #ffffff;
  /* background: linear-gradient(to right, #ff6f00 0%, #ff6f00 50%, #db6a00 50%, #db6a00 100%); */
  /* background-image: linear-gradient(175deg, rgb(42 119 178), rgb(99 162 211 / 95%)); */
  /* 
  /* background: #7cba27db; */
  border-radius: 12px;
  font-weight: 700;
}

.level-21 {
  width: 70%;
  margin: 0 auto 40px;
  /* background-image: linear-gradient(175deg, rgb(14 47 68), rgb(94 150 194 / 95%)); */
  background-color: #7cba27;
  color: #ffffff;
  /* background: linear-gradient(to right, #ff6f00 0%, #ff6f00 50%, #db6a00 50%, #db6a00 100%); */
  /* background: #7cba27db; */
  /* background-image: linear-gradient(175deg, rgb(42 119 178), rgb(99 162 211 / 95%)); */
  /* 
  /* background-image: linear-gradient(175deg, rgb(45,123,181),rgba(99,162,211, 0.81)); */
  /* background-image: linear-gradient(173deg, rgb(97 153 159), rgb(85 193 217)); */
  border-radius: 12px;
  font-weight: 700;
}

.level-2::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 0px;
  background: #b6b6b6;
}

.level-21::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 25px;
  background: #b6b6b6;
}

.level-2::after {
  display: none;
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-100%, -50%);
  width: 20px;
  height: 2px;
  background: #b6b6b6;
}

.level-21::after {
  display: none;
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-100%, -50%);
  width: 20px;
  height: 2px;
  background: #b6b6b6;
}



/* LEVEL-3 STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-3-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  width: fit-content;
  margin: 0 auto;
  padding-left: 0px;
}

.level-3-wrapper1 {
  position: relative;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 20px;
  width: 90%;
  margin-top: 42px;
}

.level-3-wrapper::before {
  content: "";
  position: absolute;
  top: -20px;
  left: calc(25%);
  width: 54%;
  height: 2px;
  background: #b6b6b6;
}



.level-3-wrapper1::before {
  content: "";
  position: absolute;
  top: -20px;
  left: calc(25% + -131px);
  width: 96%;
  height: 2px;
  background: #b6b6b6;
}


.level-3-wrapper1>li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 2px;
  height: 20px;
  background: #b6b6b6;
}

.level-3-wrapper>li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 54%;
  transform: translate(-50%, -100%);
  width: 2px;
  height: 20px;
  background: #b6b6b6;
}

.level-3 {
  min-height: 160px;
  margin-bottom: 20px;
  background: var(--level-3);
  border-radius: 12px;
  /* background-image: linear-gradient(178deg, rgb(58 119 158), rgb(23 62 86)); */
  background-color: #ffffff;
  /* background: linear-gradient(to right, #00337C 0%, #00337C 50%, #13005A 50%, #13005A 100%); */
  /* background: #7b7b7c; */
  /* background-image: linear-gradient(178deg, rgba(99,162,211, 0.81),rgba(132,144,145, 0.81)); */
  /* background-image: linear-gradient(173deg, rgb(11, 69, 135), rgba(19, 116, 188, 0.59)); */
}

.level-312 {
  margin-bottom: 20px;
  background: var(--level-3);
  border-radius: 12px;
  /* background: linear-gradient(to right, #00337C 0%, #00337C 50%, #13005A 50%, #13005A 100%); */
  background: #7b7b7c;
}


/* LEVEL-4 STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-4-wrapper {
  position: relative;
  width: 80%;
  margin-left: auto;
}

.level-4-wrapper::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  width: 2px;
  height: calc(100% + 20px);
  background: #b6b6b6;
}

.level-4-wrapper li+li {
  margin-top: 20px;
}

.level-4 {
  font-weight: normal;
  background: var(--level-4);
}

.level-4::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-100%, -50%);
  width: 20px;
  height: 2px;
  background: #b6b6b6;
}


/* MQ STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

/* @media screen and (min-width: 1201px)and (max-width: 1400px) {
    .level-2-wrapper::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 38.5%;
      width: 48.75%;
      height: 2px;
      background: #b6b6b6;
  }
  } */




/* @media screen and (min-width: 1201px) and (max-width: 1540px) {
  .level-2-wrapper::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 28.60%;
    width: 48.90%;
    height: 2px;
    background: #b6b6b6;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1535px) {
  .level-2-wrapper::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 34.5%;
    width: 48.90%;
    height: 2px;
    background: #b6b6b6;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1534px) {
  .level-2-wrapper::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 31.45%;
    width: 48.90%;
    height: 2px;
    background: #b6b6b6;
  }
}


@media screen and (min-width: 1201px) and (max-width: 1279px) {
  .level-2-wrapper::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 43%;
    width: 51.75%;
    height: 2px;
    background: #b6b6b6;
  }

  /* .level-3-wrapper::before {
    content: "";
    position: absolute;
    top: -20px;
    left: calc(25% + 25px);
    width: 53%;
    height: 2px;
    background: #b6b6b6;
  } 

} */

@media screen and (max-width: 1200px) {

  .rectangle {
    padding: 20px 10px;
  }

  .level-21 {
    width: 100%;
  }

  .level-3 {
    min-height: 125px;
  }

  .level-1,
  .level-2 {
    width: 100%;
  }

  .level-1 {
    margin-bottom: 20px;
  }

  .level-1::before,
  .level-2-wrapper>li::before,
  .level-3-wrapper1::before,
  .level-3-wrapper1>li::before {
    display: none;
  }

  .level-2-wrapper,
  .level-2-wrapper::after,
  .level-2::after {
    display: block;
  }

  .level-3-wrapper1>li::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-100%, -50%);
    width: 20px;
    height: 2px;
    background: #b6b6b6;
  }


  .level-2-wrapper {
    width: 90%;
    margin-left: 10%;
  }

  .level-2-wrapper::before {
    left: -20px;
    width: 2px;
    /* height: calc(100% + 40px); */
    height: calc(100% - 21px);
  }

  .level-2-wrapper li {
    position: relative;
    margin-left: -30px;
  }


  .level-21-wrapper>li::before {
    display: none;
  }

  .level-21-wrapper,
  .level-21-wrapper::after,
  .level-21::after {
    display: block;
  }


  .level-21-wrapper {
    width: 90%;
    margin-left: 10%;
  }

  .level-21-wrapper::before {
    left: -20px;
    width: 2px;
    height: calc(100% + 40px);
  }

  .level-21-wrapper li {
    position: relative;
    margin-left: -30px;
  }


  .level-3-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
    width: 90%;
    margin: 0 50px;
  }

  .level-3-wrapper::before {
    content: "";
    position: absolute;
    top: -40px;
    left: calc(25% + 11px);
    width: 0%;
    height: 0px;
    background: #b6b6b6;
  }

  .level-3-wrapper>li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 2px;
    height: 0px;
    background: #b6b6b6;
  }

  .level-3-wrapper1 {
    position: relative;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
    width: 90%;
    margin: 0 40px;
  }

  /* .level-3-wrapper1::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-100%, -50%);
    width: 20px;
    height: 2px;
    background: #b6b6b6;
  } */



  .level-2::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 0px;
    background: orange;
  }

  .oc h3 {
    /* font-size: 17px; */
    /* font-weight: 700; */
    /* margin-left: 15px; */
    text-align: center;
  }

  .level-2-wrapper>li:not(:first-child) {
    margin-top: 50px;
  }

  .level-21::before {
    content: "";
    position: absolute;
    transform: translateX(-50%);
    background: #b6b6b6;
    left: 21px;
    width: 2px;
    top: 100%;
    height: calc(1140% + 4px);
  }

  .level-2::before {
    content: "";
    position: absolute;
    transform: translateX(-50%);
    background: #b6b6b6;
    left: 21px;
    width: 2px;
    top: 100%;
    height: calc(0%);
  }

  /* .level-21::before{
    display: none;
  } */
}

.level-3-wrapper1>li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 2px;
  height: 20px;
  background: #b6b6b6;
}

/* @media screen and (max-width: 800px) {
  .level-3-wrapper1>li::before {
    left: clamp(3.25%, 6.5vw, 3.5%);
  }
} */
/* FOOTER
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  position: fixed;
  right: 0;
  bottom: 20px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.page-footer a {
  margin-left: 4px;
}

.oc h3 {
  /* font-size: 17px; */
  /* font-weight: 700; */
  text-align: center;
}

.oc h4 {
  /* font-size: 1em; */
  font-weight: 400;
  text-align: center;
}

.oc h2 {
  /* font-size: 18px; */
  /* font-weight: 700; */
  text-align: center;
}

.oc h1 {
  /* font-size: 18px; */
  /* font-weight: 700; */
}


.div-prop {
  background: linear-gradient(to right, #9c9e9f 0%, #9c9e9f 50%, #f6f6f6 50%, #f6f6f6 100%);
}

.ogc-main-box {
  display: flex;
  height: fit-content;
  width: 95%;
  margin: 55px;
  /* justify-content: center; */
  align-items: center;
  gap: 100px;
}

.ogc-lvl-2 {
  display: flex;
  flex-direction: column;
}

.ogc-lvl-3 {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.lvl-3-row {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 1200px) {
  .oc-container {
    min-width: 1200px;
    max-width: 1600px;
    /* width : clamp(1200px, 2.5vw, 1600px); */
    margin: auto;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .oc-container {
    width: 1200px;
  }

  .level-2-wrapper::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 45%;
    width: 48.75%;
    height: 2px;
    background: #b6b6b6;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
  .oc-container {
    width: 1400px;
  }

  .level-2-wrapper::before {
    left: 38.4%;
  }

}