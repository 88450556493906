.timeline {
  position: relative;
  width: 50%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background: #ccc;
  top: 210px;
  bottom: 100px;
  left: 50%;
  margin-left: -1px;
}

.container_timeline {
  padding: 1px 30px;
  position: relative;
  background: inherit;
  width: 50%;
}

.container_timeline.left {
  left: 0;
}

.container_timeline.right {
  left: 50%;
}

.container_timeline::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  background: #ffffff;
  border: 2px solid #2B2A4C;
  border-radius: 16px;
  z-index: 1;
}

.container_timeline.right::after {
  left: -8px;
  z-index: 1;
  border: 2px solid #F31559;
}

.container_timeline::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 2px;
  top: calc(50% - 1px);
  right: 8px;
  background: #2B2A4C;
  /* background: #2B2A4C; */
  z-index: 1;
}

.container_timeline.right::before {
  left: 8px;
  background: #F31559;
}

.container_timeline .date {
  position: absolute;
  display: inline-block;
  top: calc(50% - 8px);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #006E51;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.container_timeline.left .date {
  right: -75px;
}

.container_timeline.right .date {
  left: -75px;
}

.container_timeline .icon {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 9px 0;
  top: calc(50% - 20px);
  background: #F6D155;
  border: 2px solid #006E51;
  border-radius: 40px;
  text-align: center;
  font-size: 16px;
  color: #006E51;
  z-index: 1;
}

.container_timeline.left .icon {
  right: 56px;
}

.container_timeline.right .icon {
  left: 56px;
}

.container_timeline .content-timeline {
  /* padding: 30px 90px 30px 30px; */
  /* background: #F6D155; */
  border: 2px solid #2B2A4C;
  /* border: 2px solid #2B2A4C; */
  position: relative;
  border-radius: 12px;
  z-index: 1;
}

.container_timeline.right .content-timeline {
  border: 2px solid #F31559;
}

.container_timeline .content-timeline h2 {
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: normal;
  /* color: #006E51; */
  color: white;
}

.container_timeline .content-timeline p {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

@media (max-width: 767.98px) {
  .timeline::after {
    left: 90px;
  }

  .container_timeline {
    width: 100%;
    padding-left: 120px;
    padding-right: 30px;
  }

  .container_timeline.right {
    left: 0%;
  }

  .container_timeline.left::after,
  .container_timeline.right::after {
    left: 82px;
  }

  .container_timeline.left::before,
  .container_timeline.right::before {
    left: 100px;
    border-color: transparent #006E51 transparent transparent;
  }

  .container_timeline.left .date,
  .container_timeline.right .date {
    right: auto;
    left: 15px;
  }

  .container_timeline.left .icon,
  .container_timeline.right .icon {
    right: auto;
    left: 146px;
  }

  .container_timeline.left .content-timeline,
  .container_timeline.right .content-timeline {
    padding: 30px 30px 30px 90px;
    border-radius: 500px 0 0 500px;
  }
}

.timeline-heading {
  display: flex;
  justify-content: center;
  background-color: #2B2A4C;
  border-radius: 12px 12px 0 0;
  color: white;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.timeline-heading-right {
  display: flex;
  justify-content: center;
  /* background-color: black;*/
  background-color: #F31559;
  /* z-index: -1; */
  border-radius: 12px 12px 0 0;
  color: white;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.timeline-content {
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 30px 90px 30px 30px;
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
}

.timeline-content-right {
  display: flex;
  justify-content: center;
  background-color: white;
  /* padding: 30px 90px 30px 30px; */
  padding: 30px 90px 30px 30px;
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
}

.Milestones-top-spacing {
  padding-top: 25px;
}

.Milestones-bottom-spacing {
  padding-bottom: 80px;
}

.tm-section {
  position: relative;
  padding: 37px 0px 106px 0px;
  text-align: center;
  height: 600px;
}

.tm-pattern-layer {
  position: absolute;
  width: 100%;
  height: 200px;
  left: 0px;
  top: 0px;
  right: 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../../Images_1/AboutUs/AboutCompanyImages/TimelineImages/tm-bacground1.jpg');
}

.tm-title {
  margin-bottom: 47px;
  position: relative;
  display: block;
}

.tm-centred {
  text-align: center;
}

.tm-auto-container {
  position: relative;
  max-width: 1189px;
  padding: 0px 15px;
  margin: -9px auto;
  color: white;
  height: 112px;
}

.tm-inner-content {
  border: 0.25px solid #7cba27;
  height: 140px;
  position: relative;
  top: 73px;
  padding: 10px 10px 0 10px;
  text-align: center;
  background: #85bf3a47;
  border-radius: 12px;
}

.tm-inner-content p {
  text-align: center;
  /* font-size: clamp(14px, 1.5vw, 18px); */
  font-weight: 500;
  color: #18181B;
}

.tm-section:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 353px;
  left: 0px;
  top: 0px;
  right: 0px;
}

.tm-outer-container {
  position: relative;
  width: 100%;
  padding: 0px 30px;
}

.tm-owl-carousel {
  /* display: none; */
  display: block;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}

.tm-date::before {
  position: absolute;
  content: '';
  background-color: #fff;
  border: 0.25px solid #7cba27;
  width: 20px;
  height: 20px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -34px;
  border-radius: 3px;
}

.tm-date::after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -29px;
  background-color: #7cba27;
  border: 2px solid white;
  border-radius: 3px;
}

.tm-date {
  /* padding-bottom: 20px; */
  position: relative;
  color: white;
  font-size: 16px;
  /* font-weight: 600; */
}

.tm-inner {
  padding-top: 17px;
  position: relative;
  height: 320px;
}

.tm-inner-content::before {
  position: absolute;
  content: '';
  /* background-color: #7cba27; */
  /* width: 1px; */
  border-left: 1px solid #7cba27;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
  top: -41px;
}

.title-top h6 {
  color: white;
}

.tm-auto-container h2 {
  color: white;
}

.timeline-heading-h2 {
  font-weight: unset;
}

.color-white {
  color: white;
}

@media screen and (max-width: 1024px) {
  .tm-inner-content p {
    font-size: 16px;
  }
}

@media screen and (max-width: 460px) {
  /* .tm-inner-content p{
    font-size: 18px;
  } */

  .tm-inner-content {
    height: 100px;
  }
}