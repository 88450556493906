.dep-image-1 {
    width: 240px;
    height: 150px;
    position: relative;
    left: -20px;
    top: 10px;
}

.dep-image-2 {
    width: 240px;
    height: 150px;
    position: relative;
    right: -50px;
    top: 45px;
}

.dep-image-3 {
    position: relative;
    width: 400px;
    height: 400px;
    object-fit: cover;
    z-index: 2;
}

.department-details-div-text-div {
    padding: 5%;
    width: 80%;
    z-index: 2;
}

.department-details-div-image-div-1-1 {
    display: flex;
    width: auto;
    height: auto;
    position: relative;
}

.department-details-div-image-div-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.department-details-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
}

.department-details-section {
    padding: 40px 0px;
    margin: auto;
}

.flex-center-div {
    display: flex;
    justify-content: center;
    align-items: center;

}

.dep-div-2 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    position: relative;
}

.dep-div-2-bg::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 83%;
    height: 100%;
    z-index: 1;
    background-image: repeating-linear-gradient(0deg, rgba(25, 208, 33, 0.1) 0px, rgba(25, 208, 33, 0.1) 1px, transparent 1px, transparent 13px), repeating-linear-gradient(90deg, rgba(25, 208, 33, 0.1) 0px, rgba(25, 208, 33, 0.1) 1px, transparent 1px, transparent 13px), linear-gradient(90deg, rgb(80, 177, 80), rgb(141, 197, 80)) !important;
}

.department-details-div-image-div-1-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    position: relative;
}

.circle-design-div {
    border: 50px solid #7cba7c;
    border-radius: 50%;
    height: 380px;
    width: 380px;
    position: relative;
}

.dep-image-4 {
    width: 320px;
    height: 100%;
}

.dep-image-5 {
    width: 260px;
    height: 300px;
    position: relative;
    left: -100px;
}

.department-details-heading {
    line-height: normal;
    /* font-size: 36px; */
    /* font-size: clamp(18px, 2.5vw, 30px); */
    /* font-size: clamp(18px, 2.5vw, 30px); */
    padding-bottom: 10px;
}

.department-details-text {
    /* font-size: clamp(14px, 2.5vw, 18px); */
    /* font-size: 18px; */
    /* font-size: clamp(14px, 2.5vw, 18px); */
    text-align: justify;
    line-height: normal;
}

/* Button CSS */

.see-more-button-department {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 10rem;
    color: #000000;
    font-size: 1rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    border: none;
    z-index: 1;
    border: 1px solid rgb(175, 172, 172);
    text-align: center;
    background-color: #ffffff;
}

.see-more-button-department:after {
    content: '';
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 10rem;
    z-index: -2;
}

.see-more-button-department:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: black;
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
}

.see-more-button-department:hover {
    color: white;
}

.see-more-button-department:hover:before {
    width: 100%;
}

/* Button CSS Ends */

.shape-div {
    width: 50px;
    height: 50px;
    border: 3px solid #7cba7c;
    border-radius: 50px;
    position: absolute;
    top: 270px;
    right: 530px;
}

.shape-div-2 {
    width: 90px;
    height: 90px;
    border: 3px solid #000000;
    border-radius: 50px;
    position: absolute;
    top: 415px;
    left: 190px;
    z-index: -1;
}

.shape-div-3 {
    width: 90px;
    height: 90px;
    border: 3px solid #000000;
    border-radius: 50px;
    position: absolute;
    bottom: 30px;
    left: 310px;
    z-index: -1;
}

.shape-div-4 {
    width: 50px;
    height: 50px;
    border: 3px solid #7cba7c;
    border-radius: 50px;
    position: absolute;
    top: 20px;
    right: 180px;
    z-index: -1;
}

/* @media (max-width: 768px){

.department-details-div-text-div {
    padding: 5%;
    width: 100%;
}
.dep-div-2 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    position: relative;
}

.dep-div-2-bg::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 93%;
    height: 100%;
    z-index: -2;
    background-image: repeating-linear-gradient(0deg, rgba(25, 208, 33, 0.1) 0px, rgba(25, 208, 33, 0.1) 1px, transparent 1px, transparent 13px), repeating-linear-gradient(90deg, rgba(25, 208, 33, 0.1) 0px, rgba(25, 208, 33, 0.1) 1px, transparent 1px, transparent 13px), linear-gradient(90deg, rgb(80, 177, 80), rgb(141, 197, 80));
}
} */


@media only screen and (min-width: 425px) and (max-width: 1023px) {
    .flex-center-div {
        flex-direction: column-reverse;
    }

    .dep-div-2-bg::after {
        width: 100%;
    }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
    .flex-center-div {
        flex-direction: column-reverse;
    }
}

@media only screen and (max-width: 426px) {
    .flex-center-div {
        flex-direction: column-reverse;
    }

    .dep-div-2-bg::after {
        width: 100%;
    }

    .department-details-div-text-div {
        padding: 5%;
        width: 100%;
    }
}