.services-card:hover::before {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    opacity: 1;
    
}

.services-card::before {
    content: "";
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 90px;
    height: 90px;
    border-radius: 272px 0 0;
    transition: .7s;
    color: #ffffff;
}


.services-card::after {
    content: "";
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 75px;
    height: 75px;
    border-radius: 170px 0 0;
    transition: .7s;
}

.services-card::after,
.services-card::before {
    opacity: .1;
    position: absolute;
    background-color: #7cba7c;
    color: #ffffff;
}