.form2-main-div{
  height: max-content;
  width: 80%;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 20px;
  display: flex;
  justify-content: center;
  margin-left: 50px;
  padding-bottom: 30px;
}



.form2-row-div{
  display: flex;
}

.form2-address textarea{
  border-radius: 0;
}

.text-salary{
  margin-left: 10px;
  margin-bottom: 10px;
  color: currentColor;
}

.form2-pt-10{
  padding-top: 30px;
}

.form2-pr {
  padding-right: 30px;
  /* text-align: center; */
  padding-top: 10px;
  color: #18181B;
}

.form2-pl{
  padding-left: 10px;
}

.form2-check-l{
  padding-left: 10px;
}

.form2-declare-styling{
  font-weight: 600;
  text-align: left;
}
.form2-mandatory{
  font-weight: 600;
  color: red;
}
.form-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}

.form2-pr{
  padding-right: 20px;
  padding-left: 16px;
}