.sitemap-section {
    width: 75%;
    margin: auto;
    padding: 50px 0px;
}

.sitemap-div {
    padding: 20px 0px;
    border-bottom: 1px solid #ccc;
    max-width: 80%;
}

.parent-name {
    padding: 20px 0px;
    font-weight: 500;
    color: #18181b;
    font-size: 26px;
    letter-spacing: 0.03vw;
    font-family: kumbhfonts;
}

.child-list {
    display: flex;
    align-items: center;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.child-list li {
    float: left;
    font-size: 16px;
}

.child-list li a {
    float: left;
    font-size: 16px;
    color: #063e79;
}

.child-list li a:hover {
    color: #7cba27;
}