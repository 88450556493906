:root {
    --global-font-size: 16px;
  }
.home-aboutus-main-div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    z-index: 999;
    margin: auto;
    padding-bottom: 30px;
    padding-top: 30px
}

.aboutus-bg-div {
    /* background-image: radial-gradient(circle at 13% 47%, rgba(140, 140, 140, 0.03) 0%, rgba(140, 140, 140, 0.03) 25%, transparent 25%, transparent 100%), radial-gradient(circle at 28% 63%, rgba(143, 143, 143, 0.03) 0%, rgba(143, 143, 143, 0.03) 16%, transparent 16%, transparent 100%), radial-gradient(circle at 81% 56%, rgba(65, 65, 65, 0.03) 0%, rgba(65, 65, 65, 0.03) 12%, transparent 12%, transparent 100%), radial-gradient(circle at 26% 48%, rgba(60, 60, 60, 0.03) 0%, rgba(60, 60, 60, 0.03) 6%, transparent 6%, transparent 100%), radial-gradient(circle at 97% 17%, rgba(150, 150, 150, 0.03) 0%, rgba(150, 150, 150, 0.03) 56%, transparent 56%, transparent 100%), radial-gradient(circle at 50% 100%, rgba(25, 25, 25, 0.03) 0%, rgba(25, 25, 25, 0.03) 36%, transparent 36%, transparent 100%), radial-gradient(circle at 55% 52%, rgba(69, 69, 69, 0.03) 0%, rgba(69, 69, 69, 0.03) 6%, transparent 6%, transparent 100%), linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255)); */
    background-image: linear-gradient(22.5deg, rgba(66, 66, 66, 0.02) 0%, rgba(66, 66, 66, 0.02) 11%, rgba(135, 135, 135, 0.02) 11%, rgba(135, 135, 135, 0.02) 24%, rgba(29, 29, 29, 0.02) 24%, rgba(29, 29, 29, 0.02) 38%, rgba(15, 15, 15, 0.02) 38%, rgba(15, 15, 15, 0.02) 50%, rgba(180, 180, 180, 0.02) 50%, rgba(180, 180, 180, 0.02) 77%, rgba(205, 205, 205, 0.02) 77%, rgba(205, 205, 205, 0.02) 100%), linear-gradient(67.5deg, rgba(10, 10, 10, 0.02) 0%, rgba(10, 10, 10, 0.02) 22%, rgba(52, 52, 52, 0.02) 22%, rgba(52, 52, 52, 0.02) 29%, rgba(203, 203, 203, 0.02) 29%, rgba(203, 203, 203, 0.02) 30%, rgba(69, 69, 69, 0.02) 30%, rgba(69, 69, 69, 0.02) 75%, rgba(231, 231, 231, 0.02) 75%, rgba(231, 231, 231, 0.02) 95%, rgba(138, 138, 138, 0.02) 95%, rgba(138, 138, 138, 0.02) 100%), linear-gradient(112.5deg, rgba(221, 221, 221, 0.02) 0%, rgba(221, 221, 221, 0.02) 17%, rgba(190, 190, 190, 0.02) 17%, rgba(190, 190, 190, 0.02) 39%, rgba(186, 186, 186, 0.02) 39%, rgba(186, 186, 186, 0.02) 66%, rgba(191, 191, 191, 0.02) 66%, rgba(191, 191, 191, 0.02) 68%, rgba(16, 16, 16, 0.02) 68%, rgba(16, 16, 16, 0.02) 70%, rgba(94, 94, 94, 0.02) 70%, rgba(94, 94, 94, 0.02) 100%), linear-gradient(90deg, #ffffff, #ffffff);
}

.home-aboutus-content-container-div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.aboutus-title {
    height: 100px;
    /* font-size: clamp(var(--global-font-size-sub-title-xs), 2.5vw, var(--global-font-size)); */
    /* font-size: var(--global-font-size); */
    /* font-size: 18px; */
    color: #18181B;
    line-height: 34px;
    margin-bottom: 10px;
    width: 180px;
}
.title-animation-about-us {
    display: flex;
    justify-content: center;
    width: 50%;
    /* font-size: clamp(14px, 2.5vw, 18px); */
    padding: 0px 10px;
    text-align: center;
    line-height: normal;
    
}

.aboutus-block {
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-box {
    width: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-aboutus-content-img {
    background-color: #ffffff;
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
}

.aboutus-icon {
    width: 160px;
    height: 160px;
}

.icon-div-shadow {
    --tw-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 0px 30px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    /* box-shadow: 0px 0px 10px #a7a7a7; */
}
@media only screen and (min-width: 1440px) {
    .title-animation-about-us {
        display: flex;
        justify-content: center;
        width: 60%;
        /* font-size: clamp(14px, 2.5vw, 18px); */
       
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    .home-aboutus-content-container-div {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }
    .title-animation-about-us {
        display: flex;
        justify-content: center;
        width: 70%;
        /* font-size: clamp(14px, 2.5vw, 18px); */
      
    }
    .home-aboutus-content-container-div>div {
        justify-self: center;
    }

    .aboutus-block {
        width: 150px;
    }

    .aboutus-icon {
        width: 120px;
        height: 120px;
    }

    .home-aboutus-content-img {
        width: 160px;
        height: 160px;
    }

    .home-aboutus-text-container {
        width: 180px;
    }

    .home-aboutus-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .aboutus-title {
        height: max-content;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .home-aboutus-content-container-div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .title-animation-about-us {
        display: flex;
        justify-content: center;
        width: 90%;
        /* font-size: clamp(14px, 2.5vw, 18px); */
       
    }
    .home-aboutus-content-container-div>div {
        justify-self: center;
    }

    .aboutus-block {
        width: 150px;
    }

    .aboutus-icon {
        width: 120px;
        height: 120px;
    }

    .home-aboutus-content-img {
        width: 160px;
        height: 160px;
    }

    .home-aboutus-text-container {
        width: max-content;
    }

    .home-aboutus-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .aboutus-title {
        height: max-content;
    }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
    .home-aboutus-content-container-div {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .home-aboutus-content-container-div>div {
        justify-self: center;
    }
    .title-animation-about-us {
        display: flex;
        justify-content: center;
        width: 90%;
        /* font-size: clamp(14px, 2.5vw, 18px); */
       
    }
    .aboutus-block {
        width: 150px;
    }

    .aboutus-icon {
        width: 120px;
        height: 120px;
    }

    .home-aboutus-content-img {
        width: 160px;
        height: 160px;
    }

    .home-aboutus-text-container {
        width: max-content;
    }

    .home-aboutus-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .aboutus-title {
        height: max-content;
    }
}

@media only screen and (max-width: 426px) {
    .home-aboutus-content-container-div {
        flex-direction: column;
    }

    .aboutus-block {
        width: 150px;
    }

    .aboutus-icon {
        width: 100px;
        height: 100px;
    }
    .title-animation-about-us {
        display: flex;
        justify-content: center;
        width: 90%;
        /* font-size: clamp(14px, 2.5vw, 18px); */
    }
    .home-aboutus-content-img {
        width: 120px;
        height: 120px;
    }

    .home-aboutus-text-container {
        width: max-content;
    }

    .home-aboutus-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .aboutus-title {
        height: max-content;
    }
}