.main-slider-image {
  width: 100%;
  height: 76vh;
  object-fit: cover;
}

.theAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}
.linkheaderright1 {
  position: absolute;
  top: 2rem;
  left: 2rem;
  padding: 10px;
  width: 230px;
  height: 50px;
  background: #7cba27;
  border-radius: 20;
  background: #7cba27;
  color: #ffffff;
  /* box-shadow: 0px 5px 20px #7cba7c; */
  z-index: 10000;
}
.linkheaderright1:hover {
  background: #7cba27;
  color: #ffffff;
  /* box-shadow: 0px 5px 20px 5px #7cba27; */
}
.marquee-container {
  width: 100%;
  overflow: hidden;
  align-content: center;
  padding-bottom: 25px;
}

.marquee-container:hover {
  animation-play-state: paused;
}

.marquee:hover {
  animation-play-state: paused;
  color: #ffffff;
  
}

.marquee {
  display: block;
  width: 200%;
  position: absolute;
  overflow: hidden;
 
  animation: marquee 20s linear infinite;
  /* display: flex;
  justify-content: right;
  animation: marquee 20s linear infinite; */
  /* width: max-content; */
}
@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}
/* @keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
} */


/*both custom buttons*/
.custom-prev-button,
.custom-next-button {
  position: absolute;
  bottom: 20px;
  /* background-color: #fff; */
  border: 2px solid #7cba27;
  padding: 5px 10px;
  cursor: pointer;
  transform: skewX(-23deg) translateY(-50px) translateX(50px);
  width: 80px;
  height: 120px;
  background-color: #7cba27;
  color: white;
  font-weight: bold;
}

@media only screen and (min-width: 1000px) and (max-width: 1439px) {

  .custom-prev-button,
  .custom-next-button {
    width: 60px;
    height: 90px;
    transform: skewX(-23deg) translateY(-30px) translateX(80px);
  }
}

@media only screen and (max-width: 999px) {

  .custom-prev-button,
  .custom-next-button {
    width: 60px;
    height: 70px;
    transform: skewX(-23deg) translateY(-30px) translateX(120px);
  }
}

@media only screen and (max-width: 420px) {

  .custom-prev-button,
  .custom-next-button {
    width: 35px;
    height: 45px;
    transform: skewX(-23deg) translateY(-20px) translateX(140px);
  }
}

/* 
@media only screen and (min-width: 370px) and (max-width: 419px) {

  .custom-prev-button,
  .custom-next-button {
    width: 30px;
    height: 40px;
    transform: skewX(-23deg) translateY(-10px) translateX(150px);
  }
}


@media only screen and (max-width: 369px) {

  .custom-prev-button,
  .custom-next-button {
    width: 20px;
    height: 30px;
    transform: skewX(-23deg) translateY(-10px) translateX(155px);
  }
} */

/*for each individual custom button*/
.custom-prev-button:hover {
  transition: all ease-in-out 0.3s;
  border: 2px solid rgb(255, 252, 252);
}

.custom-next-button:hover {
  transition: all ease-in-out 0.3s;
  border: 2px solid rgb(255, 252, 252);
}

.custom-next-button {
  z-index: 10;
  bottom: 20px;
  right: calc(0% + 170px);
}


.custom-prev-button {
  z-index: 10;
  bottom: 20px;
  left: calc(100% - 350px);
}


@media only screen and (min-width: 1000px) and (max-width: 1439px) {
  .custom-prev-button {
    z-index: 10;
    bottom: 20px;
    left: calc(100% - 310px);
  }

}

@media only screen and (max-width: 999px) {

  .custom-prev-button {
    z-index: 10;
    bottom: 0px;
    left: calc(100% - 300px);
  }

  .custom-next-button {
    bottom: 0px;
  }
}

@media screen and (max-width: 420px) {

  .custom-prev-button {
    z-index: 10;
    bottom: 0px;
    left: calc(100% - 250px);
  }

  .custom-next-button {
    bottom: 0px;
  }

}

/* 
@media only screen and (min-width: 370px) and (max-width: 419px) {

  .custom-prev-button {
    z-index: 10;
    bottom: 0px;
    left: calc(100% - 240px);
  }

  .custom-next-button {
    bottom: 0px;
  }

}

@media only screen and (max-width: 369px) {

  .custom-prev-button {
    z-index: 10;
    bottom: 0px;
    left: calc(100% - 225px);
  }

  .custom-next-button {
    bottom: 0px;
  }

} */


.slider-content {
  width: 100%;
  height: max-content;
  position: relative;
}

@keyframes fadeInAndUpCustomSlider {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
    ;
  }
}

.image-translateCustomSlider {
  --tw-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0px 0px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  opacity: 0;
  animation: fadeInAndUpCustomSlider 1s ease-in-out forwards;
}

.image-translateCustomSlider {
  opacity: 1;
  transform: translateX(0);
}


@keyframes fadeInAndUpCustomSlider1 {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
    ;
  }
}

.image-translateCustomSlider1 {
  --tw-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0px 0px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  opacity: 0;
  animation: fadeInAndUpCustomSlider1 1.4s ease-in-out backwards;
}

.image-translateCustomSlider1 {
  opacity: 1;
  transform: translateX(0);
}


/* /all about the left side animation button/ */
@keyframes fadeInAndUpCustomSlider2 {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.image-translateCustomSlider2 {
  --tw-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0px 0px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  opacity: 0;
  animation: fadeInAndUpCustomSlider2 1.8s ease-in-out forwards;
}


.image-translateCustomSlider2 {
  opacity: 1;
}


.custom-slider-changing-button {
  position: absolute;
  display: flex;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
  top: 57%;
  left: 5%;
  font-family: kumbhfonts;
  z-index: 10;
  color: black;
  text-align: center;
  align-items: center;
  position: absolute;
  border: 2px solid #7cba27;
  cursor: pointer;
  width: 240px;
  justify-content: space-between;
  padding-left: 24px;
  height: fit-content;
  background-color: #7cba27;
  z-index: 10;
  margin-top: 50px;
}

@media only screen and (min-width: 1000px) and (max-width: 1439px) {
  .custom-slider-changing-button {
    /* top: 70%; */
    width: 200px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 999px) {

  .custom-slider-changing-button {
    /* top: 70%; */
    width: 170px;
    font-size: 12px;
  }
}

/* @media only screen and (min-width: 420px) and (max-width: 749px) {

  .custom-slider-changing-button {
    top: 80%;
    width: 170px;
    font-size: 12px;
  }

} */


/* @media only screen and (min-width: 370px) and (max-width: 419px) {

   .custom-slider-changing-button {
    top: 70%;
    width: 90px;
    font-size: 8px;
    padding-left: 0px;
    height: fit-content;
  } 

  .custom-slider-changing-button {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 75%;
    width: 80px;
    font-size: 7px;
    padding-left: 0px;
    height: 20px;
  }

} */

/* @media only screen and (max-width: 330px) {
  .custom-slider-changing-button {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 75%;
    width: 80px;
    font-size: 7px;
    padding-left: 0px;
    height: 20px;
  }

} */

.custom-slider-changing-button:hover {
  background-color: transparent;
  color: white;
  transition: all ease-in-out 0.3s;
}

.custom-slider-changing-button:hover .icons-in-button-slider-animated {
  background-color: #7cba27;
  transform: skewX(-39deg) scale(110%);
  transition: all ease-in-out 0.3s;
}

.custom-slider-changing-button::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  right: -1px;
  width: 6px;
  background-color: #7cba27;
  transform-origin: top right;
  transform: skewX(-39deg);
  z-index: -1;
}

.icons-in-button-slider-animated {
  height: 100%;
  width: fit-content;
  background-color: #000000;
  padding: 10px;
  margin: 3px;
  transform: skewX(-39deg);
  margin-right: 13%;
}

@media only screen and (min-width: 1000px) and (max-width: 1439px) {

  .icons-in-button-slider-animated {
    padding: 5px;
    margin: 3px;
    transform: skewX(-40deg);
    margin-right: 13%;
  }
}

@media only screen and (max-width: 999px) {
  .icons-in-button-slider-animated {
    padding: 4px;
    margin: 3px;
    transform: skewX(-37deg);
    margin-right: 13%;
  }
}

/* @media only screen and (max-width: 749px) {

  .icons-in-button-slider-animated {
    padding: 0px;
    margin: 0px;
    transform: skewX(-38deg);
    margin-right: 10%;
  }
} */

/* @media only screen and (min-width: 370px) and (max-width: 419px) {

   .icons-in-button-slider-animated {
    padding: 0px;
    margin: 0px;
    transform: skewX(-29deg);
    margin-right: 7%;
  } 

  .icons-in-button-slider-animated {
    padding: 0px;
    margin: 0px;
    transform: skewX(-33deg);
    margin-right: 2%;
    margin-left: 2%;
  }
} */

/* @media only screen and (max-width: 419px) {

   .icons-in-button-slider-animated {
    padding: 0px;
    margin: 0px;
    transform: skewX(-33deg);
    margin-right: 2%;
    margin-left: 2%;
  } 

  .icons-skew-in-slider {
    transform: skewX(30deg);
    color: white;
    padding: 8px;
  } 

} */


.custom-slider-changing-text {
  height: fit-content;
  /* font-weight: 600; */
  font-family: kumbhfonts;
  display: flex;
  width: fit-content;
  background-color: transparent;
  justify-content: start;
  position: absolute;
  top: 35%;
  left: 5%;
  /* transform: translate(-50%, -50%); */
  /* font-size: 36px; */
  z-index: 10;
  color: white;
  text-align: center;

}

@media only screen and (min-width: 1440px) and (max-width: 1450px) {
  .custom-slider-changing-text {
    /* font-size: 30px; */
    /* top: 25%; */
  }

}

@media only screen and (min-width: 1000px) and (max-width: 1439px) {
  .custom-slider-changing-text {
    /* font-size: 30px; */
    /* top: 25%; */
  }
}

@media only screen and (max-width: 999px) {
  .custom-slider-changing-text {
    /* font-size: 25px; */
    /* top: 20%; */
  }
}

/* @media only screen and (min-width: 420px) and (max-width: 749px) {
  .custom-slider-changing-text {
    width: fit-content;
    left: 50%;
    text-align: center;
    top: 20%;
  }

  .home-slider-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

} */
/* 
@media only screen and (min-width: 370px) and (max-width: 419px) {
  .custom-slider-changing-text {
    font-size: 13px;
    top: 10%;
  }

}

@media only screen and (max-width: 369px) {
  .custom-slider-changing-text {
    font-size: 10px;
    top: 10%;
  }
} */

.custom-slider-changing-paragraph {
  font-family: kumbhfonts;
  font-weight: normal;
  /* font-size: 18px; */
  align-content: start;
  /* color: #18181b; */
  color: white;
  display: flex;

  letter-spacing: 0.2px;
  justify-content: start;
  padding-top: 5px;
  /* padding-left: 15px; */
  padding-right: 15px;
  position: absolute;
  width: 625px;
  display: flex;
  top: 42%;
  left: 5%;
  z-index: 10;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: justify;
}

/* @media only screen and (min-width: 1440px) and (max-width: 1450px) {
  .custom-slider-changing-paragraph {
    top: 37%;
    font-size: 15px;
  }

} */

/* @media only screen and (min-width: 1000px) and (max-width: 1439px) {
  .custom-slider-changing-paragraph {
    top: 37%;
    font-size: 15px;
  }

} */

@media only screen and (min-width: 750px) and (max-width: 999px) {
  .custom-slider-changing-paragraph {
    /* top: 35%; */
    /* font-size: 12px; */
    width: 450px;
    line-height: 2;
  }

}

@media only screen and (min-width: 420px) and (max-width: 749px) {
  .custom-slider-changing-paragraph {
    /* top: 35%; */
    /* font-size: 12px; */
    width: 400px;
    line-height: 2;
  }


}

@media only screen and (min-width: 370px) and (max-width: 419px) {
  .custom-slider-changing-paragraph {
    /* top: 35%; */
    /* font-size: 9px; */
    width: 325px;
    line-height: 2;
  }
}

@media only screen and (max-width: 369px) {
  .custom-slider-changing-paragraph {
    /* top: 35%; */
    /* font-size: 9px; */
    width: fit-content;
    line-height: 2;
  }

}


.custome-swiper-class {
  overflow: hidden;
}

.swiper-slide-overflow {
  overflow: hidden;
}


.custom-swiper-div-with-black-bg {
  opacity: 0.4;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0%;
  opacity: 0;
  display: flex;
  position: absolute;
  height: calc(100% - 0px);
  z-index: 4;
  align-items: center;
  width: 100%;
}

.custom-swiper-div-with-black-bg {
  opacity: 1;
}


/* /all for bg1/ */
@keyframes bgfadeinanimation1 {
  from {
    opacity: 0;
    transform: translateX(200px) skewX(-20deg);
  }

  to {
    opacity: 1;
    transform: translateY(0) translateX(0) skewX(-20deg);
  }
}


.custom-swiper-div-with-black-bg1 {
  opacity: 0;
  animation: bgfadeinanimation1 0.7s ease-in-out forwards;
  background-color: rgba(149, 218, 51, 0.4);
  color: #18181b;
  top: 0%;
  right: calc(13%);
  display: flex;
  position: absolute;
  height: calc(100% - 8px);
  z-index: 4;
  color: black;
  align-items: center;
  /* transform: skewX(-20deg) ; */
  width: calc(25%);
}

.custom-swiper-div-with-black-bg1 {
  opacity: 1;
}



/* /all for bg2/ */
@keyframes bgfadeinanimation2 {
  from {
    opacity: 0;
    transform: translateX(200px) skewX(-23deg);
  }

  to {
    opacity: 1;
    transform: translateY(0) skewX(-23deg) translateX(0)
  }
}

.custom-swiper-div-with-black-bg2 {
  background-color: rgba(149, 218, 51);
  color: #18181b;
  top: 25%;
  right: 10%;
  display: flex;
  position: absolute;
  height: calc(65%);
  z-index: 4;
  color: black;
  align-items: center;
  animation: bgfadeinanimation2 0.9s ease-in-out forwards;
  /* transform: skewX(-20deg) translateX(270px) ; */
  width: calc(15%);
}

.custom-swiper-div-with-black-bg2 {
  opacity: 1;
}



.svg-home-main-slider {
  z-index: 20;
  color: #18181b;
  top: -49%;
  transform: skewX(-20deg);
  left: 40%;
  display: flex;
  position: absolute;
  height: calc(100% - 8px);
  z-index: 4;
  color: black;
  align-items: center;

  width: calc(14%);
}


.svg-home-main-slider1 {
  z-index: 20;
  color: #18181b;
  bottom: -49%;
  transform: skewX(-20deg);
  left: 0%;
  display: flex;
  position: absolute;
  height: calc(100% - 8px);
  z-index: 4;
  color: black;
  align-items: center;

  width: calc(14%);
}



.icons-skew-in-slider {
  transform: skewX(38deg);
  color: white;
}

.announcement-Heading {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0px;
  background-color: orange;
  color: white;
  /* padding-top: 5px; */
  text-align: center;
}

@media only screen and (max-width: 420px) {
  .icons-skew-in-slider-for-button {
    transform: skewX(15deg);
    color: white;
    padding-right: 10px;
  }
}

.headingtitlemq:hover {
  color: #ffffff;
}

/* 
@media only screen and (min-width: 420px) and (max-width: 749px) {
  .icons-skew-in-slider {
    transform: skewX(30deg);
    color: white;
    padding: 5px;

  }

} */

/* @media only screen and (min-width: 370px) and (max-width: 419px) {

 
  .icons-skew-in-slider {
    transform: skewX(40deg);
    color: white;
    padding: 7px;
    transform: translateY(-6px);
  }
}

@media only screen and (max-width: 369px) {
  .icons-skew-in-slider {
    transform: skewX(40deg);
    color: white;
    padding: 7px;
    transform: translateY(-6px);
  }


}

.icons-skew-in-slider-for-button {
  transform: skewX(30deg);
  color: white;

} */

/* @media only screen and (min-width: 420px) and (max-width: 749px) {
  .icons-skew-in-slider-for-button {
    transform: skewX(15deg);
    color: white;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 370px) and (max-width: 419px) {
  /* .icons-skew-in-slider-for-button {
    transform: skewX(15deg);
    color: white;
    padding-right: 17px;
  } 

  .announcement-Heading {
    height: 20px;
  }

  .gujaratHeading {
    height: 20px;
    padding-top: 0px;
  }

  .headingTitle {
    padding: 0px;
  }

}

@media only screen and (max-width: 369px) {
  .icons-skew-in-slider-for-button {
    transform: skewX(15deg);
    color: white;
    padding-right: 17px;
    transform: translateY(-6px) translateX(-3px)
  }

  .announcement-Heading {
    height: 20px;
  }

  .gujaratHeading {
    height: 20px;
    padding-top: 0px;
  }

  .headingTitle {
    padding: 0px;
  }
} */

.custome-swiper-class {
  overflow: hidden;
}

.swiper-slide-overflow {
  overflow: hidden;
}


/* /all for bg/ */
@keyframes bgfadeinanimation {
  from {
    opacity: 0;
    transform: translateX(-200px) skewX(-20deg);
  }

  to {
    opacity: 1;
    transform: translateY(200px) skewX(-20deg) translateX(0)
  }
}




/* /all for bg1/ */
@keyframes bgfadeinanimation {
  from {
    opacity: 0;
    transform: translateX(-200px) skewX(-20deg);
  }

  to {
    opacity: 1;
    transform: translateY(0) translateX(0) skewX(-20deg);
  }
}


/* /all for bg2/ */
@keyframes bgfadeinanimation2 {
  from {
    opacity: 0;
    transform: translateX(200px) skewX(-20deg);
  }

  to {
    opacity: 1;
    transform: translateY(0) skewX(-20deg) translateX(0)
  }
}





.svg-home-main-slider {
  z-index: 20;
  color: #18181b;
  top: -49%;
  transform: skewX(-20deg);
  left: 40%;
  display: flex;
  position: absolute;
  height: calc(100% - 8px);
  z-index: 4;
  color: black;
  align-items: center;

  width: calc(14%);
}


.svg-home-main-slider1 {
  z-index: 20;
  color: #18181b;
  bottom: -49%;
  transform: skewX(-20deg);
  left: 0%;
  display: flex;
  position: absolute;
  height: calc(100% - 8px);
  z-index: 4;
  color: black;
  align-items: center;

  width: calc(14%);
}

@media only screen and (max-width: 749px) {
  .custom-slider-changing-text {
    position: static;
    text-align: start;
    display: flex;
    justify-content: center;
  }

  .custom-slider-changing-paragraph {
    position: static;
    text-align: start;
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
    /* margin-block-end: 0px; */
  }

  .custom-slider-changing-button {
    position: static;
    margin: auto;
  }

  .hs-content-div {
    position: absolute;
    top: 50%;
    width: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: start;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    color: white;
    z-index: 5;
    gap: 25px;
  }

  .hs-button-link {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}