/* src/ExpandableCard.css */
.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  cursor: pointer;
}

.card-header {
  background-color: #f7f7f7;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.card-header h2 {
  margin: 0;
}

.card-content {
  padding: 10px;
  background-color: #fff;
}

.expanded .card-content {
  display: block;
}

.card-content {
  display: none;
}

.AppExp {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0px;
}

.tmarginandb {
  margin-bottom: 10px;
}

.aboutus-heading-fonts {
  text-align: justify;
}

@media only screen and (min-width:280px) and (max-width:700px) {
  .tmarginandb {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 20px;
    font-weight: 600;
  }
}