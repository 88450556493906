.form-container-ev {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.head-title {
    display: flex;
    justify-content: center;
    font-weight: 600;
    /* font-size: larger; */
}

.field-container {
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.field-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.result-content {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.result-content-main {
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
    font-weight: 700;
}

.result-content-main .result-container {
    border: 1px;
    width: 30%;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-color: black;
    border-style: solid;
    border-radius: 7.5px;
    display: flex;
    color: #7cba27;
    font-size: 600;
}

.result-container {
    border: 1px;
    width: 30%;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-color: black;
    border-style: solid;
    border-radius: 5px;
    display: flex;
}

.dark-mode {
    background-color: black;
}

.dark-mode .result-container {
    background-color: black;
    color: white;
    border-color: white;
    border-radius: 5px;
}

.dark-mode .form-container-ev {
    background-color: rgb(91, 91, 91);
    color: white;
}

.dark-mode #from {
    background-color: rgb(91, 91, 91);
}

.ev-choose-type{
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 30px;
}
.ev-choose-type img{
    width: 90px;
    aspect-ratio: 3/2;
    object-fit: contain;
    border-radius: 10px;
}

.ev-choose-type div{
    border: 2px solid #f1f1f1;
    border-radius: 10px;
    background: white !important;
}

.ev-choose-type button{
    border: none;
   
    display: contents;
}

.ev-green-border{
    border-color: #7cba27;
}

.form-container-ev-div{
    width: 48%;
}

.ev-input-size{
    width: 15%;
}
@media screen and (max-width: 1024px) {
    .form-container-ev{
        flex-direction: column;
        gap: 20px;
    }
    .form-container-ev-div{
        width: 90%;
        margin: auto;
    }
    .result-content-main{
        flex-direction: column;
    }
    
}

@media screen and (max-width: 500px){
    .ev-input-size{
        width: 30%;
    }
}