.fab-wrapper {
    position: fixed;
    top: 26%;
    right: 40px;
    z-index: 100000;
}

.fab-checkbox {
    display: none;
}

.fab {
    position: absolute;
    bottom: -1rem;
    right: -1rem;
    width: 50px;
    height: 50px;
    background: #7cba27;
    border-radius: 50%;
    background: #7cba27;
    color: #ffffff;
    /* box-shadow: 0px 5px 20px #7cba7c; */
    transition: all 0.3s ease;
    z-index: 1;
    border: 1px solid #7cba27;
    animation: highlightPulse 2s infinite;
}

/* .fab {
    position: relative;
    width: 56px;
    height: 56px;
    background-color: #6200ee;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
   
} */

@keyframes highlightPulse {
    0% {
        box-shadow: 0px 0px 0px rgba(98, 0, 238, 0.2);
    }
    50% {
        box-shadow: 0px 0px 15px rgba(98, 0, 238, 0.8);
    }
    100% {
        box-shadow: 0px 0px 0px rgba(98, 0, 238, 0.2);
    }
}

.fab:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
}

.fab-checkbox:checked~.fab:before {
    width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
    background-color: rgba(255, 255, 255, 0.2);
}

.fab:hover {
    background: #7cba27;
    color: #ffffff;
    /* box-shadow: 0px 5px 20px 5px #7cba27; */
}

.fab-dots {
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: white;
    border-radius: 50%;
    top: 50%;
    transform: translateX(0%) translateY(-50%) rotate(0deg);
    opacity: 1;
    animation: blink 3s ease infinite;
    transition: all 0.3s ease;
}

.fab-dots-1 {
    left: 15px;
    animation-delay: 0s;
}

.fab-dots-2 {
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation-delay: 0.4s;
}

.fab-dots-3 {
    right: 15px;
    animation-delay: 0.8s;
}

.fab-checkbox:checked~.fab .fab-dots {
    height: 6px;
}

.fab .fab-dots-2 {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
}

.fab-checkbox:checked~.fab .fab-dots-1 {
    width: 32px;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.fab-checkbox:checked~.fab .fab-dots-3 {
    width: 32px;
    border-radius: 10px;
    right: 50%;
    transform: translateX(50%) translateY(-50%) rotate(-45deg);
}

@keyframes blink {
    50% {
        opacity: 0.25;
    }
}

.fab-checkbox:checked~.fab .fab-dots {
    animation: none;
}

.fab-wheel {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10rem;
    height: 10rem;
    transition: all 0.3s ease;
    transform-origin: bottom right;
    transform: scale(0);
}

.fab-checkbox:checked~.fab-wheel {
    transform: scale(1);
}

.fab-action {
    position: absolute;
    background: #7cba27;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    /* box-shadow: 0 0.1rem 1rem rgba(24, 66, 154, 0.82); */
    transition: all 1s ease;
    opacity: 0;
}

.fab-checkbox:checked~.fab-wheel .fab-action {
    opacity: 1;
}

.fab-action:hover {
    background-color: #D2611C;
    color: #ffffff;
}

.fab-wheel .fab-action-1 {
    right: -1rem;
    top: 0;
    border: none;
}

.fab-wheel .fab-action-2 {
    right: 3.4rem;
    top: 0.5rem;
    border: none;
}

.fab-wheel .fab-action-3 {
    left: 0.5rem;
    bottom: 3.4rem;
    border: none;
}

.fab-wheel .fab-action-4 {
    left: 0;
    bottom: -1rem;
    
}

.fab-wheel .fab-action-5 {
    left: 0.8rem;
    bottom: -5.2rem;
    border: none;
}

.fab-wheel .fab-action-6 {
    left: 4rem;
    bottom: -8.2rem;
    border: none;
}

@media only screen and (min-width: 1024px) and (max-width: 1496px) {
    .fab-wrapper {
        top: 26%;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .fab-wrapper {
        bottom: 153px;
    }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
    .fab-wrapper {
        bottom: 153px;
    }
}

@media only screen and (max-width: 426px) {
    .fab-wrapper {
        bottom: 153px;
    }
}