/* .progress-six_bg {
    margin: 100px 0px 0px 0px;
} */

.choose-image-custom-css {
  margin: 10% 0;
  /* margin-left: 20%; */
  position: relative;
  height: 500px;
  width: 760px;
}

.choose-bg {
  background-image: url("../../../Images_1/Home/ChooseUsImages/choose-bg-1.jpg");
  width: auto;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.choose-bg-dark-div {
  background: rgba(0, 0, 0, 0.4);
  width: auto;
  height: 100%;
}

.radiant-effect-div {
  /* background-image: linear-gradient(90deg, rgb(160, 203, 142), rgb(160, 203, 142, 0.6), rgb(160, 203, 142, 0), rgb(239, 219, 97, 0)); */
  background-image: linear-gradient(90deg, rgb(0, 0, 0, 0.8), rgb(0, 0, 0, 0.6), rgb(160, 203, 142, 0), rgb(239, 219, 97, 0));
  width: auto;
  height: 100%;
}



.service-pbmit-color {
  color: #ffffff;
}

.choose-title {
  color: #ffffff;
  /* font-size: clamp(18px, 2.5vw, 30px); */
  line-height: 125%;
  /* font-weight: 700; */
  padding-bottom: 50px;
}

.choose-top-spacing {
  margin-top: 0px;
}

@keyframes animatechooseusdescription {

  from {
    opacity: 0;
    transform: translateY(300px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }

}

.animate-choose-us-description {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  opacity: 0;
  animation: animatechooseusdescription 1.2s ease-in-out forwards;
}



@keyframes animatechooseusimage {

  from {
    opacity: 0;
    transform: translateX(400px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }

}

.animate-choose-us-image {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  opacity: 0;
  animation: animatechooseusimage 1.2s ease-in-out forwards;
}

.Text-centre-sub-heading {
  display: flex;
  text-align: left;
  justify-content: left;
}

.choose-bottom-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-align: center;
  width: 200px;
}


.india-map-info {
  text-align: end;
  color: white;
  padding-top: 50px;
  position: relative;
  right: 130px;
}

.chooseUsImg {
  margin-top: 20px;
  width: 95%;
  object-fit: cover;
  margin: 30px auto 0 auto;
}

.mapsm-icon {
  height: 100%;
  width: 70px;
}

/* .choose-flex-width {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
} */

@media screen and (max-width: 1200px) {
  .choose-title {
    text-align: center;
  }

  .counter-six_bg_color {
    margin-top: 50px;
  }

  .choose-flex-div {
    flex-direction: column;
    width: 100%;
    margin: auto;
  }

  .choose-flex-child-div {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
  }

  .counter-space-setter {
    padding: 15px;
  }

  .counter-bottom-spacing {
    padding-bottom: 50px;
  }

  .counter-bg-dark-div {
    background: rgba(0, 0, 0, 0.4);
    width: auto;
    height: 100%;
  }

  .counter-radiant-effect-div {
    /* background-image: linear-gradient(90deg, rgb(160, 203, 142), rgb(160, 203, 142, 0.6), rgb(160, 203, 142, 0), rgb(239, 219, 97, 0)); */
    background-image: linear-gradient(90deg, rgb(0, 0, 0, 0.8), rgb(0, 0, 0, 0.6), rgb(160, 203, 142, 0), rgb(239, 219, 97, 0));
    width: auto;
    height: 100%;
  }

}

@media (max-width: 500px) {
  .counter-six_bg_color {
    padding: 0px;
  }
}

@media only screen and (max-width:1024px) {
  .res-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

@media only screen and (max-width:720px) {
  .res-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .choose-title {
    color: #ffffff;
    /* font-size: 18px; */
    line-height: 125%;
    text-align: center;
    /* font-weight: 700; */
    padding-bottom: 20px;
  }
}

@media only screen and (min-width:350px) and (max-width:500px) {
  .res-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 350px) {
  .res-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 460px) {
  .res-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 770px) {
  .chooseUsImg {
    width: 100%;
    margin-top: 0px;
  }

  #nvprm {
    display: none;
  }
}

.map-container {
  margin: auto;
}


@media (min-width: 475px) {
  .map-container {
    max-width: 475px;
  }
}

@media (min-width: 640px) {
  .map-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .map-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .map-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .map-container {
    max-width: 1280px;
  }
}

@media (min-width: 1400px) {
  .map-container {
    max-width: 1400px;
  }
}

@media (min-width: 1536px) {
  .map-container {
    max-width: 1450px;
  }
}

.choose-flex-width {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  width: 100%;
}

@media (min-width: 300px) and (max-width: 450px) {
  .choose-flex-width {
    grid-template-columns: repeat(1, 1fr);
  }

  .choose-flex-child-div {
    width: 92%;
  }
}

@media (min-width: 319px) and (max-width: 425px) {
  .choose-flex-width {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 426px) and (max-width: 767px) {
  .choose-flex-width {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .choose-flex-width {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 1280px) {
  .choose-flex-div {
    width: 95%;
    margin: auto;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1537px) {
  .mapsm-icon {
    width: 55px;
  }
  .progress-six_single {
    padding: 35px 0px 0px 0px;
  }
}