.indiSpanColor {
    font-weight: 600;
    /* color: #7cba27 */
}

.row2 {
    justify-content: space-evenly;
}

.home-counter-section {
    height: 542px;
    display: flex;
    align-items: center;
    --tw-bg-opacity: 1;
    background-color: rgb(246 248 241 / var(--tw-bg-opacity));
}

.indiContent {
    border: 1px dashed rgb(35, 35, 35, .10);
    transition: all 0.4s ease;
    background-color: white;
    border-radius: 10px;
    text-align: center;
    /* padding: 0px 10px; */
    width: 230px;
    height: 210px;
}

.indiInner {
    font-size: 36px;
    font-weight: 600;
    color: var(--pbmit-global-color);
    letter-spacing: -0.5px;
    text-align: center;
    padding-bottom: 10px;
}

.indiSpaceContainer {
    display: -webkit-inline-box;
    padding: 10px;
    background-color: white;
    border-radius: 15px;
}

.indiDivider::before {
    /* z-index: 10; */
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    background-color: rgb(35, 35, 35, .10);
    top: -18px;
    border: 1px dashed rgb(35, 35, 35, .10);
}

.counter-section {
    position: relative;
    --tw-bg-opacity: 1;
    background-color: rgb(246 248 241 / var(--tw-bg-opacity));
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-individual {
    display: flex;
    justify-content: space-between;
}

.lower-div-individual {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: auto;
}

.upper-div-individual {
    position: relative;
    z-index: 1;
}

.home-counter-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.adjust-title-in-success-in-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* /individual1/ */
@keyframes animateingindividual {

    from {
        opacity: 0;
        transform: translateY(300px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }

}

.animate-individuals {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    opacity: 0;
    animation: animateingindividual 0.8s ease-in-out forwards;
}


/* /individual2/ */
@keyframes animateingindividual1 {

    from {
        opacity: 0;
        transform: translateY(300px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }

}

.animate-individuals1 {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    opacity: 0;
    animation: animateingindividual1 0.8s ease-in-out forwards 0.1s;
}

/* /individual3/ */
@keyframes animateingindividual2 {

    from {
        opacity: 0;
        transform: translateY(300px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }

}

.animate-individuals2 {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    opacity: 0;
    animation: animateingindividual2 0.8s ease-in-out forwards 0.3s;
}


/* /individual4/ */
@keyframes animateingindividual3 {

    from {
        opacity: 0;
        transform: translateY(300px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.animate-individuals3 {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    opacity: 0;
    animation: animateingindividual3 0.8s ease-in-out forwards;
}

/* /individual5/ */
@keyframes animateingindividual4 {
    from {
        opacity: 0;
        transform: translateY(300px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.animate-individuals4 {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    opacity: 0;
    animation: animateingindividual4 0.8s ease-in-out forwards 0.1s;
}

/* /individual6/ */
@keyframes animateingindividual5 {
    from {
        opacity: 0;
        transform: translateY(300px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.animate-individuals5 {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    opacity: 0;
    animation: animateingindividual5 0.8s ease-in-out forwards 0.3s;
}