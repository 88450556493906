.department-team-padding-section {
    padding: 40px 0px;
}

.ndt-main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 15px;
    /* padding-top: 180px; */
    /* padding-bottom: 30px; */
    background-color: #F8F8F8;
    position: relative;
}

.ndt-heading {
    position: relative;
}

.ndt-heading-subtitle {
    font-size: 18px;
    font-weight: 700;
    font-family: 'Plus Jakarta Sans' sans-serif;
    color: #7cba27;
    position: relative;
    letter-spacing: .1em;
    padding-bottom: 12px;
}

.ndt-heading-subtitle:before {
    background: #7cba27;
    content: "";
    height: 2px;
    left: 9%;
    margin-left: 10px;
    margin-top: -1px;
    position: absolute;
    top: 35%;
    width: 40px;

}

.ndt-heading h2 {
    position: relative;
    letter-spacing: -1.3px;
    font-size: 48px;
    font-weight: 700;
    line-height: 110%;
}

.icon-quote {
    width: 64px;
    height: 46px;
    background-image: url(../../../Images_1/CommonSection/Common/icon-quote.png);
}

.ndt-icon-box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-right: 10px;
}

.ndt-text {
    position: relative;
    font-size: 18px;
    line-height: 36px;
    color: #18181B;
    margin-bottom: 25px;
}

.fa-star {
    color: #FFAA18;
    margin-left: 2px;
}

.ndt-image-column .ndt-image {
    position: relative;
    display: inline-block;
    margin-top: -190px;
    height: 450px;
    width: 450px;
    background-color: var(--theme-color-light);
    padding: 35px;
    border-radius: 50%;
    -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, .05);
    box-shadow: 0 10px 60px rgba(0, 0, 0, .05);
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    /* opacity: 0; */
}

.ndt-testimonial-thumbs {
    position: absolute;
    right: 340px;
    top: 18px;
    height: 400px;
    min-width: 185px;
    z-index: 1;
}

.ndt-thumb-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
    flex-direction: column;
}

.ndt-testimonial-thumb img {
    border-radius: 50%;
    height: 109px;
    width: 109px;
}

.ndt-testimonial-thumb button {
    border-radius: 50%;
    border: none;
    display: contents;
}

.ndt-testimonial-thumb:nth-child(odd) {
    /* -webkit-transform: translateX(50px); */
    transform: translateX(50px);
}

.ndt-image-column {
    position: relative;
    text-align: right;
    right: 50px;
}

.ndt-pb {
    /* padding-bottom: 30px; */
}

.select-image {
    position: relative;
    height: 132px;
    width: 132px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    border-radius: 50%;
    overflow: hidden;
    padding: 11px;
    border: 6px solid transparent;
}

.ndt-image img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    border-radius: 50%;
}

.name {
    font-size: 18px;
}

@media only screen and (max-width:1200px) {
    .ndt-image-column .ndt-image {
        margin-top: 0px;
        width: fit-content;
        height: fit-content;
        /* display: none; */
    }

    .ndt-row {
        flex-direction: column;
    }

    .ndt-image-column {
        position: relative;
        text-align: center;
        /* right: 50px; */
    }

    .ndt-heading {
        text-align: center;
    }

    .ndt-testimonial-thumbs {
        position: relative;
        top: 0;
        right: 0;
        height: fit-content;
    }

    .ndt-testimonial-thumb {
        /* -webkit-transform: translateX(50px); */
        /* transform: translateX(50px); */

    }

    .ndt-testimonial-thumb:nth-child(odd) {
        /* -webkit-transform: translateX(50px); */
        transform: translateX(0px);
    }

    .ndt-thumb-wrapper {
        flex-direction: row;
        justify-content: center;
    }

    .ndt-heading-subtitle:before {
        content: none;
    }
}