.ob-main-box{
    position: relative;
}

.ob-img-box {
    height: 30.5%;
    aspect-ratio: 1 / 1;
    position: absolute;
    left: 24%;
    top: -15%;
}

.ob-img-box img{
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 50%;
    /* box-shadow: 2px; */
}

.ob-content-box{
    height: fit-content;
    width: fit-content;
    margin: 10%;
    border-radius: 12px;
    padding-top: 10%;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 10%;
    box-shadow: 10px 10px 20px rgba(0,0,0,0.18);
    display: flex;
    justify-content: center;
}

.ob-content-box h2{
    display: flex;
    color: white;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
}

/* .ob-content-box h2::after {
    position: absolute;
    left: 38%;
    top: 38%;
    content: '';
    height: 1px;
    background: white;
    width: 46px;
} */