.breadcrumb-content-adjustment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
}

.breadcrumb-title-bar-content {
    color: white;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px 0;
    min-height: 298px;
    font-size: 100px;
}

.breadcrumb-title-bar-content h1 {
    color: #ffffff;
    font-size: clamp(18px, 2.5vw, 30px);
    font-weight: 500;
    /* font-size: none; */
}

.pbmit-breadcrumbs,
.pbmit-breadcrumbs a {
    font-family: var(--pbmit-body-typography-font-family);
    font-weight: 400;
    font-size: clamp(14px, 2.5vw, 18px);
    line-height: 1.5;
    color: #ffffff;
    /* color: var(--pbmit-white-color) !important; */
    text-transform: capitalize;
    font-style: normal;
}

@media only screen and (min-width:280px) and (max-width:600px) {

    .breadcrumb-title-bar-content {
        color: white;
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 64px 0;
        min-height: 225px;
        font-size: 100px;
    }

    /* .breadcrumb-title-bar-content h1 {
        color: #ffffff;
        font-size: 22px;
        font-weight: 500;
        width: 100%;
        font-size: none;
    } */
    /*   .pbmit-breadcrumbs,
 .pbmit-breadcrumbs a {
    font-family: var(--pbmit-body-typography-font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: #ffffff;
    text-transform: capitalize;
    font-style: normal;
} */

}


.padding-rl-20 {
    padding-right: 20px;
    padding-left: 20px;
    max-height: 44.99px;
}

.current-item {
    color: #7cba27;
    /* color:#14cf93; */
}

.breadcrumb-text-align {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}