.home-counter {
    position: relative;
    height: auto;
    background: url('../../../Images_1/Home/CounterImages/counter-bg2.jpg') no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.home-counter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    background: rgba(0, 0, 0, 0.6);
    padding: 100px 0px;
}

.counter-div-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: auto;
}

.home-counter-heading {
    /* color: #18181B; */
    color: #ffffff;
}

.counter-div {
    width: 250px;
    text-align: center;
}

.counter-numbers {
    color: #7CBA27;
    /* font-size: 36px; */
    /* font-weight: 700; */
    font-family: kumbhfonts;
    /* text-shadow: 2px 2px #000000; */
}

.counter-symbol {
    /* display: block; */
}

/* .counter-text {
    color: #ffffff;
    font-size: 18px;
} */
.counter-text {
    color: #ffffff;
    /* font-size: 18px; */
    font-family: kumbhfonts;
    /* font-weight: 700; */
    /* text-shadow: 2px 2px #000000; */
    display: block;
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    .counter-div-container {
        width: 100%;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1023px) {
    .counter-div-container {
        width: 95%;
    }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
    .counter-div-container {
        flex-direction: column;
    }

    .home-counter-container {
        padding: 50px 0px;
    }

    .home-counter-container>div {
        gap: 30px;
    }
}

@media only screen and (max-width: 426px) {
    .counter-div-container {
        flex-direction: column;
    }

    .home-counter-container {
        padding: 50px 0px;
    }

    .home-counter-container>div {
        gap: 30px;
    }
}