.Send-mail-container {
  /* display: flex; */
  padding: 50px;
  /* background-color: white; */
  /* border: 2px dotted #dce0d3; */
  border-radius: 20px;

}

.send-mail-parent-container {
  width: 900px;
  padding: 10px;
  z-index: 2;
  /* border-radius: 20px; */
  /* background-color: rgb(255, 255, 255); */
  /* box-shadow: 0 0 10px #bbff00; */
}

.send-mail-main-container {
  width: 100%;
  /* padding: 10px; */
  /* background-color: white; */
  display: flex;
  justify-content: center;
}

.Send-mail-content h1,
p {
  text-align: start;
}

.Send-mail-Form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Send-mail-container form {
  display: block;
  justify-content: center;
  align-items: center;
  gap: 5%;
}

.Send-mail-Form-container input {
  display: flex;
  width: 200px;
  margin-top: 4%;
  border-radius: 50px;
  border: rgb(232, 232, 232);
}

.Send-mail-Form-container button {
  width: 200px;
  height: 50px;
  border-radius: 50px;
}


.department-contactus-heading {
  color: #ffffff;
}

.heading-container-white-fonts {
  color: #ffffff;
}

.send-mail-parent-container {
  width: 900px;
  z-index: 2;
  border-radius: 20px;
  /* box-shadow: 0 0 10px #bbff00; */
}

.error {
  color: red;
  font-size: 0.8em;
}

.department-contact-bg-black {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  padding: 40px 0px;
}

.department-contact-us-div {
  /* background-image: radial-gradient(circle at top right, rgb(192,192,192) 0%, rgb(192,192,192) 48%,rgb(201,201,201) 48%, rgb(201,201,201) 53%,rgb(210,210,210) 53%, rgb(210,210,210) 56%,rgb(216,216,216) 56%, rgb(216,216,216) 69%,rgb(231,231,231) 69%, rgb(231,231,231) 100%); */
  background-image: url('../../Images_1/CommonSection/Contactus/contactbg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.send-mail-main-container {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
}

.department-contact-title {
  color: #ffffff;
}

.Send-mail-content h1,
p {
  text-align: start;
}

.Send-mail-Form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Send-mail-container form {
  display: block;
  justify-content: center;
  align-items: center;
  gap: 5%;
}

.Send-mail-Form-container input {
  display: flex;
  width: 200px;
  margin-top: 4%;
  border-radius: 50px;
  border: rgb(232, 232, 232);
}

.Send-mail-Form-container button {
  width: 200px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}



.contact-us-text-dept {
  /* background-color: aqua; */
  padding: 0px;
  margin: 0px;
  height: 40px;
  display: flex;
  justify-content: start;
  margin-bottom: 30px;
}

/* /the button/ */
.contact-us-button {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10rem;
  margin-left: 55px;
  color: #000000;
  justify-content: center;
  /* text-transform: uppercase; */
  /* font-size: 1rem; */
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  border: none;
  z-index: 1;
  /* border: 1px solid rgb(0, 0, 0); */
  margin-top: 20px;
}

.contact-us-text-dept {
  display: flex;
  justify-content: center;
  margin-right: 40px;
}

.contact-us-button:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10rem;
  z-index: -2;

}

.contact-us-button:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: black;
  transition: all .3s;
  border-radius: 10rem;
  z-index: -1;

}

.contact-us-button:hover {
  color: white;

}

.contact-us-button:hover:before {
  width: 100%;
}

.cud-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* /text inaside the button/ */
.text-contact-us-dept {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  /* font-size: 15px; */
  align-content: start;
  font-weight: 500;
  display: flex;
  width: 100%;
  letter-spacing: 0.2px;
  justify-content: start;
  padding-top: 5px;
  margin-left: 45px;
}

.contact-us-button:hover .text-contact-us-dept {
  color: white;
}

.contact-department-text {
  display: flex;
  justify-content: center;
  text-align: center;
}

.effect-position {
  padding: 100px;
}

.input-text-container {
  display: flex;
  justify-content: space-between;
  padding: 0;
  gap: 10px;
}

.name-input {
  width: 50%;
  padding: 20px 25px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: #ffffff;
}

.email-input {
  width: 50%;
  padding: 20px 25px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: #ffffff;
}

.textarea-input {
  margin-top: 20px;
  padding: 20px 25px;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: none;
  color: #ffffff;
  resize: none;
}

.hover-div:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

input::placeholder {
  color: #ffffff;
}

textarea::placeholder {
  color: #ffffff;
}