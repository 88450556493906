.careers-container {
    background-color: white;
    height: 100%;
    border-radius: 20px;
    padding: 12px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.121);
}

.ssContent-container {
    display: block;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 2.5%;
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 40px;
}

.Categories-Container {
    border: 2px dotted #dce0d3;
    border-radius: 15px;
    padding: 30px;
    width: 350px;
}

.mainsssbg {
    padding-top: 40px;
    background-image: linear-gradient(22.5deg, rgba(242, 242, 242, 0.03) 0%, rgba(242, 242, 242, 0.03) 16%, rgba(81, 81, 81, 0.03) 16%, rgba(81, 81, 81, 0.03) 26%, rgba(99, 99, 99, 0.03) 26%, rgba(99, 99, 99, 0.03) 73%, rgba(43, 43, 43, 0.03) 73%, rgba(43, 43, 43, 0.03) 84%, rgba(213, 213, 213, 0.03) 84%, rgba(213, 213, 213, 0.03) 85%, rgba(125, 125, 125, 0.03) 85%, rgba(125, 125, 125, 0.03) 100%), linear-gradient(22.5deg, rgba(25, 25, 25, 0.03) 0%, rgba(25, 25, 25, 0.03) 54%, rgba(144, 144, 144, 0.03) 54%, rgba(144, 144, 144, 0.03) 60%, rgba(204, 204, 204, 0.03) 60%, rgba(204, 204, 204, 0.03) 76%, rgba(37, 37, 37, 0.03) 76%, rgba(37, 37, 37, 0.03) 78%, rgba(115, 115, 115, 0.03) 78%, rgba(115, 115, 115, 0.03) 91%, rgba(63, 63, 63, 0.03) 91%, rgba(63, 63, 63, 0.03) 100%), linear-gradient(157.5deg, rgba(71, 71, 71, 0.03) 0%, rgba(71, 71, 71, 0.03) 6%, rgba(75, 75, 75, 0.03) 6%, rgba(75, 75, 75, 0.03) 15%, rgba(131, 131, 131, 0.03) 15%, rgba(131, 131, 131, 0.03) 18%, rgba(110, 110, 110, 0.03) 18%, rgba(110, 110, 110, 0.03) 37%, rgba(215, 215, 215, 0.03) 37%, rgba(215, 215, 215, 0.03) 62%, rgba(5, 5, 5, 0.03) 62%, rgba(5, 5, 5, 0.03) 100%), linear-gradient(90deg, #ffffff, #ffffff);
}

.Search-Bar h1 {
    text-align: start;
    margin-left: 2%;
}

.Search-Bar input {
    border-radius: 50px;
}

.List-Grid-Card-Categories h1 {
    text-align: start;
    margin-bottom: 5%;
}

.List-Grid-Card-Categories ul {
    list-style-type: none;
    padding: 0;
}

.List-Grid-Card-Categories ul li {
    padding: 20px 0;
    border-bottom: 1px dotted rgb(211, 210, 210);
    font-size: 18px;
    font-family: kumbhfonts;
    color: #18181b;
    cursor: pointer;
}

.List-Grid-Card-Categories ul li:hover {
    transition: all ease-in-out 500ms;
    border-bottom: 1px solid black;
}

.button-container button {
    border-radius: 50px;
    border: 1px solid black;
    background: none;
    color: black;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button-container button {
    display: inline-block;
    padding: 5px 1px;
    /* Adjust padding to change size */
    border-radius: 25px;
    /* Adjust border-radius to change shape */
    margin-left: 0;
    /* Remove margin-left */
    margin-bottom: 20px;
    /* Add margin-bottom to create vertical gap */
    color: #000000;
    font-size: 1rem;
    /* Adjust font-size to change text size */
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    border: none;
    z-index: 1;
    border: 1px solid rgb(175, 172, 172);
}

.button-container button:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 10rem;
    z-index: -2;
}

.button-container button:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: black;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
}

.button-container button:hover {
    color: white;
}

.button-container button:hover:before {
    width: 100%;
}

.button-container button:hover .text-contact-us-dept {
    color: white;
}

.button-container {
    display: flex;
    gap: 2%;
    flex-direction: column;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    background: none;
    border: 1px solid #d9e0c5;
    color: black;
    padding: 5px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 50px;
}

.pagination button.active {
    transition: all 100ms ease-in;
    background-color: #b1e346;
    border: none;
    color: black;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}


.List-Grid-Categories-Container2 {

    background-color: white;
    width: 900px;
    height: 800px;
    border-radius: 20px;
    padding: 5%;

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.121);

    padding-bottom: 25px;
}

.imagedetail2 {
    margin-left: 150px;
    height: 300px;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.parafrom {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 36px;
    font-weight: 600;
}

.paraDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-top: 30px;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
}

.align-top {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.career-content-container {
    border: 0.05vw solid #cecece;
}

.career-content-div {
    padding: 25px;
}

.career-heading-div {
    margin-bottom: 20px;
}

.career-heading {
    font-size: 30px;
    font-weight: 400;
}

.career-text {
    font-size: 16px;
    font-family: kumbhfonts;
}

.career-image-div {
    width: 100%;
    height: 100%;
}

.career-image {
    width: 100%;
    height: 100%;
}

/* New CSS */
.career-normal-text {
    text-align: left;
    font-size: clamp(var(--global-font-size-xxs), 2.5vw, var(--global-font-size));
}

.career-main-section {
    padding: 60px 10px;
    background-image: linear-gradient(359deg, rgba(25, 25, 25, 0.02) 0%, rgba(25, 25, 25, 0.02) 8%, transparent 8%, transparent 27%, rgba(152, 152, 152, 0.02) 27%, rgba(152, 152, 152, 0.02) 80%, rgba(142, 142, 142, 0.02) 80%, rgba(142, 142, 142, 0.02) 100%), linear-gradient(250deg, rgba(9, 9, 9, 0.02) 0%, rgba(9, 9, 9, 0.02) 33%, transparent 33%, transparent 40%, rgba(240, 240, 240, 0.02) 40%, rgba(240, 240, 240, 0.02) 68%, rgba(141, 141, 141, 0.02) 68%, rgba(141, 141, 141, 0.02) 100%), linear-gradient(107deg, rgba(229, 229, 229, 0.02) 0%, rgba(229, 229, 229, 0.02) 12%, transparent 12%, transparent 24%, rgba(89, 89, 89, 0.02) 24%, rgba(89, 89, 89, 0.02) 38%, rgba(206, 206, 206, 0.02) 38%, rgba(206, 206, 206, 0.02) 100%), linear-gradient(64deg, rgba(49, 49, 49, 0.02) 0%, rgba(49, 49, 49, 0.02) 33%, transparent 33%, transparent 73%, rgba(191, 191, 191, 0.02) 73%, rgba(191, 191, 191, 0.02) 78%, rgba(83, 83, 83, 0.02) 78%, rgba(83, 83, 83, 0.02) 100%), linear-gradient(90deg, rgb(255, 255, 255), rgb(255, 255, 255));
}

.career-main-div {
    max-width: 1350px;
    margin: auto;
}

.career-top-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 100px;
    margin-bottom: 30px;
}

.career-top-heading-div {
    width: 100%;
}

.career-top-desc-div {
    width: 70%;
    display: flex;
    align-items: center;
    margin: 0;
}

.career-top-desc {
    margin: 0;
    color: #787878;
    line-height: 160%;
}

.career-top-heading-1 {
    font-size: 20px;
    color: #7cba27;
    margin: 0;
}

.career-top-heading-2 {
    font-size: 40px;
    /* font-weight: 700; */
    margin: 0;
}

.career-bottom-div {
    width: 100%;
}

.career-job-role-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
}

.career-job-single {
    /* background-color: #111827; */
    background-color: #0e2f44;
    /* width: 700px; */
    height: fit-content;
    border-radius: 1rem;
    padding: 25px;
}

.location-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    flex-wrap: wrap;
    gap: 10px;
}

.location-div>:nth-child(1) {
    color: #ffffff;
    /* font-size: 20px; */
    /* font-size: clamp(16px, 2.5vw, 20px); */
}

.location-div>:nth-child(2) {
    color: #D9D9D9;
    background-color: #FFFFFF1A;
    padding: 3px 15px;
    border-radius: 1rem;
    height: fit-content;
    font-size: 14px;
}

.role-div {
    margin-bottom: 10px;
}

.role-div>:nth-child(1) {
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
}

.info-div {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.info-div>:nth-child(1) {
    color: #ffffff;
    /* font-size: 14px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.info-div>:nth-child(2) {
    color: #ffffff;
    /* font-size: 14px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

.info-div>:nth-child(3) {
    color: #ffffff;
    /* font-size: 14px; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
}

/* .apply-button {
    margin-top: 35px;
}

.apply-button>:nth-child(1) {
    border-radius: 1rem;
    font-size: 14px;
    padding: 2px 15px;
    transition: 0.4s ease-in-out;
    border: 1px solid #000000;
}

.apply-button>:nth-child(1):hover {
    color: #ffffff;
    background-color: #000000;
    border: 1px solid #ffffff;

    a {
        color: #FFF;
    }
} */

.apply-button-text-card {
    padding: 0px;
    margin: 0px;
    height: 35px;
    display: flex;
    justify-content: start;
    margin-top: 10px;
}

/* /the button/ */
.apply-button {
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 10rem;
    color: #000000;
    /* text-transform: uppercase; */
    font-size: 1rem;
    letter-spacing: .08rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: 1px solid rgb(175, 172, 172);
}

.apply-button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10rem;
    z-index: -2;
}

.apply-button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #000000;
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
    border: 1px solid white;
}

.apply-button:hover {
    color: #ffffff;
    border: none;
}

.apply-button:hover:before {
    width: 100%;
}

.apply-button-text-card:hover .link-color {
    color: #ffffff;
}

.career-working-div {
    margin-bottom: 100px;
}

.career-working {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.career-working-content-div {
    width: 60%;
}

.career-working-image-div {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 1.2rem;
}

.career-working-image {
    width: 100%;
    border-radius: 1rem;
}

@media screen and (max-width: 1000px) {
    .career-working {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
        text-align: center;
    }

    .career-job-role-div {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }

    .career-top-heading-div {
        width: 100%;
    }

    .career-top-div {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        gap: 100px;
        margin-bottom: 50px;
    }
}