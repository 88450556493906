.aboutcompany-main-div {
    /* height: 710px; */
    display: flex;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.aboutcompany-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutcompany-content-div {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
}

/* Image Style Start */

.about-company-image-div {
    display: flex;
}

.aboutcompany-text-div h2 {
    color: #0a3e76;
}

.aboutcompany-bg-div {
    position: absolute;
}

.aboutcompany-bg {
    position: relative;
    top: 0;
    right: 0;
}

.aboutcompany-bg {
    width: 500px;
    height: 700px;
}

.aboutcompany-hero-img-div {
    display: flex;
    position: relative;
}

.aboutcompany-hero-img-1 {
    width: 380px;
    height: 480px;
    object-fit: cover;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 10px;
}

.aboutcompany-hero-img-2 {
    width: 250px;
    height: 300px;
    object-fit: cover;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    left: 120px;
    bottom: 40px;
}

.span-div {
    list-style: disc;
    padding: 0px 20px;
}

.span-div li {
    /* font-size: 18px; */
    text-align: justify;
}

.aboutcompany-us-subtitle {
    /* font-size: clamp(16px, 2.5vw, 18px); */
    font-weight: 500;
    padding-bottom: 20px;
}

.aboutcompany-us-subtitle ul {
    margin-left: 20px;
}

.aboutcompany-hero-img-3 {
    width: 60%;
    margin: auto;
    z-index: 1;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .aboutcompany-hero-img-3 {
        width: 95%;
    }
}

@media screen and (max-width: 1200px) {
    .aboutcompany-text-div h2 {
        text-align: center;
    }

    .aboutcompany-content-div {
        grid-template-columns: auto;
    }

    .aboutcompany-main-div {
        height: max-content;
    }

    .aboutcompany-text-div {
        z-index: 1;
        padding: 10px 50px;
    }

    .about-company-image-div {
        justify-content: center;
    }

    .aboutcompany-bg {
        display: none;
    }

    .aboutcompany-section {
        max-width: 100%;
        padding-bottom: 50px;
    }
}

@media screen and (max-width: 600px) {
    .aboutcompany-hero-img-3 {
        width: 80%;
    }

    .aboutcompany-text-div {
        padding: 10px 10px;
    }

    .aboutcompany-us-subtitle {
        /* font-size: 16px; */
        font-weight: 500;
        padding-bottom: 20px;
        text-align: justify;
    }

    .span-div li {
        /* font-size: 16px; */
        text-align: justify;
    }

    .aboutcompany-section {
        max-width: 95%;
        padding-bottom: 50px;
        margin: auto;
    }
}