.form2-main-div {
  height: max-content;
  width: 80%;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 20px;
  display: flex;
  justify-content: center;
  margin-left: 50px;
  padding-bottom: 30px;
}



.form2-row-div {
  display: flex;
  gap: 20px;
  background-color: white;
  border-radius: 2%;
}

.form-inner {
  margin: 20;
}

.form2-address textarea {
  border-radius: 0;
}

.text-salary {
  margin-left: 10px;
  margin-bottom: 10px;
  color: currentColor;
}

.form2-pt-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.form2-pr {
  padding-right: 30px;
  /* text-align: center; */
  padding-top: 10px;
  color: #18181B;
}

.form2-pl {
  padding-left: 10px;
}

.form2-check-l {
  padding-left: 10px;
}

.form2-declare-styling {
  font-weight: 600;
  text-align: left;
}

.form2-mandatory {
  font-weight: 600;
  color: red;
}

.form-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}

.form2-pr {
  padding-right: 20px;
  padding-left: 16px;
}

.lightText {
  font-size: 16px;
  color: gray;
  width: 200px;
}

.lightText2 {
  font-size: 16px;
  color: gray;
}

.inputFlex {
  display: flex;
  align-items: center;
  /* gap: 10px; */
}

textarea {
  resize: none;
}

.toggle-container {
  position: relative;
  width: 44px;
  height: 24px;
  background-color: #c4c4c4;
  cursor: pointer;
  user-select: none;
  border-radius: 20px;
}

.toggle-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: #fff;
  /* background-color: #FF6666; */
  background-color: #2196F3;
  box-shadow: 0 2px 4px rgb(0, 0, 0, 0.25);
  border-radius: 20px;
  position: absolute;
  transition: all 0.2s ease;
  left: 20px;
}

.disable {
  background-color: #707070;
  left: 0px;
}

.removeButton {
  color: #ffffff;
  background-color: #7cba27;
  border-radius: 100%;
  border: none;
}

.addButton {
  color: #ffffff;
  background-color: #7cba27;
  border-radius: 100%;
  border: none;
}

.text-red{
  color: red;
}