.exp-img-size {
  width: 100%;
  aspect-ratio: 3.5/2;
  border-radius: 12px;
}

.exp-fid-title {
  margin: 15px 0 0 5px;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: -0.3px;
  --tw-text-opacity: 1;
  color: rgb(24 24 27 / var(--tw-text-opacity));
}

.exp-inner {
  margin: 15px 0 15px 5px;
  --tw-text-opacity: 1;
  color: rgb(24 24 27 / var(--tw-text-opacity));
  letter-spacing: -0.5px;
  text-align: left;
}

.department-row {
  flex-wrap: nowrap;
  justify-content: left;
}

.dep-image {
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
}

/* .reason-to-choose-top-padding{
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
  } */

.choose-content {
  border: 1px dashed rgb(35, 35, 35, .10);
  transition: all 0.4s ease;
  /* background-color: white; */
  border-radius: 10px;
  text-align: center;
  padding: 10px 10px;
  position: relative;
  z-index: 1;
}

.choose-space-container {
  display: -webkit-inline-box;
  padding: 10px;
  background-color: white;
  border-radius: 15px;
  position: relative;
}

.choose-margin {
  margin-block-start: 5%;
}

.reason-to-choose {
  padding-top: 20px;
}

.choose-content:hover>* {
  color: white;
}

.choose-content:hover>div h4 {
  color: white;
}